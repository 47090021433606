@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.product-card-dashboard {
  @include max-width;

  &__wrapper {
    background-color: $white;
  }

  &__products {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 64px;
    grid-column-gap: 16px;
    padding: $padding-base * 4 $padding-base * 2 $padding-base * 4;

    @include media($to: tbl-p) {
      padding: 0 $padding-base 2.5 * $padding-base;
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 0px;
    }

    @include media($from: tbl-p) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media($from: tbl-l) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media($from: dsk) {
      grid-template-columns: repeat(4, 1fr);
    }

    * {
      box-sizing: border-box;
    }
  }
}

@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.special-offers-card {
  margin-right: $margin-base;
  padding-bottom: $padding-base * 1.5;
  border-bottom: 2px solid $grey-light;
  $padding-left-secondary-cta: $padding-base + 2;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;

  &--large {
    min-width: 300px;
    max-width: 300px;
  }

  &--small {
    min-width: 300px;
    max-width: 300px;
  }

  @include media($from: tbl-p) {
    margin-right: $margin-base * 5;

    &--large {
      min-width: 600px;
      max-width: 600px;
    }

    &--small {
      min-width: 328px;
      max-width: 328px;
    }
  }

  &__badge {
    position: absolute;
    top: $margin-base;
    right: $margin-base;
    z-index: 1;
    font-size: $font-size-smaller;
    line-height: 1.67;
    color: $black;
    padding: $padding-base / 4 $padding-base / 1.5;
    background-color: $grey-light;
    text-transform: uppercase;

    * {
      margin: 0;
    }
  }

  &__image {
    height: 168px;
    width: 100%;
    object-fit: cover;
    margin: 0;

    @include media($from: tbl-p) {
      height: 185px;
    }
  }

  &__body {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: ($padding-base * 1.5) - 2 $padding-base / 2 0;

    @include media($from: tbl-p) {
      padding: $padding-base + 2 $padding-base / 2 0;
    }

    span {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
    }
  }

  &__title {
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $blue-dark;
    margin: 0 0 $margin-base / 2 0;

    @include media($from: tbl-p) {
      font-size: $font-size-base;
      margin-bottom: $margin-base * 0.375;
    }

    // discount style
    b,
    strong {
      font-size: $font-size-medium;
      font-weight: $font-weight-bold;
      line-height: $line-height-base;
      letter-spacing: $letter-spacing-small;
      color: $color-primary;

      @include media($from: tbl-p) {
        line-height: $line-height-small;
      }
    }
  }

  &__content {
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $color-dark-blue;
    margin: $margin-base / 2 0 $margin-base * 2 0;
    ul {
      margin: $margin-base 0 0 $margin-base;
      li {
        padding-left: 0;
      }
    }
  }

  &__disclaimer {
    text-decoration: underline;
    font-weight: $font-weight-bold;
    font-size: $font-size-small;
    color: $color-primary;
    cursor: pointer;
    margin-bottom: $margin-base / 2;
    letter-spacing: 0.8px;
    line-height: $line-height-small;
  }

  &__date-off {
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    line-height: 1.71;
    letter-spacing: 0.8px;
    color: $blue-dark;
    margin-bottom: $margin-base * 1.5;

    span {
      display: inline-flex;
    }
  }

  &__cta-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: auto;

    @include media($from: tbl-p) {
      &--row {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }

  &__cta-primary {
    background-color: $yellow-primary;
    padding: $padding-base / 1.2 $padding-base * 1.5;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    line-height: $line-height-small;
    letter-spacing: 0.8px;
    text-align: left;
    color: $black;
    text-transform: uppercase;
    border: none;

    a {
      text-decoration: none;
      color: $black;
      font-family: $font-family;
    }
  }

  &__cta-secondary {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    line-height: 1.5;
    letter-spacing: 0.8px;
    text-align: left;
    color: $color-primary;
    border: none;
    background: transparent;
    text-decoration: none;
    font-family: $font-family;

    &--margin {
      margin-top: $margin-base * 1.5;
    }

    & a {
      display: inline-block;
      align-items: center;
      color: $color-primary;
      text-decoration: none;
      font-family: $font-family;
      padding-left: $padding-base * 1.125;
    }

    .custom-arrow__icon {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: $margin-base * 0.1875;
      background-color: $color-primary-blue;
    }

    @include media($from: tbl-p) {
      &--margin-row {
        margin-top: 0;
        margin-left: $margin-base;
      }
    }

    &:hover {
      a {
        text-decoration: underline;
        text-underline-position: under;

        .custom-arrow__icon {
          margin-left: $margin-base / 2;
          transition: all 0.1s ease-in;
        }
      }
    }
  }
}

.my-modal-dialog.special-offers-disclaimer-modal {
  padding: $padding-base * 5 $padding-base * 2 $padding-base * 4;

  @include media($to: tbl-p) {
    padding: $padding-base $padding-base $padding-base * 2.5;
  }

  & > div {
    text-align: left;
    margin: 0 auto;

    @include media($from: tbl-p) {
      max-width: 632px;
    }

    @include media($to: tbl-p) {
      max-width: 343px;
    }
  }
}

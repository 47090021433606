@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.custom-arrow {
  $box-shadow-arrow: 0px 2px 20px rgba(126, 126, 126, 0.25);
  $custom-arrow-dim: 40px;
  $gradient: rgba(255, 255, 255, 0), rgba(244, 244, 244, 0.9) 80%;

  align-items: center;
  cursor: pointer;
  display: none;
  height: 100%;
  position: absolute;
  width: 80px;
  z-index: $z-index-carousel-arrow;

  @include media($from: tbl-p) {
    display: flex;
  }

  .fade {
    display: none;
    transition: opacity 0.3s ease;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;

    &.visible {
      opacity: 1;
    }

    @include media($from: tbl-p) {
      display: block;
    }
  }

  &--left {
    justify-content: flex-end;
    left: 0;

    .fade {
      background: linear-gradient(to left, $gradient);
    }
  }

  &--right {
    justify-content: flex-start;
    right: 0;

    .fade {
      background: linear-gradient(to right, $gradient);
    }
  }

  &__button {
    align-items: center;
    background: $color-dark-blue;
    cursor: pointer;
    border: 0;
    box-shadow: $box-shadow-arrow;
    height: $custom-arrow-dim;
    justify-content: center;
    outline: 0;
    width: $custom-arrow-dim;
    padding: 0;
    &:hover {
      background: $color-primary-blue;
      box-shadow: 0px 10px 20px $color-arrow-hover-box-shadow;
    }
  }
}

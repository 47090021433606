@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

@mixin desktop {
  @media (min-width: map-get($breakpoints, tbl-p)) {
    @content;
  }
}

$interval: 10s;
$control-counter-dsk-height: 10px;
$control-counter-mbl-height: 6px;
$slide-content-max-width-dsk: 883px;
$slide-content-pos-left: 168px;
$slide-button-width-height: 40px;
$slide-arrow-left-right-position-mobile: 20px;
$slide-arrow-left-right-position: 80px;
$slide-print-top-right-position: 16px;
$slide-print-top-position-dsk: 24px;
$slide-print-right-position-dsk: 80px;
$slide-footer-height-desktop: 114px;
$slide-footer-height-mobile: 130px;
$fullscreen-comp-height-desktop: 768px;
$fullscreen-comp-height-mobile: 651px;

.fullscreen-media {
  $content-spacing-left: $slide-content-pos-left;
  background: $white;
  @include max-width;

  & + * {
    padding-top: $padding-base * 4;

    @include media($from: tbl-p) {
      padding-top: $padding-base * 1.5;
    }

    @media (min-width: map-get($breakpoints, dsk-l)) {
      padding-top: $padding-base * 9;
    }
  }

  &__carousel {
    position: relative;

    .custom-arrow {
      display: block;
      top: 50%;
      transform: translateY(-50%);
      height: fit-content;

      &__button {
        display: flex;
      }
    }

    .custom-arrow--left .custom-arrow__button {
      margin-left: auto;
    }

    &__animate-icons {
      opacity: 1;
      transition: opacity 1s ease;
      z-index: $z-index-series-overview-nav-section-arrow;

      &--animate-fade {
        opacity: 0;
      }

      @include media($to: tbl-p) {
        opacity: 0;
        pointer-events: none;

        &--animate-fade {
          @include nav-arrows-fade();
        }
      }

      &--hide {
        opacity: 0;
      }

      &--show {
        opacity: 1;
        pointer-events: inherit;
      }
    }
  }

  .slick-slide > div,
  .slide {
    height: $fullscreen-comp-height-mobile;

    @include media($from: tbl-p) {
      height: $fullscreen-comp-height-desktop;
    }
  }

  .slide {
    .slide-footer {
      min-height: $slide-footer-height-desktop;
      position: absolute;
      z-index: $z-index-full-screen-media-carousel-description;
      bottom: 0;
      width: 100%;
      padding-top: ($padding-base * 2) + 8;
      background-image: $carousel-image-gradient;
      pointer-events: none;

      @include media($from: dsk) {
        min-height: $slide-footer-height-mobile;
      }

      &--hide-bg {
        background-image: none;
      }

      & .slide-description {
        position: relative;
        box-sizing: border-box;
        bottom: 0;
        width: 100%;
        margin-bottom: ($margin-base * 3) + 2;
        padding: 0px ($padding-base / 4) + 1;
        max-width: 50%;

        @include media($from: tbl-p) {
          padding: 0px ($padding-base);
        }

        @include media($from: dsk) {
          padding: 0px 0px 0px $slide-content-pos-left;
          margin-bottom: ($margin-base * 4) + 2;
        }

        @include media($to: tbl-p) {
          padding: 0 0 0 $padding-base;
        }

        text-align: left;
        color: $white;
        font-size: $font-size-base;
        line-height: $line-height-small;
      }
    }

    &__inner {
      height: 100%;
      width: 100%;
      position: relative;
      overflow: hidden;

      &[data-text-color='White'] {
        .slide {
          &__text {
            color: $white;
          }
        }
      }
    }

    &__image {
      pointer-events: all !important;
      position: relative;
      height: 100%;
      width: 100%;
      max-height: 100%;
      max-width: 100%;
      object-fit: cover;
      z-index: $z-index-full-screen-media-carousel-img;
    }

    &__content {
      padding: $padding-base;
      text-align: center;
      position: relative;

      @include media($from: tbl-l) {
        box-sizing: content-box;
        padding: $padding-base * 6.7 $content-spacing-left 0px;
        margin: auto;
        max-width: $slide-content-max-width-dsk;
        width: 100%;
      }

      & pfe-cta {
        --pfe-theme--cta__priority--DiagonalSkewX: 0deg;
        --pfe-cta--BackgroundColor: #{$color-primary};
        --pfe-cta--BoxShadow--hover: none;
        --pfe-cta--BoxShadow: none;
        --pfe-cta--BackgroundImage--hover: none;
        --pfe-cta--BackgroundColor--hover: #{$color-primary};
        --pfe-cta--Color--hover: $black;
      }
    }

    &__text {
      color: $black;

      &--title {
        font-size: $font-size-smaller - 2;
        font-weight: $font-weight-bold;
        line-height: $line-height-large;
        margin: ($margin-base * 5) 0 $margin-base 0;

        @include media($from: dsk) {
          font-size: $font-size-extra-large;
          line-height: $line-height-base;
          margin: 0 0 $margin-base 0;
        }
      }

      &--content {
        display: block;
        font-weight: 600;
        font-size: $font-size-base;
        line-height: $font-size-large;
        margin-bottom: ($margin-base * 3);

        @include media($from: dsk) {
          font-size: $font-size-large;
          line-height: $line-height-large + 2;
        }
      }
    }
  }

  .controls {
    display: flex;
    justify-content: center;

    .carousel-dots-wrapper {
      bottom: $margin-base;
      position: absolute;
      z-index: $z-index-full-screen-media-carousel-controls;

      @include media($from: dsk) {
        bottom: $margin-base * 2;
      }

      .carousel-dots {
        background-color: $white;
        opacity: 0.8;

        &--active {
          opacity: 1;
        }
      }
    }
  }

  .controls__arrow {
    border: none;
    position: absolute;
    width: $slide-button-width-height;
    height: $slide-button-width-height;
    top: calc(50% - 40px / 2);
    background-color: $color-dark-blue;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: $font-size-medium + 4;

    &:hover {
      background-color: $color-primary-blue;
    }

    @include media($from: dsk) {
      font-size: $font-size-large + 2;
      display: flex;
    }

    .icon-wrapper {
      display: block;
    }

    &--previous {
      left: $slide-arrow-left-right-position-mobile;

      @include media($from: dsk) {
        left: $slide-arrow-left-right-position;
      }
    }

    &--next {
      right: $slide-arrow-left-right-position-mobile;

      @include media($from: dsk) {
        right: $slide-arrow-left-right-position;
      }
    }

    &--download {
      align-items: center;
      border: 0;
      height: 40px;
      justify-content: center;
      margin-left: $margin-base;
      outline: 0;
      width: 40px;
      padding: 0;
      cursor: pointer;
      display: flex;
      top: $slide-print-top-right-position;
      right: $slide-print-top-right-position;
      z-index: $z-index-download-button;

      @include media($from: dsk) {
        top: $slide-print-top-position-dsk;
        right: $slide-print-right-position-dsk;
      }
    }
  }
}

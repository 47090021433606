@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.stories-card {
  display: flex;
  width: 163px;
  height: 290px;
  position: relative;
  overflow: hidden;

  @media (min-width: mbl-s) {
    width: 163px;
  }

  @include media($from: tbl-p) {
    width: 200px;
    height: 355px;
  }

  picture {
    width: 100%;
  }

  &__bg-image {
    width: 100%;
    height: 100%;
    display: flex;
    transition: transform 0.5s ease;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border-radius: 3px;
    @include media($from: dsk) {
      &:hover {
       transform: scale(1.2);
      }
    }
  }

  &__content {
    width: 100%;
    box-sizing: border-box;
    align-self: flex-end;
    color: $white;
    padding: $padding-base/2 $padding-base/2 $padding-base $padding-base/2;
    position: absolute;
    background: linear-gradient(to bottom, rgba(10, 26, 50, 0), rgba(10, 26, 50, 0.5) 27%, rgba(10, 26, 50, 0.7));
  }

  &__title {
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    margin: $margin-base 0 $margin-base/2 0;

    @include media($from: tbl-p) {
      font-size: $font-size-base;
    }
  }

  &__link-wrapper {
    display: flex;
    align-items: center;
  }

  &__link {
    font-size: $font-size-base;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    text-transform: uppercase;
    font-weight: $font-weight-bold;

    &:hover {
      text-decoration: underline;
    }

    &-icon {
      background-color: $white;
    }
  }
}
@import '../../../../../styles/_variables.scss';
@import '../../../../../styles/_mixins.scss';

.filter-accordion {
  background-color: $color-light-grey-opacity03;

  .accordion-font-styles {
    font-size: $font-size-small;
    line-height: $line-height-small;
    color: $color-dark-blue;
  }

  &__switch {
    @extend .accordion-font-styles;
    display: flex;
    justify-content: space-between;
    padding: $padding-base 0;
    margin: 0 $margin-base;
    border-bottom: 1px solid $color-light-grey;
    cursor: pointer;

    &--open {
      border: none;
    }
  }

  &__panel {
    background-color: $white;
    padding: 0 $padding-base;

    &-item {
      @extend .accordion-font-styles;
      letter-spacing: $letter-spacing-base;
      width: 100%;
      display: flex;
      column-gap: $margin-base / 2;
      padding: $padding-base 0;
      cursor: pointer;
      align-items: center;

      &:not(:last-child) {
        border-bottom: 1px solid $color-light-grey;
      }
    }

    &-radio {
      display: none;
    }
  }
}

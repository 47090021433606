@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.series-overview {
  background-color: $white;
  overflow: hidden;

  &__container {
    @include max-width;
    @include media($from: tbl-p) {
      display: grid;
      grid-template-columns: 34% 66%;
      min-height: 768px;
      padding-bottom: $padding-base * 2;

      > .series-overview-carousel {
        grid-row: 1/2;
        grid-column: 2/3;
      }
      > .series-overview-text-wrapper {
        grid-row: 1/2;
        grid-column: 1/2;
      }
    }
  }
}

@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

$close-icon-size-mbl: 40px;
$close-icon-size-dsk: 80px;
$modal-lateral-margin: 168px;

.my-modal-dialog {
  background: $white;
  position: relative;
  top: $margin-base * 5;
  transform: translateX(-50%);
  left: 50%;
  max-width: $width-dsk-modal-content;
  box-sizing: border-box;

  @include media($from: tbl-p, $to: dsk) {
    width: 100%;
  }
  @include media($from: dsk) {
    width: calc(100% - #{$modal-lateral-margin} * 2);
    max-width: $width-dsk-modal-content;
  }
  @include media($from: dsk-max-modal-bp) {
    max-width: $width-dsk-max-modal-content;
  }

  &--has-max-height {
    max-height: calc(100% - 36px);
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }

  &__overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    background-color: $color-white;
    @include media($from: tbl-p) {
      background-color: $modal-overlay-background-color;
    }
    z-index: $z-index-modal;
    box-sizing: border-box;
    overflow: auto;

    &--has-max-height {
      overflow-y: hidden;
    }

    &--ee-active {
      height: calc(100% - var(--ee-toolbar-offset, 0px));
      top: var(--ee-toolbar-offset, 0px);
    }
  }

  &__close-button {
    cursor: pointer !important;
    all: unset;
    position: sticky;
    top: 0;
    left: 100%;
    width: $close-icon-size-mbl;
    height: $close-icon-size-mbl;
    background-color: $blue-dark;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: $z-index-generic-close-button;
    @include media($from: tbl-p) {
      width: $close-icon-size-dsk;
      height: $close-icon-size-dsk;
    }
    &--ee-active {
      top: var(--ee-toolbar-offset, 0px);
    }
  }
}

.top-aligned-modal {
  &.my-modal-dialog {
    top: 0;
    margin: 0 0 $margin-base * 2.5 0;

    @include media($from: tbl-p) {
      margin: 0 0 $margin-base * 5 0;
    }
  }
}

.center-aligned-modal {
  &.my-modal-dialog {
    top: calc(50% - #{$close-icon-size-mbl});
    transform: translate(-50%, -50%);
    @include media($from: tbl-p) {
      top: calc(50% - #{$close-icon-size-dsk});
    }
  }
}

@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.dashboard-card {
  $root: &;
  position: relative;
  margin-bottom: $margin-base * 3;
  text-decoration: none;
  background-color: $color-white;

  @include media($from: tbl-p) {
    margin-bottom: 0;

    &:hover {
      #{$root}__image-wrapper {
        overflow: hidden;
      }

      #{$root}__image-wrapper__image {
        transform: scale(1.1);
        transition: 0.3s;
      }

      #{$root}__bgimage {
        transform: translate(-$margin-base * 2, -$margin-base * 2);
        opacity: 1;
      }
    }
  }

  &__media {
    position: relative;
    align-items: center;
    justify-content: center;
  }

  &__bgimage {
    position: absolute;
    left: 0;
    top: 0;
    height: 235px;
    z-index: -1;
    transition: 0.3s;
    opacity: 0;
  }

  &__image-wrapper__image {
    object-fit: cover;
    max-width: 100%;
    width: 100%;

    @include fixed-picture-aspect-ratio(375, 211);

    @include media($to: tbl-p) {
      width: 100vw;
    }

    @include media($from: tbl-p) {
      @include fixed-picture-aspect-ratio(736, 414);
    }

    @include media($from: dsk) {
      @include fixed-picture-aspect-ratio(328, 185);
    }
  }

  &__title {
    font-weight: $font-weight-bold;
    font-size: $font-size-medium;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-small;
    color: $color-dark-blue;
    margin: 0 0 $margin-base/2 0;
  }

  &__content {
    padding: $padding-base $padding-base 0;
    background-color: $white;

    @include media($from: tbl-p) {
      padding: $padding-base $padding-base/2 0;
    }
  }

  &__description {
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $color-dark-blue;
  }
}

@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.overlay-carousel-popup {
  &__icon {
    background-color: $white;
  }

  &__arrow-control {
    display: block;
    border: none;
    outline: none;
    padding: $padding-base/2;
    box-shadow: 0 2px 20px $color-button-shadow;
    background-color: $blue-dark;
    cursor: pointer;

    &:hover {
      background: $color-primary;
    }

    @include media($to: tbl-p) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &--previous {
        left: 32px;
      }

      &--next {
        right: 32px;
      }
    }
  }

  &__image-style {
    z-index: 0;
  }

  &__white-box {
    box-sizing: border-box;
    padding: 0;
    background: $grey-light;
    display: grid;
    margin: 0 auto;

    @include media($from: tbl-p) {
      padding: 0 $padding-base * 2 $padding-base * 4 $padding-base * 2;
    }

    @include media($from: dsk-l) {
      max-width: $max-modal-breakpoint;
    }
  }

  &__carousel-box {
    grid-template-rows: 1fr auto;
  }

  &__heading-wrapper {
    padding: $padding-base * 5 0 $padding-base * 2 0;
  }

  &__main-section {
    display: grid;
    grid-template-columns: 40px 66.336% 40px;
    column-gap: 32px;
    justify-content: center;
    align-items: center;
    padding: 0;

    @include media($to: tbl-l) {
      padding: 0 $padding-base * 5;
    }
  }

  &__carousel-btn-wrapper {
    opacity: 1;

    &--hide {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__focus-image-wrapper {
    position: relative;
    flex-grow: 1;
    overflow: hidden;
    padding-top: calc((603 / 1072) * 100%);
    height: 0;
  }

  &__focus-image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform: translate(0, 0, 1);
    transition: transform 0.8s $transition-slow-start-fast-end;
    background-color: $white;
    z-index: $z-index-gallery-layout-level-one;

    &--move-left {
      transform: translateX(-100%);
      pointer-events: none;
      transition: transform 0.8s $transition-slow-start-fast-end;
    }

    &__text-wrapper {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      color: $white;
      z-index: $z-index-gallery-layout-level-two;
      padding: $padding-base;
      background: linear-gradient(to top, rgba(10, 26, 50, 0.7), rgba(10, 26, 50, 0.5) 33%, rgba(10, 26, 50, 0));
    }

    &__text-title {
      font-size: $font-size-medium;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-small;
      font-weight: $font-weight-bold;
      margin-bottom: $margin-base/2;
    }

    &__text-description {
      font-size: $font-size-small;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
    }
  }

  &__animate-icons {
    opacity: 1;
    transition: opacity 1s ease-in;
    z-index: $z-index-series-overview-nav-section-arrow;

    &--animate-fade {
      opacity: 0;
    }

    @include media($to: tbl-p) {
      opacity: 0;
      pointer-events: none;

      &--animate-fade {
        @include nav-arrows-fade();
      }
    }

    &--show {
      opacity: 1;
      pointer-events: inherit;
    }

    &--hide {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__slide-section-wrapper {
    @include media($from: tbl-p) {
      max-width: 100%;
      overflow-x: hidden;
    }

    @include media($to: tbl-p) {
      overflow-x: auto;
    }
  }

  &__modal-gray-wrapper {
    max-width: 100vw;
    background: $grey-light;
    padding: $padding-base * 1.5 $padding-base $padding-base * 2.5 $padding-base;
  }

  &__slide-section {
    padding-top: $padding-base * 2;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: min-content;
    column-gap: 8px;
    margin: 0 auto;
    position: relative;
    left: var(--expanded-overlay-slide-move-by, 0);
    transition: left 0.5s ease-in-out;

    @include media($to: tbl-p) {
      padding-top: 0;
      overflow-x: scroll;
      width: 100%;
    }
  }

  &__slide-image-wrapper {
    width: 168px;
    position: relative;
    cursor: pointer;
    overflow: hidden;

    @include media($to: tbl-p) {
      width: 84px;
    }
  }

  &__slide-image-blur {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.6;
    background: $white;
    z-index: $z-index-gallery-layout-level-two;
    transition: opacity 0.5s ease-in-out;

    &--deactivate {
      opacity: 0;
    }
  }

  &__slide-highlight-bar {
    height: 3px;
    background: $yellow-primary;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $z-index-gallery-layout-level-one;
    transition: all 0.5s ease-in-out;
  }

  &__slide-video-play-button-slider {
    position: absolute;
    top: 50%;
    z-index: $z-index-gallery-layout-level-one;
    padding: $padding-base * 1.5;
    transform: translate(-50%, -50%);
    left: 50%;
    cursor: pointer;
    background-color: $blue-dark;

    &--in-slider {
      padding: $padding-base/4;
    }

    @include media($to: tbl-p) {
      &--in-slider {
        padding: $padding-base - 13 $padding-base - 11 $padding-base - 13 $padding-base - 13;
      }
    }

    &--in-slider-play-blur {
      padding: $padding-base/4;
    }
  }

  &__slide-image {
    &--fixed-aspect-ratio {
      background: $white;
      @include fixed-picture-aspect-ratio(168, 94);

      @include media($to: tbl-p) {
        @include fixed-picture-aspect-ratio(84, 48);
      }
    }
  }

  &__modal-title {
    font-size: $font-size-medium;
    line-height: $line-height-small;
    color: $blue-dark;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-base + 0.2;
    margin-top: $margin-base * 1.5;
  }

  &__modal-description {
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-small - 0.1;
    color: $blue-dark;
    margin-top: $margin-base * 0.5;
  }

  &__slide-video-play-button {
    position: absolute;
    top: 50%;
    z-index: $z-index-carousel-video-play-button;
    padding: $padding-base * 1.5;
    transform: translate(-50%, -50%);
    left: 50%;
    cursor: pointer;
    background-color: $blue-dark;

    &:hover {
      background: $color-primary;
    }

    @include media($to: tbl-p) {
      padding: $padding-base/2;
    }
  }

  &__picture {
    img {
      position: absolute;

      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__slider-wrapper {
    width: 100%;
    height: 100%;
  }
}

@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';
@import '../../nhceVariables';

.splash-component-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: $height-navigation;

  @include media($from: tbl-p) {
    text-align: center;
  }

  > .splash-component__container {
    display: flex;
    justify-content: space-between;

    @include media($from: tbl-p) {
      display: block;
    }
  }

  &__logo {
    max-height: 45px;
    margin: $margin-base * 1.25;

    @include media($from: tbl-p) {
      max-height: 75px;
      text-align: center;
      margin: $margin-base * 0.3125 0;
    }
  }

  &__close-btn {
    border: 0;
    background-color: $grey-lighter;
    outline: none;
    text-align: center;
    padding: $padding-base;
    position: relative;
    right: -$padding-base;
    color: $color-dark-blue;
    cursor: pointer;

    @include media($from: tbl-p) {
      display: none;
    }
  }

  &__close-btn-icon {
    display: block;
    margin: 0 auto;
  }
}

.splash-component--contruction {
  .splash-component-header {
    &__close-btn {
      color: $color-nhce-black;
    }
  }
}

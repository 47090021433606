@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.dealer-locator-landing {
  background-color: $color-backgroud-grey;
  margin: 0 0 $margin-base * 5;

  @include media($from: dsk-l) {
    min-width: unset;
  }

  &__heading {
    padding-top: $padding-base * 2;
    position: relative;
    box-shadow: $shadow-pastel2;

    @include media($from: tbl-l) {
      box-shadow: none;
    }
  }

  &__heading-text {
    padding: $padding-base $padding-base 0;

    @include media($from: tbl-p) {
      padding: $padding-base 26.19% 0;
    }
  }

  &__title {
    color: $color-dark-blue;
    text-align: center;
    font-weight: bold;
    font-size: $font-size-large;
    line-height: $line-height-medium;
    letter-spacing: $letter-spacing-medium;
    margin: 0;

    @include media($from: tbl-p) {
      font-size: $font-size-larger;
      line-height: $line-height-large;
      letter-spacing: $letter-spacing-large;
    }
  }

  &__description {
    color: $color-dark-blue;
    text-align: center;
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    margin: $margin-base 0 0;

    @include media($from: tbl-p) {
      font-size: $font-size-base;
      margin: $margin-base * 1.5 0 0;
    }
  }

  &__tab {
    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      min-height: 52px;
      margin-top: $margin-base * 1.5;

      @include media($from: tbl-l) {
        display: none;
      }
    }

    padding: 0;
    background-color: unset;
    border: none;
    outline: none;
    font-size: $font-size-smaller;
    line-height: $line-height-smaller;
    font-weight: bold;
    letter-spacing: normal;
    color: $color-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    column-gap: $margin-base * 0.25;

    &-icon {
      flex-shrink: 0;
    }
  }

  &__results-and-map-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;

    @include media($from: tbl-l) {
      flex-direction: row;
    }
  }
}

@import '../../../../../styles/_variables.scss';
@import '../../../../../styles/_mixins.scss';

.filter-search {
  display: flex;
  box-shadow: $shadow-pastel2;
  background-color: $white;
  min-height: 50px;
  height: auto;

  &__input {
    margin-top: auto;
    margin-bottom: $margin-base * 0.375;
    width: inherit;
    flex-grow: 1;
    border: none;
    font-size: $font-size-base;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-medium;
    color: $black;

    &-wrapper {
      display: flex;
      align-items: center;
      column-gap: $margin-base * 0.5;
      width: 100%;
      position: relative;

      &:focus-within {
        border: 1px solid $black;
      }
    }
  }

  &__placeholder {
    position: absolute;
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $grey-dark;
    left: $margin-base * 2.875;
    right: $margin-base * 2.5;
    pointer-events: none;
  }

  &__input:focus + &__placeholder,
  &__input:not([value='']) + &__placeholder {
    font-size: $font-size-smaller;
    top: $margin-base * 0.375;
  }

  &__suggestion {
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    z-index: $z-index-attachment-dropdown;
    width: 100%;
    max-height: 164px;
    overflow-y: auto;
    @include scrollbars();
    padding: 0 $padding-base;
    background-color: $white;
    box-shadow: $shadow-pastel;
    box-sizing: border-box;
    display: none;

    &-item {
      position: relative;
      padding: $padding-base * 0.5 0;
      width: 100%;
      border-bottom: 1px solid $color-light-grey;
      font-size: $font-size-small;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
      color: $blue-dark;
      cursor: pointer;

      &:hover {
        color: $white;
      }

      &:hover::before {
        content: '';
        width: calc(100% + 32px);
        position: absolute;
        left: -$padding-base;
        top: 0;
        height: 100%;
        background-color: $color-primary;
        pointer-events: none;
        z-index: $z-index-search-dropdown-item-background;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  &__input:focus ~ &__suggestion,
  &__suggestion:active {
    display: unset;
  }

  &__search-icon {
    margin-left: $margin-base;
    box-shadow: $shadow-pastel2;
    background-color: $grey-medium;
  }

  &__close-icon {
    cursor: pointer;
    background-color: $grey-medium;
    position: absolute;
    right: $margin-base;
    visibility: hidden;
  }

  &__input:not([value='']) ~ &__close-icon {
    visibility: visible;
  }

  &__cta {
    cursor: pointer;
    outline: none;
    border: none;

    &--disabled.button {
      background-color: $grey-medium;
      pointer-events: none;
      color: $white;
    }
  }
}

@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

$line-width: 2px;
$item-height: 104px;
$icon-dsk-width: 39px;
$icon-mbl-width: 32px;

.find-equipment-item {
  height: $item-height;

  @include media($to: tbl-l) {
    align-items: center;
    box-shadow: 0px 20px 50px -30px $blue-pastel;
    display: flex;
    margin-bottom: $margin-base;
    background-color: $white;
  }

  @include media($from: tbl-l) {
    display: inline-block;
    text-align: center;
    vertical-align: top;

    &--2 {
      width: 24vw;
      max-width: 400px;
    }

    &--3 {
      width: 16vw;
      max-width: 270px;
    }
  }

  &__text {
    box-sizing: border-box;

    @include media($to: tbl-l) {
      padding: $padding-base * 1.5 0 $padding-base * 1.5 $padding-base * 1.5;
    }

    @include media($from: tbl-l, $to: dsk-m) {
      padding: 0 $padding-base;
    }

    @include media($from: dsk-m) {
      padding: 0 ($padding-base * 2);
    }

    & p,
    & a {
      overflow: hidden;
    }
  }

  & a {
    height: 100%;
  }

  & a,
  & p {
    color: $color-primary;
    display: inline-block;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-small;
    margin: 0;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;

    &:hover {
      color: $blue-dark;
      text-decoration: none;
    }

    &:focus,
    &:visited,
    &:active {
      color: $color-primary;
      text-decoration: none;
    }
  }

  &__content {
    @include media($to: tbl-l) {
      align-items: center;
      display: flex;
      height: 100%;
    }

    @include media($from: tbl-l) {
      box-sizing: border-box;
      margin-top: $margin-base * 0.25;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &--2 {
      @include media($from: tbl-l, $to: dsk) {
        padding: 0 $padding-base;
      }

      @include media($from: dsk) {
        padding: 0 ($padding-base * 2);
      }

      @include media($from: dsk-m) {
        padding: 0 ($padding-base * 4);
      }
    }

    &--3 {
      @include media($from: dsk-m) {
        padding: 0 $padding-base;
      }
    }
  }

  &__img {
    width: auto;

    @include media($to: tbl-l) {
      margin: $padding-base;
      max-height: $icon-mbl-width;
    }

    @include media($from: tbl-l) {
      margin-bottom: $margin-base * 0.5;
      max-height: $icon-dsk-width;
    }
  }
}

.find-equipment-line {
  @include media($to: tbl-l) {
    display: none;
  }

  @include media($from: tbl-l) {
    background-color: $grey-light;
    display: inline-block;
    height: $item-height;
    width: $line-width;

    &--last {
      display: none;
    }
  }
}

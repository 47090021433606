@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.carousel-transparent-nav-section {
  display: none;

  @include media($to: tbl-p) {
    display: block;

    &--left,
    &--right {
      position: absolute;
      border: 0;
      top: 30%;
      bottom: 30%;
      width: 100px;
      background: transparent;
      z-index: $z-index-series-overview-nav-section;
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }
}

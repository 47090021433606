@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

$quick-link-width: 50px;
$quick-links-mbl-height: 64px;
$quick-link-text-box-height: 50px;
$quick-link-line-width: 16px;
$icon-dsk-width: 28px;
$icon-mbl-width: 20px;
$border-mbl-width: 2px;
$line-dsk-height: 2px;

.border {
  background: $blue-pastel;
  height: $quick-links-mbl-height;
  width: $border-mbl-width;

  &--last {
    display: none !important;
  }
}

.quick-links-link {
  @include media($to: tbl-p) {
    text-align: center;
    vertical-align: top;
    flex-basis: 25%;
    flex-grow: 1;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: $margin-base;
      right: 0;
      width: 2px;
      height: 16px;
      background-color: $blue-pastel;
    }
    &:last-child {
      &:after {
        content: unset;
      }
    }
  }

  @include media($from: tbl-p) {
    position: relative;

    &:hover {
      & .quick-links-link__link,
      & .quick-links-link__link a {
        transform: translateX(0);
      }
    }
  }

  &__link,
  & .quick-links-link__link a {
    display: inline-block;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active,
    &:visited {
      text-decoration: none;
    }

    @include media($to: tbl-p) {
      height: calc(100% - calc(#{$padding-base} * 0.125));
      overflow: hidden;
      text-decoration: none;
      width: 100%;

      & p,
      & a {
        color: $blue-dark;
        font-size: $font-size-medium / 2;
        line-height: $line-height-small / 2;
        margin: 0;
        margin-top: $margin-base / 2;
      }

      &:hover,
      &:focus,
      &:active,
      &:visited {
        color: $blue-dark;
      }
    }

    @include media($from: tbl-p) {
      background: $quick-links-gradient-background;
      border-radius: 50px;
      color: $white;
      font-weight: 600;
      font-size: $font-size-base;
      height: $quick-link-text-box-height;
      letter-spacing: $letter-spacing-base;
      line-height: $line-height-small;
      margin: 0;
      position: absolute;
      right: 0;
      transform: translateX(100%);
      transition: transform 0.5s ease-out;
      vertical-align: top;
      white-space: nowrap;

      & p,
      & a {
        display: inline-block;
        height: auto !important;
        margin: 0 !important;
        padding: (($quick-link-text-box-height - $line-height-small) * 0.5) ($padding-base + $quick-link-width)
          (($quick-link-text-box-height - $line-height-small) * 0.5) $padding-base;
        text-transform: uppercase;
      }
    }
  }

  &__image-wrapper {
    @include media($to: tbl-p) {
      display: block;
      height: $icon-mbl-width;
      margin: ($margin-base * 0.75) auto 0;
      width: $icon-mbl-width;
    }

    @include media($from: tbl-p) {
      background-color: $white;
      box-shadow: -10px 0px 20px rgba(134, 188, 237, 0.3);
      display: inline-block;
      height: $quick-link-width;
      position: relative;
      width: $quick-link-width;

      &--first {
        border-top-left-radius: $quick-link-width/3;
      }

      &--last {
        border-bottom-left-radius: $quick-link-width/3;
      }
    }
  }

  &__image {
    height: 100%;
    @include media($to: tbl-p) {
      overflow: hidden;
      & img {
        max-height: $icon-mbl-width;
        max-width: $icon-mbl-width;
      }
    }

    @include media($from: tbl-p) {
      align-items: center;
      display: flex;
      justify-content: center;
      overflow: hidden;
      & img {
        max-height: $icon-dsk-width;
        max-width: $icon-dsk-width;
      }
    }
  }

  &__line {
    background-color: $blue-pastel;
    bottom: 0;
    height: $line-dsk-height;
    left: ($quick-link-width - $quick-link-line-width) * 0.5;
    position: absolute;
    width: $quick-link-line-width;

    &--last {
      display: none;
    }
  }
}

@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.special-offers-wrapper {
  @include media($from: tbl-p) {
    .carousel-arrow {
      display: none;
    }
    &:hover {
      .carousel-arrow {
        display: flex;
      }
    }
  }
}

.special-offers {
  position: relative;
  margin: 0 auto;
  max-width: $width-max-page;
  display: flex;
  flex-direction: column;
  padding-left: $padding-base;
  padding-top: $padding-base * 3;

  @include media($from: tbl-p) {
    padding-left: $padding-base * 2;
    padding-top: $padding-base * 5;
  }

  &__arrow {
    top: 0;

    .custom-arrow__button {
      display: flex;
    }
  }

  .custom-arrow--right {
    .custom-arrow__button {
      margin-right: $margin-base/2;
    }
  }

  &__card {
    position: relative;
  }

  &__footer {
    height: 50px;
  }

  &__dots {
    // overwrite the default library style react-slick that put on style display: block
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-top: $margin-base * 1.5;
    margin-bottom: $margin-base * 2;
    overflow-x: auto;
    flex-wrap: wrap;

    li {
      border-radius: 50%;
      width: 8px;
      min-width: 8px;
      height: 8px;
      min-height: 8px;
      opacity: 0.3;
      background-color: $blue-dark;
      margin: $margin-base / 4 $margin-base;
    }

    .slick-active {
      opacity: 1;
      min-width: 10px;
      width: 10px;
      height: 10px;
      min-height: 10px;
    }
  }
}

@import '../../../../styles/variables';
@import '../../../../styles/_mixins.scss';

.dealer-locator-landing-search {
  margin-top: 2 * $margin-base;

  @include media($from: tbl-l) {
    display: flex;
    justify-content: center;
  }

  &__error-msg {
    top: -100px; // should be 40px below header in non desktop resolutions
    min-width: 343px;
    max-width: 343px;
    &.dealer-locator-landing-toast-msg--show {
      z-index: $z-index-geo-location-message;
    }
  }

  &__users-location-btn {
    display: grid;
    height: 50px;
    width: 50px;
    background-color: $color-dark-blue;
    place-content: center;
    cursor: pointer;
    margin-left: $margin-base / 1.6;

    @include media($from: tbl-l) {
      display: none;
    }
  }

  &__col {
    width: 100%;
    padding: 0 $padding-base / 2 $margin-base * 1.5;
    box-sizing: border-box;
    display: flex;

    @include media($from: tbl-l) {
      padding: 0;
      margin: 0 $margin-base / 2 $margin-base * 1.5;
    }

    &--dropdown {
      @include media($from: tbl-l) {
        max-width: 392px;
      }
    }

    &--text-search {
      @include media($from: tbl-l) {
        max-width: 800px;
      }
    }
  }

  &__dropdown {
    position: relative;
    width: 100%;
  }

  &__dropdown-input {
    box-sizing: border-box;
    background-color: $color-white;
    border: 1px solid $color-white;
    height: 50px;
    padding: 0.75 * $padding-base  $padding-base * 0.25  $padding-base $padding-base;
    box-shadow: 0 4px 20px -10px $color-input-shadow;

    &--open {
      border-color: $color-primary;
    }
  }

  &__dropdown-input-field {
    color: $color-dark-blue;
    display: block;
    padding: 0;
    border: 0;
    font-size: $font-size-small;
    line-height: $line-height-small;
  }

  &__dropdown-input-text,
  &__text-search-input {
    color: $color-dark-blue;
    height: 24px;
    display: block;
    line-height: 24px;
    padding: 0;
    border: 0;
    font-size: $font-size-small;
    width: 100%;
  }

  &__dropdown-input-icon,
  &__text-search-input-icon,
  &__text-search-clear-icon {
    position: absolute;
    top: 13px;
  }

  &__dropdown-input-icon {
    right: 8px;
  }

  &__text-search-clear-icon {
    right: 16px;
    cursor: pointer;
  }

  &__text-search-input-icon {
    left: 16px;
  }

  &__text-search-google-logo {
    float: right;
    padding: $margin-base * 0.5 $margin-base $margin-base;
  }

  &__dropdown-list,
  &__text-search-suggestions {
    cursor: pointer;
    margin: 0;
    background-color: $color-white;
    position: absolute;
    width: 100%;
    top: 100%;
    max-height: 360px;
    overflow-y: auto;
    z-index: $z-index-custom-dropdown;
    @include scrollbars();
  }

  &__text-search-suggestions {
    max-height: 402px;
    margin: 0;
    background-color: $color-white;
    position: absolute;
    width: 100%;
    top: 100%;
  }

  &__dropdown-label,
  &__text-search-label {
    color: $color-dark-grey;
    position: absolute;
    font-size: $font-size-smaller;
    top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 64px);

    &--center {
      top: 16px;
      font-size: $font-size-small;
    }
  }

  &__text-search-label {
    left: 48px;
  }

  &__dropdown-list-item,
  &__text-search-suggestions-list-item {
    color: $color-dark-blue;
    padding: $padding-base/2 $padding-base;
    font-size: $font-size-small;
    line-height: $line-height-small;

    &--highligted {
      background-color: $color-light-grey;
    }
  }

  &__text-search {
    position: relative;
    width: 100%;
  }

  &__text-search-input-wrapper {
    position: relative;
    box-sizing: border-box;
    background-color: $color-white;
    border: 1px solid $color-white;
    padding: 1.25 * $padding-base $padding-base $padding-base / 4 $padding-base * 3;
    box-shadow: 0 4px 20px -10px $color-input-shadow;
  }

  &__text-search-suggestion-group {
    margin: 0;
  }

  &__text-search-suggestion-group-title {
    color: $color-medium-grey;
    font-size: $font-size-smaller;
    padding: $padding-base / 4 $padding-base $padding-base / 16;
  }

  &__text-search-suggestion-list-item-address {
    color: $color-medium-grey;
    font-size: $font-size-smaller;
    margin: 0 $margin-base / 4;
    line-height: $line-height-smaller;
  }
}

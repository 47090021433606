@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.plm-series-detail-overview {
  background-color: $white;
  overflow: hidden;

  &__container {
    @include max-width;
    @include media($from: tbl-p) {
      display: grid;
      grid-template-columns: 34% 66%;
      min-height: 768px;

      > :nth-child(1) {
        grid-row: 1/2;
        grid-column: 2/3;
      }
      > :nth-child(2) {
        grid-row: 1/2;
        grid-column: 1/2;
      }
    }
  }
}

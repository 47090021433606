@import '../../../../../../styles/_variables.scss';

.mobile-login-tab {
  margin: ($margin-base * 1.125) $margin-base 0;

  &__toggler {
    display: flex;
    align-items: center;
  }

  .login-link {
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
  }

  .user-details {
    margin-left: $margin-base;
    flex-grow: 1;

    &__name,
    &__company {
      @extend .login-link;
      margin-bottom: 0;
    }

    &__name {
      font-weight: $font-weight-bold;
    }
  }

  &__tray {
    margin: ($margin-base * 2) ($margin-base * 1.5) 0;

    .user-account {
      margin-bottom: $margin-base * 2;

      &__link {
        @extend .login-link;
        font-weight: $font-weight-bold;
        margin-bottom: $margin-base * 1.25;

        &:last-child {
          margin-bottom: none;
        }
      }
    }
  }

  .other-links--mbl {
    margin-top: $margin-base * 2;
  }
}
@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.footer-action-section {
  margin-bottom: $margin-base * 2;
  @include media('tbl-l') {
    margin-bottom: 0;
  }
  .dealer-login,
  .dealer-request {
    margin: $margin-base * 1.5 0;

    @include media($from: tbl-p) {
      margin-bottom: 0;
      margin-top: $margin-base * 4;
    }
  }
  .phone-link {
    display: inline-block;
    margin-top: $margin-base * 2;
    margin-right: $margin-base * 1.5;
  }
}

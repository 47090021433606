@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

$dropdown-panel-mobile-height: 44px;
$height-series-navigation: 74px;
$height-series-navigation-mobile: 44px;
$navigation-tab-gap: 24px;

.navigation-bar-container {
  @include max-width-fullscreen;
  height: $height-series-navigation;
  box-shadow: 0 0 30px -10px $color-pastel;
  z-index: $z-index-series-navigation;
  background-color: $color-white;
  color: $blue-dark;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 ($padding-base * 2);
  left: 0;
  right: 0;
  position: relative;

  @include media($from: dsk-l) {
    box-shadow: none;
    box-sizing: border-box;

    &:before,
    &:after {
      box-shadow: 0 0 30px -10px $color-pastel;
    }
  }

  &__dropdown {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: $margin-base * 1.5;
    min-width: 11%;

    @media screen and (max-width: map-get($breakpoints, dsk)) {
      display: none;
    }
  }

  &__dropdown-content {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
  }

  &__dropdown-data,
  &__editorial-title {
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    font-stretch: normal;
    font-style: normal;
    line-height: $line-height-normal;
    letter-spacing: $letter-spacing-base;
    color: $blue-dark;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__dropdown-data {
    margin-right: $margin-base / 2;
  }

  &__editorial-title {
    @include media($to: dsk) {
      display: none;
    }
  }

  &__dropdown-icon {
    cursor: pointer;

    @media screen and (max-width: map-get($breakpoints, tbl-p)) {
      margin: $margin-base - 3 $margin-base / 2 $margin-base - 3 0;
    }

    &--is-open {
      transform: rotate(180deg);
    }
  }

  &__dropdown-list {
    position: absolute;
    padding: 0;
    margin: 0;
    background-color: $color-white;
    box-shadow: 0 0 30px -10px $color-pastel;
    cursor: pointer;
    width: 100%;
    top: 53px;
    left: 0;
    z-index: $z-index-sticky-nav-dropdown;
    max-height: 50vh;
    overflow: auto;
    @include custom-scroll-bar;
  }

  &__dropdown-list-item {
    padding: $padding-base / 2 $padding-base;
    border-bottom: 1px solid $color-light-grey;
    color: $blue-dark;
    font-size: $font-size-small;
    line-height: $line-height-medium - 1;
    letter-spacing: 1px;
    margin: 0;
    background-color: $color-white;

    &:hover {
      background-color: $grey-light;
    }

    &:last-child {
      border-bottom: none;
    }

    a,
    a:hover {
      text-decoration: none;
      color: $blue-dark;
      margin: 0;
      display: block;
    }
  }

  &__tabs {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    height: 100%;
    padding: 0;

    @media screen and (max-width: map-get($breakpoints, dsk)) {
      justify-content: flex-start;
    }
  }

  &__tab {
    position: relative;
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    line-height: $line-height-small;
    letter-spacing: 1px;
    color: $blue-dark;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 $margin-base / 2;
    padding: 0 $padding-base / 2;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0; 
      height: 3px;
      background-color: $blue-dark;
      transition: width 0.3s ease;
    }

    > * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      font-weight: $font-weight-bold;
      text-decoration: none;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &--active {
      color: $blue-dark;
      text-decoration: none;
      font-weight: $font-weight-bold;

      &:before {
        width: 100%;
      }
    }

    &--hidden {
      display: none;
    }
  }

  &__price-container {
    display: flex;
    flex-direction: column;
    height: 50px;
    align-items: flex-end;
    justify-content: center;
    margin-left: $margin-base * 1.5;
  }

  &__price-optional-label {
    font-size: $font-size-small;
    line-height: $line-height-small;
    color: $color-dark-blue;
    letter-spacing: 0.8px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__price {
    font-size: $font-size-smaller;
    line-height: $line-height-smaller;
    color: $color-dark-blue;
    font-weight: $font-weight-bold;
    display: flex;
    flex-direction: row;

    * {
      margin: 0;
    }
  }

  &__price-label {
    font-weight: $font-weight-normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__button-container {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: $margin-base * 1.5;
  }
}

.navigation-bar-mobile {
  width: 100%;
  left: 0;
  right: 0;
  z-index: $z-index-series-navigation;
  background-color: $color-white;
  height: $height-series-navigation-mobile;
  color: $blue-dark;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: $navigation-tab-gap;
  overflow: auto;
  box-shadow: 0 0 30px -10px $color-pastel;
  position: relative;

  &__tab {
    position: relative;
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $blue-dark;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0;

    > * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:first-child {
      margin-left: $margin-base;
    }

    &:last-child {
      margin-right: $margin-base * 2;
    }

    &:hover {
      font-weight: $font-weight-bold;
      text-decoration: none;
      color: $blue-dark;
    }

    &--active {
      color: $blue-dark;
      font-weight: $font-weight-bold;
      text-decoration: none;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        background-color: $blue-dark;
        height: 3px;
        bottom: 0;
      }
    }

    &--hidden {
      display: none;
    }
  }
}

.navigation-box-mobile {
  background-color: $color-white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: $padding-base * 1.5 $padding-base;

  &__price-container {
    display: flex;
    flex-direction: column;
    height: 50px;
    align-items: flex-end;
    justify-content: center;
    margin-right: $margin-base;
  }

  &__price-optional-label {
    font-size: $font-size-small;
    line-height: $line-height-small;
    color: $color-dark-blue;
    letter-spacing: 0.8px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__price {
    font-size: $font-size-smaller;
    line-height: $line-height-smaller;
    color: $color-dark-blue;
    font-weight: $font-weight-bold;
    display: flex;
    flex-direction: row;

    * {
      margin: 0;
    }
  }

  &__price-label {
    font-weight: $font-weight-normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__button-container {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__button {
    background-color: $yellow-primary;
    box-shadow: none;
    border: none;
    outline: none;
    color: $black;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    line-height: $line-height-base;
    letter-spacing: 1px;
    padding: 0 $padding-base * 1.5;
    text-transform: uppercase;
    height: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;

    &:hover {
      background-color: $yellow-bright;
      color: $black;
      text-decoration: none;
      box-shadow: 0 10px 20px 0 rgba(255, 211, 0, 0.3);
    }
  }
}

@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

@mixin dealer-locator-max-width() {
  margin: 0 auto;
  max-width: 800px;

  @include media($to: tbl-l) {
    padding: 0 $padding-base;
  }
}

.dealer-locator-detail {
  padding: $padding-base * 5 0;

  @include media($to: tbl-l) {
    padding: $padding-base * 3 0 $padding-base * 2.5;
  }

  &__header {
    @include dealer-locator-max-width;

    @include media($to: tbl-l) {
      padding: 0;
      margin: 0 $margin-base;
    }

    border-bottom: 1px solid $color-light-grey;
  }

  &__eyebrow {
    font-size: $font-size-small;
    line-height: $line-height-small;
    color: $color-medium-grey;
    letter-spacing: $letter-spacing-base;
    margin-bottom: $margin-base * 0.5;
    text-transform: uppercase;

    @include media($from: tbl-p) {
      font-size: $font-size-base;
    }
  }

  &__title {
    font-size: $font-size-large;
    line-height: $line-height-medium;
    color: $color-dark-blue;
    letter-spacing: $letter-spacing-medium;
    margin: 0 0 $margin-base 0;
    font-weight: $font-weight-bold;

    @include media($from: tbl-p) {
      font-size: $font-size-larger;
      line-height: $line-height-large;
      letter-spacing: $letter-spacing-large;
      margin: 0 0 $margin-base * 1.5 0;
    }
  }

  &__social-media-bar {
    margin: $margin-base * 3 0 $margin-base * 1.5 0;

    .social-media__social {
      padding: 0;

      &-icon-link:last-of-type {
        margin-right: 0;
      }
    }
  }

  &__description {
    @include dealer-locator-max-width;
    font-size: $font-size-small;
    line-height: $line-height-small;
    color: $color-dark-blue;
    letter-spacing: $letter-spacing-base;
    margin-top: $margin-base * 1.5;

    @include media($from: tbl-p) {
      font-size: $font-size-base;
      margin-top: $margin-base * 2;
    }
  }

  &__subtitle {
    margin: 0 0 $margin-base * 3 0;
  }

  &__media {
    @include dealer-locator-max-width;
    margin-top: $margin-base * 1.5;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;

    @include media($to: tbl-l) {
      padding: 0;
    }

    &__dealer-image {
      &-wrapper {
        margin-top: $margin-base * 2;
      }

      @include fixed-picture-aspect-ratio(343, 192);

      @include media($from: tbl-p) {
        @include fixed-picture-aspect-ratio(800, 450);
      }

      @include media($to: tbl-p) {
        &-wrapper {
          padding: 0 $padding-base;
        }
      }
    }

    &__logo-image {
      max-width: 256px;
      max-height: 100px;
      object-fit: contain;

      @include media($to: tbl-p) {
        &-wrapper {
          padding: 0 $padding-base;
        }
      }
    }
  }

  &__contacts {
    &--wrapper {
      @include dealer-locator-max-width;

      @include media($to: tbl-l) {
        padding: $padding-base * 1.5 $padding-base;
      }
    }
  }

  &__business-hours-section {
    @include dealer-locator-max-width;

    @include media($to: tbl-l) {
      padding: $padding-base * 1.5 0 $padding-base * 1.5 0;
    }
  }

  &__promotions {
    @include dealer-locator-max-width;
    line-height: $line-height-small;
    padding: $padding-base * 2 0;

    @include media($to: tbl-l) {
      padding: $padding-base * 1.5 $padding-base;
    }

    &__title {
      font-size: $font-size-medium;
      font-weight: bold;
      letter-spacing: $letter-spacing-small;

      @include media($to: tbl-l) {
        line-height: $line-height-base;
      }
    }

    &__text {
      font-size: $font-size-base;
      margin-top: $margin-base * 0.5;

      @include media($to: tbl-p) {
        font-size: $font-size-small;
      }
    }
  }
}

@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';
@import '../../nhceVariables';

.splash-component-market-selector {
  @include media($from: tbl-p) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: $padding-base * 2;
    padding-bottom: ($padding-base * 2.5) + $splash-page-footer-height;
    background-color: $grey-lighter;
  }

  .splash-component__container {
    display: flex;
    justify-content: space-between;
  }

  &__list {
    display: block;

    @include media($from: tbl-p) {
      display: grid;
      grid-template-columns: auto auto auto;
      grid-auto-rows: min-content;
    }
  }

  &__list-item {
    margin-bottom: $margin-base * 1.25;

    &:first-child {
      margin-top: $margin-base * 0.5;
    }

    &:last-child {
      margin-top: $margin-base * 1.5;
    }

    @include media($from: tbl-p) {
      margin: $margin-base * 0.75 $margin-base * 3.75 $margin-base * 0.75 0;

      &:first-child,
      &:last-child {
        margin: $margin-base * 0.75 $margin-base * 3.75 $margin-base * 0.75 0;
      }
    }
  }

  &__list-item-link {
    text-transform: uppercase;
    color: $color-dark-blue;
    text-decoration: none;
    cursor: pointer;
    font-weight: $font-weight-bold;
    font-family: $font-family;
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &__image {
    display: none;
    max-width: 100%;
    height: auto;

    @include media($from: dsk) {
      display: block;
    }
  }
}

.splash-component--contruction {
  .splash-component-market-selector {
    &__list-item-link {
      color: $color-nhce-black;
    }
  }
}

@import '../../../../../styles/_variables.scss';
@import '../../../../../styles/_mixins.scss';

.media-carousel-arrows {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: $blue-dark;
  outline: none;
  position: absolute;
  padding: $padding-base * 0.25;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  &:hover {
    background-color: $color-primary-blue;
    box-shadow: 0 10px 20px 0 $color-arrow-hover-box-shadow;
  }

  &--previous {
    left: 32px;

    @include media($from: tbl-p) {
      left: 80px;
    }

    z-index: $z-index-series-overview-nav-section-arrow;
  }

  &--next {
    right: 32px;

    @include media($from: tbl-p) {
      right: 80px;
    }

    z-index: $z-index-series-overview-nav-section-arrow;
  }
}

@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

$margin-button-carousel: 1.5px;
$blue-dark-background: #0a1a32bf;
$dot-color: #e5ded5;
$height-herobanner-not-in-homepage: calc(100vh - 85px);

@keyframes animationBackgroudItem {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

#root {
  overflow: hidden;
}

.hero-carousel {
  $root: &;
  display: block;
  height: $vh100;
  position: relative;
  margin-top: -$height-navigation;

  & .slide {
    height: $vh100;
    position: relative;

    &__inner {
      height: 100%;
      width: 100%;
      position: relative;
      overflow: hidden;
    }

    &__image,
    &__video {
      min-width: 100%;
      min-height: 100%;
      max-width: none;
      width: auto;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      position: absolute;
      z-index: $z-index-carousel-img;
      height: auto;
    }
    &__image {
      @include media($to: mbl) {
        height: 100%;
        min-height: 0;
      }
    }
  }

  &__not-in--homepage {
    height: $height-herobanner-not-in-homepage;
    margin-top: 0;

    .slide {
      height: $height-herobanner-not-in-homepage;
    }
  }

  .slick-slider {
    .carousel-dots-wrapper {
      align-items: center;
      bottom: 110px;
      position: absolute;
      width: 100%;
      height: 10px;
      justify-content: center;
      display: none;
      @include media($to: tbl-p) {
        display: flex !important;
        .carousel-dots {
          background-color: $white;
          opacity: 0.8;
          &--active {
            opacity: 1;
          }
        }
      }
    }
  }

  &__navigation {
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
  }

  &__dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 $margin-base * 2;

    @include media($from: tbl-p) {
      display: none;
    }

    #{$root}__item {
      display: none;
    }

    .slick-active {
      #{$root}__dot {
        transform: scale(1.2);
        background-color: #fff;
      }
    }
  }

  &__items {
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    #{$root}__dot {
      display: none;
    }

    li {
      position: relative;
      width: 100vw;
      height: 80px;
      display: none;
      align-items: center;
      justify-content: center;
      background-color: $blue-dark-background;

      @include media($from: tbl-p) {
        width: auto;
        display: flex;
        flex-grow: 1;
        margin: 0 $margin-button-carousel;

        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 0%;
        background-color: $blue-dark;
        transition: width 10s linear;
      }

      &.slick-active {
        display: flex;
        &:before {
          animation-name: animationBackgroudItem;
          animation-duration: 10s;
          animation-timing-function: linear;
          animation-fill-mode: forwards;
        }
      }
    }
  }

  &__dot {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: $dot-color;
    margin: 0 $margin-base * 0.625;
    transition: all 0.5s linear;
    cursor: pointer;
  }

  &__item {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    * {
      color: $color-white;
      text-decoration: none;
      font-size: $font-size-base;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
    }
  }

  &__item-link {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.noscroll {
  overflow-y: hidden;
  margin-right: var(--body-scrollbar-width--scroll-hidden, 0px);
}

@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';
@import '../../nhceVariables';

.splash-component-sector-selector {
  padding: $padding-base * 2.5;
  opacity: 0.85;
  background-color: $white;
  text-align: center;
  position: relative;
  z-index: $z-index-splash-page-sector-selector;
  margin-top: auto;

  @include media($from: tbl-p) {
    margin-top: 0;
  }

  &--active {
    opacity: 1;
    display: none;

    @include media($from: tbl-p) {
      display: block;
    }
  }

  &--top {
    margin-bottom: auto;
    margin-top: 0;
    @include media($from: tbl-p) {
      margin-bottom: 0;
    }
  }

  &__title {
    max-width: 800px;
    margin: 0 auto $margin-base * 1.625;
    font-family: $font-family;
    font-weight: $font-weight-bold;
    text-align: center;
    color: $color-dark-blue;
    font-size: $font-size-large;
    line-height: $line-height-large;
    letter-spacing: $letter-spacing-medium;

    @include media($from: tbl-p) {
      font-size: $font-size-larger;
      line-height: $line-height-large;
      letter-spacing: $letter-spacing-large;
    }
  }

  &__inputs {
    border: 0;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  &__input-wrapper {
    display: flex;

    &::after  {
      content: '';
      display: block;
      background-color: $color-light-grey;
      width: 2px;
      height: 22px;
      margin-left: 1.5 * $margin-base;
      margin-right: 1.5 * $margin-base;
    }

    &:last-child{
      &::after {
        content: none;
      }     
    }
  }

  &__radio {
    appearance: none;
    display: none;
  }

  &__radio-btn-icon {
    margin-right: $margin-base / 2;
  }

  &__label {
    display: flex;
    font-size: $font-size-small;
    align-items: center;
  }
}

.splash-component--contruction {
  .splash-component-sector-selector {
    &__radio-btn-icon {
      background-color: $color-nhce-black;
    }
  }
}

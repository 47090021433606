@import '../../../../../../styles/_variables.scss';
@import '../../../../../../styles/_mixins.scss';

.dealer-droplist-filter {
  .dealer-droplist-font-styles {
    font-size: $font-size-small;
    line-height: $line-height-small;
    color: $color-dark-blue;
    letter-spacing: $letter-spacing-base;
  }

  $one-option-column-width: 270px;
  $root: &;

  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: $margin-base;
  padding: 0 $padding-base * 1.5;
  border-left: 2px solid $color-light-grey;

  &:first-child {
    padding-left: 0;
    border-left: none;
  }

  &__title {
    @extend .dealer-droplist-font-styles;
    pointer-events: none;
  }

  &__icon {
    pointer-events: none;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  &__panel {
    width: calc(#{$one-option-column-width} * var(--option-column-count, 1));
    position: absolute;
    top: 100%;
    transform: translateX(-$margin-base * 1.125);
    z-index: $z-index-dealer-filter-droplist;
    background-color: $white;
    padding: 0 $padding-base;
    box-shadow: $shadow-pastel;

    &--open {
      animation: fadeIn 0.3s ease-in-out;
    }

    &--closed {
      opacity: 0;
      animation: fadeOut 0.3s ease-in-out;
    }

    &--right {
      right: 0;
    }
  }

  &__option-wrapper {
    display: grid;
    grid-template-columns: repeat(var(--option-column-count, 1), auto);
    column-gap: $margin-base;
  }

  &__option {
    display: flex;
    align-items: center;
    column-gap: $margin-base * 0.5;
    position: relative;
    padding: $padding-base * 0.5 0;
    border-bottom: 1px solid $color-light-grey;
    @extend .dealer-droplist-font-styles;

    .icon-wrapper {
      flex-shrink: 0;
    }
  }

  &__cta-wrapper {
    padding: $padding-base * 1.5 0;
    display: flex;
    gap: $margin-base;
    flex-wrap: wrap;
    justify-content: flex-end;

    button.button {
      white-space: normal;
      word-break: break-word;
      text-transform: unset;
    }
  }

  &__cta {
    &--apply-filters.button {
      border: none;
    }

    &--disabled.button {
      cursor: not-allowed;
      background-color: $color-medium-grey;
      color: $white;
      border: 2px solid $color-medium-grey;

      &:hover {
        color: $white;
        border: 2px solid $color-medium-grey;
        box-shadow: none;
      }
    }
  }
}

@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';
@import '../../nhceVariables';

.region-selector {
  background-color: $grey-lighter;
  height: 100%;
  position: absolute;
  width: 100%;
  max-height: calc(100vh - #{$splash-page-header-height});
  overflow: auto;

  @include media($from: tbl-p) {
    display: flex;
    flex-direction: column;
    background-color: $white;
    position: relative;
    flex-grow: 1;
    max-height: 100%;
    overflow: auto;
  }

  &__title {
    margin: 2 * $margin-base $margin-base $margin-base;
    font-family: $font-family;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $color-dark-blue;

    @include media($from: tbl-p) {
      display: none;
    }
  }

  &__row {
    @include media($from: tbl-p) {
      box-shadow: 0 4px 20px -10px $card-box-scroll-shadow;
    }
  }

  &__list {
    display: flex;
    margin: 0;
    flex-direction: column;

    @include media($from: tbl-p) {
      flex-direction: row;
    }
  }

  &__list-title {
    cursor: pointer;
    font-family: $font-family;
    font-size: $font-size-base;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $color-dark-blue;
    padding: $padding-base 0;
    display: flex;
    justify-content: space-between;

    @include media($from: tbl-p) {
      display: inline-block;
      padding: 1.875 * $padding-base 1.25 * $padding-base 1.875 * $padding-base;
      font-weight: $font-weight-bold;
    }

    &--active,
    &:hover {
      font-weight: $font-weight-bold;
      background-color: $grey-lighter;
    }
  }

  &__list-title-icon {
    @include media($from: tbl-p) {
      display: none;
    }
  }

  &__image {
    display: none;
    @include media($from: tbl-p) {
      margin: $margin-base * 1.5 auto;
      display: block;
    }
  }
}

.splash-component--contruction {
  .region-selector {
    &__title {
      color: $color-nhce-black;
    }

    &__list-title {
      color: $color-nhce-black;
    }
  }
}

@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

$text-container-padding: 23.7%;

.hotspot-split-module-wrapper {
  box-sizing: border-box;

  @include media($from: tbl-p) {
    padding: $padding-base * 6.5 0 $padding-base * 4;
  }
}

.hotspot-split-module {
  $root: &;
  @include max-width();
  position: relative;
  padding-top: $padding-base * 5;
  box-sizing: border-box;

  @include media($from: tbl-p) {
    display: flex;
    flex-direction: row;
    background-color: $white;
    padding-top: 0;
    min-height: 301px;

    &--left {
      justify-content: flex-start;

      #{$root}__media-container {
        right: 0;
      }
    }

    &--right {
      justify-content: flex-end;

      #{$root}__media-container {
        left: 0;
      }

      #{$root}__dot-image {
        left: unset;
        right: 45%;
      }

      #{$root}__text-container {
        align-items: flex-end;
        padding-left: $padding-base * 14.375;
        padding-right: 2 * $padding-base;
      }
    }
  }

  @include media($from: dsk) {
    &--right {
      #{$root}__text-container {
        flex-wrap: nowrap;
        align-items: flex-end;
      }
    }
  }

  @include media($from: tbl-p, $to: tbl-l) {
    &--right {
      #{$root}__dot-image {
        right: 42.5%;
      }
    }
  }

  &__dot-image {
    position: absolute;
    right: 0;
    top: 0;
    z-index: $z-index-background-media;

    @include media($from: tbl-p) {
      z-index: $z-index-medium;
      width: 301px;
      right: 15%;
      left: 45%;
      top: -$padding-base * 1.5;
    }

    @include media($from: tbl-p, $to: tbl-l) {
      left: 42.5%;
    }
  }

  &__media-container {
    display: block;
    position: relative;
    width: 100%;

    .hotspot-split-module {
      &__media-wrapper,
      &-image,
      &__video-wrapper,
      &__image {
        position: relative;
        padding-top: 56.25%;
      }
    }

    img,
    iframe,
    video {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }

    img {
      object-fit: cover;
    }

    @include media($from: tbl-p) {
      width: 49%;
      position: absolute;
      z-index: $z-index-gallery-layout-level-two;
      top: $margin-base * 3.5;
    }

    @include media($from: dsk) {
      width: 49.4%;
    }

    @include media($from: dsk-max-modal-bp) {
      width: 49.5%;
    }
  }

  &__text-container {
    background-color: $blue-dark;
    padding-top: $padding-base;
    padding-bottom: $padding-base * 2.5;
    flex-direction: column;

    @include media($from: tbl-p) {
      width: 64%;
      margin: 0;
      flex-wrap: nowrap;
      align-items: flex-start;
      box-sizing: border-box;
      padding: 5 * $padding-base $text-container-padding 2 * $padding-base 2 * $padding-base;
      min-height: 470px;
    }

    @include media($from: dsk) {
      width: 56%;
      margin: 0;
    }
  }

  &__eyebrow {
    color: $white;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-small;
    font-size: $font-size-small;
    text-transform: uppercase;
    margin: 0 0 $margin-base / 2;

    @include media($from: tbl-p) {
      font-size: $font-size-base;
    }
  }

  &__title {
    color: $white;
    font-size: $font-size-large;
    line-height: $line-height-medium;
    letter-spacing: $letter-spacing-medium;
    margin: 0 0 $margin-base / 2;
    font-weight: $font-weight-bold;

    @include media($from: tbl-p) {
      font-size: $font-size-large;
      line-height: $line-height-medium;
      letter-spacing: $letter-spacing-small;
      margin: 0 0 $margin-base;
    }
  }

  &__text {
    color: $white;
    margin-bottom: $margin-base / 2 0 0;
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;

    @include media($from: tbl-p) {
      margin: 0;
    }

    &.rich-text {
      a,
      a:visited,
      a:focus,
      a:hover {
        color: $color-pastel;
      }
    }

    p {
      margin-bottom: $margin-base;
    }
  }

  &__buttons {
    margin-top: $margin-base * 1.5;

    @include media($from: tbl-p) {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    @include media($from: dsk) {
      flex-direction: row;
      align-items: center;
    }

    &--media {
      background-color: initial;
      display: flex;

      @include media($from: tbl-p) {
        &:first-child {
          margin-right: $margin-base;
        }
      }

      #{$root}__icon {
        padding: 0;
        border-radius: 10px;
        overflow: hidden;

        &:hover {
          box-shadow: none;
        }

        & img {
          width: auto;
          height: 50px;
        }
      }
    }
  }

  &__primary-link,
  &__secondary-link {
    margin-top: $margin-base * 1.5;
    display: inline-block;
  }

  &__primary-link {
    background-color: $white;

    #{$root}__cta-icon {
      text-align: left;
      color: $color-dark-blue;

      &:hover {
        color: $color-primary-blue;
        box-shadow: 0 10px 20px $blue-pastel-04;
      }
    }
  }

  &__secondary-link {
    #{$root}__cta-icon {
      padding: 0 $padding-base * 1.5;
      text-align: left;
      color: $white;
      transform: perspective(1px) translateZ(0);
      transition: all 0.1s ease-in;

      &:hover {
        text-decoration: underline;
        text-underline-offset: 6px;
      }
    }
  }

  &__cta-icon {
    padding: $padding-base $padding-base * 1.5;
    position: relative;
    text-decoration: none;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-small;
    text-transform: uppercase;
    align-items: center;
    display: inline-block;

    &:hover {
      #{$root}__arrow {
        margin-left: $margin-base * 0.5;
        transition: all 0.1s ease-in;
      }
    }
  }

  &__arrow {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: $margin-base * 0.1875;
    background-color: $white;
  }
}

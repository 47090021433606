@import '../../../../styles/variables';
@import '../../../../styles/_mixins.scss';

.dealer-locator-landing-dealer-results {
  background-color: $color-white;
  width: 100%;

  @include media($from: tbl-l) {
    width: 556px;
    flex-shrink: 0;
    max-height: 750px;
    overflow-y: auto;
    box-shadow: $box-shadow-dealer-locator-results;
    z-index: $z-index-medium;
    @include scrollbars($size: 4px, $background-color: transparent, $border-radius: 2px);

    &--bottom-shadow::after {
      display: block;
      content: '';
      position: absolute;
      height: 40px;
      width: inherit;
      bottom: 0;
      background-image: linear-gradient(to bottom, rgba(6, 8, 8, 0) 0%, rgba(6, 8, 8, 0.1) 90%);
    }

    &--top-shadow::before {
      display: block;
      content: '';
      position: absolute;
      height: 40px;
      width: inherit;
      top: 0;
      background-image: $dealer-results-top-gradient-shadow;
    }
  }

  @include media($from: tbl-l, $to: dsk) {
    width: 410px;
  }

  &__list {
    margin: 0;
  }

  &__dealers-count {
    margin: $margin-base * 2 $margin-base * 2 $margin-base;
    font-size: $font-size-base;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $color-medium-grey;
  }

  &__item {
    padding: $padding-base * 1.625 $padding-base * 2 $padding-base;

    &::after {
      content: '';
      margin-top: $margin-base;
      position: relative;
      display: block;
      width: 100%;
      height: 1px;
      background-color: $color-light-grey;
    }

    &--active {
      background-color: $color-backgroud-grey;
    }
  }

  &__item-header {
    display: flex;
    justify-content: space-between;
    column-gap: $margin-base * 0.5;
  }

  &__item-marker-wrapper {
    flex-shrink: 0;
    text-align: center;
  }

  &__item-certified {
    text-transform: uppercase;
    width: fit-content;
    margin-bottom: $margin-base / 2;
    padding: $margin-base * 0.25 $margin-base * 0.625;
    box-shadow: $dealer-result-eybrow-box-shadow;
    background-color: $color-light-grey;
    font-size: $font-size-smaller;
    line-height: $line-height-smaller;
    color: $color-black;
  }

  &__item-dealer-distance {
    font-size: $font-size-smaller;
    line-height: $line-height-smaller;
    text-align: center;
    color: $color-medium-grey;
  }

  &__item-title {
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
    line-height: $line-height-base;
    letter-spacing: $letter-spacing-small;
    color: $color-dark-blue;

    @include media($from: tbl-p) {
      line-height: $line-height-small;
    }
  }

  &__item-address {
    margin-top: $margin-base;
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $color-dark-blue;
  }

  &__contact-icon {
    display: inline-block;
  }

  &__item-see-details-cta {
    margin-top: $margin-base;
    display: inline-block;
    padding: $padding-base * 0.25 $padding-base * 1.5;
    background-color: $color-dark-blue;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    text-align: center;
    color: $color-white;
    text-decoration: none;

    &:hover {
      box-shadow: $box-shadow-primary-dark-blue-cta;
      background-color: $color-primary;
    }
  }

  &__item-get-directions-cta {
    margin-top: $margin-base;
    display: inline-block;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    text-align: center;
    color: $color-primary-blue;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__item-get-directions-icon {
    display: inline-block;
    position: relative;
    top: 4px;
    margin-left: $margin-base * 0.5;
  }

  &__item-show-more-wrapper {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-out;

    &--visible {
      max-height: 100vh;
      overflow: auto;
    }

    @include media($from: tbl-l) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__show-more-icon {
    display: flex;
    justify-content: center;
    margin: $margin-base * 1.5 auto 0;
    cursor: pointer;
  }

  &__contact-row {
    display: flex;
    margin-top: $margin-base;
  }

  &__contact-link {
    margin-left: $margin-base * 0.5;
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $color-primary-blue;
    word-break: break-word;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__item-contact-awards {
    display: flex;

    @include media($from: tbl-l) {
      display: block;
    }
  }

  &__item-contact-awards-img {
    display: block;
    margin: $margin-base * 1.5 $margin-base $margin-base * 1.5 0;
    width: auto;
    height: auto;
    max-width: 90px;
    max-height: 32px;

    @include media($from: tbl-l) {
      margin: $margin-base / 2 auto $margin-base / 2;
    }
  }

  &__item-marker {
    position: relative;
    display: inline-block;
  }

  &__item-label {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    text-align: center;
    position: absolute;
    color: $color-white;
    z-index: 1;
    top: 4px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__no-dealers-msg-box {
    margin: $margin-base * 2 $margin-base * 0.8 0 0;
    padding: $padding-base * 0.8 $padding-base * 2;
    background-color: $color-backgroud-grey;
  }

  &__no-dealers-title {
    margin-top: $margin-base * 0.75;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    text-align: center;
    color: $color-primary;
  }

  &__no-dealers-description {
    margin-top: $margin-base * 2;
    font-size: $font-size-base;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    text-align: center;
    color: $color-primary;
  }

  &__no-dealers-icon {
    margin: 0 auto;
  }

  @include media($from: tbl-l) {
    &__spinner {
      background: $color-loader-opacity07;
      position: absolute;
      z-index: $z-index-medium;
      width: inherit;
    }
  }

  // UI in Map Mode(Overlay result card)
  @include media($to: tbl-l) {
    &__spinner.spinner__wrapper {
      height: 100vh;
    }

    &--map-mode & {
      &__spinner {
        position: absolute;
        z-index: $z-index-dealer-landing-map-mode-spinner;
        background: rgba($color-white, 0.3);
        height: 650px;
      }

      &__dealers-count {
        display: none;
      }

      &__list {
        @include fade-in-animation();
        position: absolute;
        bottom: $margin-base;
        box-sizing: border-box;
        max-width: 100%;
        display: flex;
        flex-direction: row;
        column-gap: $margin-base * 0.75;
        overflow-x: scroll;
        z-index: $z-index-dealer-landing-map-mode-dealers;
        padding: 0 $padding-base;
        @include hideScrollbar();
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
      }

      &__item {
        display: flex;
        flex-direction: column;
        background-color: $color-white;
        margin: 0;
        padding: $padding-base;
        box-sizing: border-box;
        min-width: calc(100vw - 48px);
        scroll-snap-align: center;

        &::after {
          display: none;
        }

        &-title,
        &-address {
          @include line-clamp($lines: 2);
        }

        &-title {
          font-size: $font-size-small;
          line-height: $line-height-small;
          letter-spacing: $letter-spacing-base;
        }

        &-address {
          font-size: $font-size-smaller;
          line-height: $line-height-smaller;
          letter-spacing: normal;
          margin-top: $margin-base * 0.125;
        }

        &-see-details-cta {
          width: fit-content;
        }

        &-show-more-wrapper {
          display: none;
        }
      }

      &__show-more-icon {
        display: none;
      }

      &__no-dealers-msg-box {
        @include fade-in-animation();
        position: absolute;
        z-index: $z-index-dealer-landing-map-mode-no-results-dialog;
        box-sizing: border-box;
        margin: $margin-base;
        padding: $padding-base;
        width: calc(100% - 32px);
        background: $color-white;
      }

      &__no-dealers-icon {
        display: none;
      }

      &__no-dealers-title,
      &__no-dealers-description {
        margin-top: 0;
        font-size: $font-size-small;
        overflow-wrap: break-word;
      }
    }
  }
}

@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.series-view-360 {
  @include max-width();

  padding: $padding-base * 3 $padding-base $padding-base * 2.5;

  #wivra_hotspot {
    margin: 0 auto;
    width: 100%;
    height: 375px;
    overflow: hidden;
    user-select: none;
    -moz-user-select: none;
    background-color: transparent;
  }

  h2 {
    margin-top: 0;
  }

  @include media($from: tbl-p) {
    padding: $padding-base * 5.25 $padding-base * 2 $padding-base * 4;
  }
}

// External
video::-webkit-media-controls-fullscreen-button {
  display: none;
}

#closeHsBut {
  color: $color-primary-blue;
  cursor: pointer;
  display: block;
  font-family: $font-family-close-btn-view360;
  font-size: 25px;
  position: absolute;
  right: 5px;
  text-align: center;
  top: 0;
  z-index: $z-index-generic-close-button;
  background-color: $color-white;
  background-color: $color-close-btn-view360;
  padding: $padding-base * 0.125 $padding-base * 0.625;
}

.hsParentDisable {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: $z-index-disable-parent-view360;
  height: 100%;
  width: 100%;
}

.hsDivTxt {
  position: fixed;
  font-size: $font-size-smaller;
  font-family: $font-family-series-view360;
  border: 1px solid #ccc;
  color: #555;
  display: block;
  padding: 10px;
  box-sizing: border-box;
  text-decoration: none;
  top: 50%;
  left: 50%;
  background-color: $color-white;
  margin-top: -50px;
  margin-left: -250px;
  width: 500px;
  height: 100px;
}

.hsDivTxt:hover {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  display: block;
}

.hsDivTxt h3 {
  padding: 0;
  margin: 0;
  color: #369;
  font-family: $font-family;
}

.hsDivImg {
  position: fixed;
  font-size: $font-size-smaller;
  font-family: $font-family-series-view360;
  border: 1px solid #ccc;
  color: #555;
  display: block;
  padding: 10px;
  box-sizing: border-box;
  text-decoration: none;
  top: 50%;
  left: 50%;
  background-color: $color-white;
  margin-top: -250px;
  margin-left: -349px;
  width: 698px;
  height: auto;
}

.hsDivImg:hover {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  display: block;
}

.hsDivImg img {
  padding: 25px;
  width: 630px;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
}

.hsDivVid {
  position: fixed;
  font-size: $font-size-smaller;
  font-family: $font-family-series-view360;
  border: 1px solid #ccc;
  color: #555;
  display: block;
  padding: 30px;
  box-sizing: border-box;
  text-decoration: none;
  top: 50%;
  left: 50%;
  background-color: $color-white;
  margin-top: -165px;
  margin-left: -270px; //FIX
  width: 540px;
  height: 330px;
}

.hsDivImgTxt {
  position: fixed;
  font-size: $font-size-smaller;
  font-family: $font-family-series-view360;
  border: 1px solid #ccc;
  color: #555;
  display: block;
  padding: 10px;
  box-sizing: border-box;
  text-decoration: none;
  top: 50%;
  left: 50%;
  background-color: $color-white;
  width: 100%;
  height: auto;
}

.hsDivImgTxt:hover {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  display: block;
}

.hsDivImgTxt img {
  padding: 25px;
  width: 100%;
  overflow: hidden;
}

.hsDivImgTxt h3 {
  padding-left: 25px;
  padding-top: 0px;
  margin-top: -25px;
  width: 100%;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  font-size: $font-size-smaller;
  font-family: $font-family-series-view360;
  color: $color-primary-blue;
}

.hsDivVid:hover {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  display: block;
}

.hsDivPan {
  position: fixed;
  font-size: $font-size-smaller;
  font-family: $font-family-series-view360;
  border: 1px solid #ccc;
  color: #555;
  display: block;
  padding: 30px;
  box-sizing: border-box;
  text-decoration: none;
  top: 50%;
  left: 50%;
  background-color: rgb(255, 255, 255);
  margin-top: -230px;
  margin-left: -270px;
  width: 540px;
  height: 460px;
}

.hsDivPan:hover {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  display: block;
}

.hsDivPan iframe {
  width: 480px;
  height: 400px;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  border-style: none;
  visibility: visible;
}

.hsDivVidTxt {
  position: fixed;
  font-size: $font-size-smaller;
  font-family: $font-family-series-view360;
  border: 1px solid #ccc;
  color: #555;
  display: block;
  padding: 30px;
  box-sizing: border-box;
  text-decoration: none;
  top: 50%;
  left: 50%;
  background-color: rgb(255, 255, 255);
  margin-top: -165px;
  margin-left: -270px;
  height: auto !important; // !important is required to override height set in the third party script
  width: 540px;
}

.hsDivVidTxt:hover {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  display: block;
}

.hsDivVidTxt h3 {
  width: 480px;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  font-size: $font-size-smaller;
  font-family: $font-family-series-view360;
  color: $color-primary-blue;
}

.hsDivYouTube {
  position: fixed;
  font-size: $font-size-smaller;
  font-family: $font-family-series-view360;
  border: 1px solid #ccc;
  color: #555;
  display: block;
  padding: 30px;
  box-sizing: border-box;
  text-decoration: none;
  top: 50%;
  left: 50%;
  background-color: $color-white;
  margin-top: -210px;
  margin-left: -414px;
  width: 828px;
  height: 420px;
}

.hsDivYouTube:hover {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  display: block;
}

.hsDivYouTube iframe {
  width: 768px;
  height: 360px;
  border: 0px;
}

@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.interactive-image {
  &__picture-wrapper {
    position: relative;
    overflow: hidden;
    height: fit-content;
  }

  &__picture {
    @include fixed-picture-aspect-ratio(375, 210);
    @include media($from: tbl-p) {
      @include fixed-picture-aspect-ratio(1104, 768);
      object-fit: unset;
    }
    object-fit: unset;
  }

  @keyframes pulse {
    from {
      transform: translate(-50%, -50%) scale(1);
    }

    to {
      transform: translate(-50%, -50%) scale(3);
    }
  }

  &__hotspot-item {
    position: absolute;
    transform: translate(-50%, -50%);
    cursor: pointer;
    display: grid;
    place-content: center;
    height: 16px;
    width: 16px;
    background: $white;
    box-shadow: 0 0 10px 2px $yellow-bright;
    border-radius: 50%;

    &:hover {
      background-color: $yellow-bright;
    }

    &::after {
      content: '';
      position: absolute;
      background-color: rgba($yellow-bright, 0.2);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 16px;
      width: 16px;
      border-radius: 50%;
      z-index: $z-index-hotspot-background;
      animation: pulse 1.5s infinite ease-in-out;
    }
  }
}

.my-modal-dialog.hotspot-dialog-box {
  width: 100%;
  @include media($from: dsk-l) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

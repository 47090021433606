@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

$card-layout-map: (
  3: $margin-base * 5 + 2.5,
  2: $margin-base * 4.5 + 1,
  1: 0,
);

.inner-cards-modules {
  padding-top: $padding-base * 2;

  @include media($from: tbl-p) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 2 * $padding-base 4.25 * $padding-base;
    grid-row-gap: $margin-base * 5;
    grid-column-gap: $margin-base * 5;
  }

  * {
    box-sizing: border-box;
  }
}

@each $var, $val in $card-layout-map {
  .inner-cards-modules-#{$var}-card {
    grid-template-columns: repeat($var, 1fr);
    grid-column-gap: map-get($card-layout-map, $var);
  }
}

.inner-cards-module {
  padding-bottom: 2.5 * $padding-base;
  flex-grow: 1;
  color: $blue-dark;

  @include media($from: tbl-p) {
    padding: 0;
    column-gap: $margin-base * 5;
    row-gap: $margin-base * 5;
  }

  &__title {
    margin: $margin-base $margin-base $margin-base * 0.5;
    font-size: $font-size-medium;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-small;

    @include media($from: tbl-p) {
      margin: $margin-base $margin-base * 0.5 $margin-base * 0.5;
    }
  }

  &__description {
    margin: $margin-base * 0.5 $margin-base 0;
    font-size: $font-size-small;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-small;
    @include reset-rich-text-styles;
    @include media($from: tbl-p) {
      margin: $margin-base * 0.5;
    }
  }

  &__ctas {
    margin: 1.5 * $margin-base $margin-base;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5 * $margin-base;
    column-gap: 1.5 * $margin-base;

    @include media($from: tbl-p) {
      margin-top: 1.5 * $margin-base;
    }

    &--secondary {
      display: inline;
      .cta-arrow {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: $margin-base * 0.1875;
      }
      &:hover {
        .cta-arrow {
          margin-left: $margin-base / 2;
          transition: all 0.1s ease-in;
        }
      }
    }
  }

  &__picture {
    &--fixed-aspect-ratio {
      @include fixed-picture-aspect-ratio(244, 138);
    }
  }
}

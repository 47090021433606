@import '../../../../styles/variables';
@import '../../../../styles/_mixins.scss';

.latest-news-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 $margin-base * 2;
  position: relative;

  @include media($to: tbl-l) {
    margin: 0 $margin-base * 1.3125 $margin-base * 2.5 $margin-base * 1.375;
  }

  &__tag {
    position: absolute;
    z-index: $z-index-latest-news-tag;
    right: $margin-base;
    top: $margin-base;
    font-size: $font-size-smaller;
    font-weight: $font-weight-normal;
    line-height: $line-height-smaller;
    letter-spacing: $letter-spacing-smaller;
    padding: $padding-base * 0.25 $padding-base * 0.625 $padding-base * 0.1875 $padding-base * 0.6875;
    background-color: $color-light-grey;

    &--text {
      text-decoration: none;
      color: $color-black;
      text-transform: uppercase;
    }
  }

  .play-icon {
    @include media($from: tbl-l) {
      width: 40px;
      height: 40px;
    }
  }

  @include media($from: tbl-l) {
    img {
      object-fit: cover;
    }
  }

  &__media-wrapper {
    @include media($to: tbl-l) {
      margin: 0;
    }

    &--thumbnail {
      @include fixed-picture-aspect-ratio(16, 9);

      .play-icon-defaults {
        height: 40px;
        width: 40px;
      }
    }

    &--video {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: $z-index-video-player-play-icon;
    }
  }

  &__image {
    @include fixed-picture-aspect-ratio(16, 9);
  }

  &__content {
    &--wrapper {
      padding: $padding-base $padding-base / 2 $padding-base * 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      @include media($from: tbl-l) {
        padding: $padding-base $padding-base / 2 $padding-base * 2;
      }
    }

    &--date {
      font-size: $font-size-small;
      letter-spacing: $letter-spacing-base;
      line-height: $line-height-small;
      color: $color-white;
      margin-bottom: $margin-base / 2;
      font-weight: $font-weight-bold;
    }

    &--title {
      font-size: $font-size-small;
      color: $color-white;
      margin-bottom: $margin-base * 0.75;
      margin-top: initial;
      font-weight: $font-weight-bold;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;

      @include media($from: tbl-l) {
        font-size: $font-size-base;
      }
    }

    &--description {
      font-size: $font-size-small;
      letter-spacing: $letter-spacing-base;
      line-height: $line-height-small;
      color: $color-white;
      margin-bottom: $margin-base * 2;
    }
  }

  &__border {
    border-bottom: 2px solid $color-white;
    margin: 0 $margin-base / 2;
  }
}

@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

$box-shadow-arrow: 0px 2px 20px rgba(126, 126, 126, 0.25);
$custom-arrow-dim: 40px;

.back-to-top {
  align-items: center;
  cursor: pointer;
  display: inline-flex;

  @include media('tbl-p') {
    justify-content: flex-end;
    display: flex;
  }

  &:hover {
    .back-to-top {
      &__text {
        color: $color-primary;
      }

      &__button {
        background: $color-primary;
      }
    }
  }

  &__text {
    font-weight: $font-weight-bold;
    margin: 0;
    text-transform: uppercase;
    font-size: $font-size-base;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-smaller;
    color: $blue-dark;
  }

  &__button {
    align-items: center;
    background: $blue-dark;
    border: 0;
    box-shadow: $box-shadow-arrow;
    height: $custom-arrow-dim;
    justify-content: center;
    margin-left: $margin-base;
    outline: 0;
    width: $custom-arrow-dim;
    min-width: $custom-arrow-dim;
    padding: 0;
    display: flex;
    cursor: pointer;
  }
}
@import '../../../../styles/_mixins.scss';
@import '../../../../styles/_variables.scss';

@mixin power-bar {
  background: $yellow-bright;
  content: '';
  display: block;
  height: 6px;
  left: 10px;
}

.social-media-section {
  @include max-width();
  background: $white;
  display: flex;
  justify-content: center;

  &__social-icons {
    justify-content: center;
    display: flex;
    padding: $padding-base $padding-base / 2;
    position: relative;
    &::after {
      $gap-caused-by-the-diagonal: 3px;
      @include power-bar();
      bottom: 0;
      position: absolute;
      right: 0;
      left: 0;
    }
    .scLooseFrameZone {
      overflow-x: hidden;
    }
  }

  &__social-icon-link {
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: $white;
    margin: 0 $margin-base * 1.5 0;
    @include media($to: tbl-p) {
      margin: 0 $margin-base * 1.25 0;
    }
    &:hover {
      .icon-wrapper {
        background-color: $color-primary;
      }
    }
  }
}

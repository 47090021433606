@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.small-hero-banner {
  @include max-width;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;

  &__social-media-bar {
    position: absolute;
    right: 0;
    top: $margin-base * 2.5;
  }

  &__image-wrapper {
    height: 100%;
    position: absolute;
    z-index: $z-index-background;
    width: 100vw;
  }

  &__image {
    display: block;
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }

  &__placeholder {
    margin-top: $margin-base - 1;
  }

  &__content {
    position: relative;
    z-index: $z-index-medium;
    box-sizing: border-box;
    padding: $padding-base $padding-base $padding-base * 2;

    @include media($from: tbl-p) {
      width: 50%;
      padding: $padding-base * 3 $padding-base * 2 $padding-base * 3.5;
      & > * {
        width: 68%;
      }
    }

    &--background {
      background-image: linear-gradient(to bottom, rgba(10, 26, 50, 0.7), rgba(10, 26, 50, 0.5) 73%, rgba(10, 26, 50, 0));
      @include media($from: tbl-p) {
        background-image: linear-gradient(to right, rgba(10, 26, 50, 0.7), rgba(10, 26, 50, 0.5) 75%, rgba(10, 26, 50, 0));
      }
    }
  }

  &__eyebrow {
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: 0.8px;
    color: $white;
    margin: 0;
    margin-bottom: $margin-base / 4;
    text-transform: uppercase;

    @include media($from: tbl-p) {
      font-size: $font-size-base;
      line-height: $line-height-small;
    }
  }

  &__title {
    font-size: $font-size-large;
    line-height: $line-height-medium;
    font-weight: bold;
    letter-spacing: 2px;
    color: $white;
    margin: 0 0 $margin-base;

    @include media($from: tbl-p) {
      font-size: $font-size-extra-large;
      line-height: $line-height-larger;
      letter-spacing: $letter-spacing-large;
      margin: 0 0 $margin-base * 1.25;
    }
  }

  &__description {
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: 0.8px;
    color: $white;
    margin: 0 0 $margin-base;

    @include media($from: tbl-p) {
      font-size: $font-size-base;
      margin: 0 0 $margin-base * 1.5;
    }
  }

  &__button {
    &-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: $margin-base;
      column-gap: $margin-base;
    }
  }
}

$mobile-image-height: 581px;
$points-col-gap: $margin-base;

.hero-banner-cl {
  @include media($from: dsk-l) {
    margin-left: calc(calc(100vw - #{$width-max-page}) / 2);
  }

  &__video-player {
    position: absolute;
    z-index: $z-index-boxed-hero-banner-video;
  }

  &__wrapper {
    position: relative;
    overflow-y: hidden;

    @include media($from: tbl-p) {
      padding-top: 0;
    }

    @include media($to: tbl-p) {
      min-height: $mobile-image-height;
    }
  }

  &__social-media-bar {
    position: absolute;
    right: 0;
  }

  &__video-wrapper > video,
  &__video-wrapper > div,
  &__media,
  &__media-wrapper--EE img {
    position: absolute;
    z-index: $z-index-background-media;
    height: 100%;
    width: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    display: block;
  }

  &__media {
    &--disable-cropping {
      object-fit: contain;
    }

    &-EE {
      &--desktop,
      &--mobile {
        display: none;
      }

      @include media($from: tbl-p) {
        &--desktop {
          display: block;
        }
      }

      @include media($to: tbl-p) {
        &--mobile {
          display: block;
        }
      }
    }
  }

  &__content {
    box-sizing: border-box;
    min-height: clamp(
      768px,
      calc(100vh - var(--navigation--Height--actual, 85px) - #{$height-breadcrumb}),
      calc(100vh - var(--navigation--Height--actual, 85px) - #{$height-breadcrumb})
    );
    width: 34.29%;
    max-width: 576px;
    background-color: $color-pastel-opacity01;
    padding: $padding-base * 5 $padding-base ($padding-base * 2.75) (2 * $padding-base);
    display: flex;
    flex-direction: column;

    @include media($to: tbl-p) {
      width: 100%;
      max-width: 100%;
      height: fit-content;
      min-height: initial;
      padding: $padding-base * 3.5 $padding-base $padding-base;
    }

    @include max-width-herosection;

    @include media($from: tbl-p) {
      margin-top: $margin-base * 3.5;

      &::before {
        margin-top: inherit;
      }
    }

    &__no-breadcrumb {
      @include media($from: tbl-p) {
        margin-top: 0;
      }
    }

    @include media($from: dsk-l) {
      margin-left: calc(calc(100vw - #{$width-max-page}) / 2);
      padding-left: $padding-base * 2;
    }

    &__eyebrow {
      width: 63.17%;
      font-size: $font-size-base;
      letter-spacing: $letter-spacing-base;
      color: $blue-dark;
      line-height: $line-height-small;
      margin-bottom: $margin-base;
      text-transform: uppercase;

      @include media($to: tbl-p) {
        width: 100%;
        font-size: $font-size-small;
        margin-bottom: 0;
      }
    }

    &__title {
      font-weight: bold;
      letter-spacing: $letter-spacing-small;
      line-height: $line-height-base;
      margin-bottom: $margin-base;
      color: $blue-dark;
      margin-top: 0;

      @include fluid-font(map-get($breakpoints, 'tbl-p'), map-get($breakpoints, 'dsk-l'), $font-size-medium, $font-size-larger);

      @include media($from: tbl-p) {
        letter-spacing: $letter-spacing-large;
        line-height: $line-height-large;
        margin-bottom: 1.5 * $margin-base;
      }
    }
  }

  &__key-point-dots__wrapper {
    position: relative;
    margin-top: auto;
    width: 33.33%;
    min-width: 560px;
    padding-left: $margin-base * 3.5 + 1;

    @include media($to: tbl-p) {
      display: none;
    }
  }

  &__dots {
    position: absolute;
    left: 0;
  }

  &__key-point__wrapper,
  &__key-point__wrapper-ch {
    background-color: $white;
    padding: $padding-base;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    row-gap: $margin-base * 1.5;
    column-gap: $points-col-gap;
    margin-top: ($margin-base * 3.5 - 1);
  }

  &__key-point__wrapper-ch {
    div {
      box-sizing: border-box;
      padding-left: $padding-base;
      border-left: 1px solid $blue-pastel;
      width: calc(50% - #{$points-col-gap} / 2);
      font-size: $font-size-small;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
      color: $grey-dark;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;

      &:only-child {
        flex-grow: 1;
      }

      b,
      strong {
        color: $color-primary-blue;
        display: block;
      }

      > * {
        margin: 0;
      }
    }
  }

  &__key-point {
    box-sizing: border-box;
    padding-left: $padding-base;
    border-left: 1px solid $blue-pastel;
    width: calc(50% - #{$points-col-gap} / 2);

    &:only-child {
      flex-grow: 1;
    }

    &__title,
    &__description {
      font-size: $font-size-small;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
      margin: 0;
    }

    &__title {
      color: $color-primary-blue;
    }

    &__description {
      color: $grey-dark;
    }
  }
}

.hero-banner-cl__wrapper.scEnabledChrome .editorial-image {
  position: initial;
}

@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

$editor-nav-height: 225px;

body.body--with-pop-up {
  // prevent background scrolling
  overflow: hidden;
}

// editor pop up UI
.generic-popup {
  width: fit-content;

  &__button {
    cursor: pointer;
    .p-blue .icon-wrapper {
      background-color: $white;
    }
    .p-yellow .icon-wrapper {
      background-color: $black;
    }
    .p-ghost .icon-wrapper {
      background-color: $blue-dark;
    }
    &:hover {
      .p-ghost .icon-wrapper {
        background-color: $color-primary;
      }
    }
    .s-white .icon-wrapper {
      background-color: $white;
    }
  }
}

.editor_popup {
  top: 0;
  left: 0;
  transform: translate(-100%, -100%);
  height: calc(100% - #{$editor-nav-height});
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  &--visible {
    top: $editor-nav-height;
    transform: translate(0, 0);
    visibility: visible;
    opacity: 1;
  }

  &__close {
    &-icon {
      background-color: $white;
    }
  }
}

// Modal dialog & editor pop up UI
.generic-modal-dialog {
  padding: 0;

  @include media($to: tbl-p) {
    background-color: $white;
  }

  &.my-modal-dialog__overlay {
    padding: 0;

    @include media($to: tbl-p) {
      background-color: $white;
    }

    &.ReactModal__Overlay {
      opacity: 0;
      transition: all 0.5s ease-in-out;
    }

    &.ReactModal__Overlay--after-open {
      opacity: 1;
    }
  }

  &.my-modal-dialog {
    padding: 0;
    text-align: initial;
    overflow: hidden;

    &__overlay--ee-active {
      height: calc(100% - var(--ee-toolbar-offset, 0px));
      top: var(--ee-toolbar-offset, 0px);
    }

    @include media($to: tbl-p) {
      min-height: 100%;
      display: flex;
      flex-direction: column;

      &:empty {
        margin: 0;
      }

      & > *:only-child {
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    &:empty {
      height: calc(100% - 144px); // 80px = top, 64px = bottom below white box
    }
  }

  .my-modal-dialog__close-button {
    &--ee-active {
      position: absolute;
      top: 0;
      left: auto;
      bottom: auto;
      right: 0;
    }
  }

  // Components inside editor Pop Up UI refactored

  .hero-carousel {
    margin-top: 0px;

    .content-overlay {
      height: fit-content;
    }
  }

  .hero-banner-cl {
    &__wrapper {
      z-index: $z-index-hero-banner-boxed-in-pop-up;
    }

    &__content {
      @include media($from: dsk-max) {
        margin-left: 0;
        margin-top: 0;
      }

      &::before {
        display: none;
      }
    }
  }

  .text-module {
    @include media($from: dsk-max) {
      min-width: initial;

      &::before {
        display: none;
      }
    }
  }

  // sitecore forms changes
  .sitecore-jss-form {
    max-width: 100%;
  }

  .preferred-dealer__search-wrapper {
    max-width: 100%;
    @include media($to: dsk-m) {
      flex-direction: column;
      gap: $margin-base * 1.5;
    }
  }
}

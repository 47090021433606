@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

$link-color: $blue-dark;
$link-color-hover: $color-primary;
$accordion-icon-width: 20px;

.footer-accordion {
  margin: $margin-base 0;

  @include media($from: tbl-p) {
    margin-top: 0;
  }

  &__container .footer-accordion {
    @include media($from: tbl-p) {
      margin-bottom: $margin-base * 3;
    }
  }

  &:not(:first-child) {
    @include media($from: tbl-p) {
      margin-top: $margin-base * 2;
    }
  }

  a {
    color: $link-color;
    text-decoration: none;
  }

  &__list {
    margin: 0 0 $margin-base * 1.5 $margin-base * 2;
    overflow: hidden;
    padding: 0;

    @include media($to: tbl-p) {
      overflow: auto;
      max-height: 1000px;
      transition: max-height 2s ease-out;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &--collapsed {
      @include media($to: tbl-p) {
        max-height: 0;
        transition: max-height 1s ease-out;
      }

      @include media($from: tbl-p) {
        display: initial;
        margin: 0;
      }
    }
  }

  &__item {
    list-style-type: none;
    margin: $margin-base * 0.75 0;

    &:last-child {
      margin-bottom: 0;
    }

    & a {
      font-size: $font-size-smaller;
      line-height: $line-height-smaller;
      &:hover {
        color: $link-color-hover;
        text-decoration: underline;
      }
    }
  }

  &__button {
    align-items: center;
    background: transparent;
    border: none;
    display: flex;
    font-family: inherit;
    padding: $padding-base * 0.25 $padding-base $padding-base * 0.25 0;
    text-align: left;

    @include media($from: tbl-p) {
      margin: 0;
      padding: 0;
      width: auto;
    }
  }

  &__icon {
    margin-right: $margin-base;

    @include media($from: tbl-p) {
      display: none;
    }
  }

  &__header {
    color: $link-color;
    font-weight: $font-weight-bold;
    margin: 0;
    font-size: $font-size-smaller;
    & a {
      cursor: auto;
    }
    &--link {
      & a {
        &:hover {
          cursor: pointer;
          color: $link-color-hover;
          text-decoration: underline;
        }
      }
    }
  }
}

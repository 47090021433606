@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.product-download-brochure-listing-with-filter {
  &__reset {
    display: flex;
    column-gap: $margin-base * 0.5;
    cursor: pointer;
    font-size: $font-size-base;
    font-weight: bold;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $color-primary-blue;
    align-items: center;
    text-transform: uppercase;
    @include media($to: dsk) {
      margin-top: $margin-base * 0.625;
      margin-left: auto;
    }

    &:hover {
      text-decoration: underline;
      text-underline-offset: 6px;
    }
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    margin-top: $margin-base/2;
  }

  &__title {
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
    line-height: $line-height-base;
    letter-spacing: $letter-spacing-small;
    color: $blue-dark;
    margin: 0;
  }

  &--dsk {
    @include max-width-fullscreen;
    background-color: $color-light-grey-opacity03;
    padding: $padding-base * 2 $padding-base * 5 $padding-base * 1.5 $padding-base * 2;
    display: flex;
    flex-direction: column;
    row-gap: $margin-base * 1.5;
    @include media($from: dsk-l) {
      background-color: initial;
      &::before {
        background-color: $color-light-grey-opacity03;
      }
    }

    .download-brochure-listing-filter {
      display: flex;
      align-items: center;
      flex-wrap: wrap-reverse;
      justify-content: space-between;

      &__dropdown-wrapper {
        display: flex;
      }
    }
  }
}

@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.editorial-image {
  height: 100%;
  width: 100%;
  position: relative;

  &__edit-cta {
    height: 40px;
    width: 40px;
    border: none;
    outline: none;
    cursor: pointer;
    position: absolute;
    top: 15%;
    right: 10%;
    z-index: $z-index-edit-image-cta;
    background-color: $color-dark-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 13px 2px #00000014;
  }

  &__modal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 55vh;
    width: 80vw;
  }

  &__modal-close {
    position: absolute;
    top: $margin-base;
    right: $margin-base;
    border: none;
    outline: none;
    cursor: pointer;
    background: none;
  }

  &__modal-desktopImage,
  &__modal-mobileImage {
    width: 50%;
    max-width: 50%;
    max-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .sc-image-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img,
    .sc-image-wrapper {
      object-fit: contain;
      max-width: 80%;
      max-height: 80%;
    }
  }

  &__modal-title {
    color: $color-dark-grey;
    margin-bottom: $margin-base;
  }
}

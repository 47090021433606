@import '../../../../styles/_mixins.scss';
@import '../../../../styles/_variables.scss';

.preferred-dealer {
  &__search-wrapper {
    background-color: $light-grey-02;
    max-width: 1152px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: $margin-base 0;
    padding: $padding-base * 1.5 $padding-base;
    width: 100%;
    gap: $margin-base * 1.5;

    @include media($from: dsk) {
      flex-direction: row;
      padding: $padding-base * 2;
      margin-bottom: $margin-base * 2;
      gap: 0;
      width: 1152px;
      justify-content: space-between;
    }
  }

  &__out-of-bound {
    display: flex;
    justify-content: center;
  }

  &__user-position {
    font-size: $font-size-base;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $color-medium-grey;
    display: flex;
    gap: $margin-base * 0.5;
    align-items: center;
    cursor: pointer;
    &--active {
      color: $color-primary-blue;
    }
  }

  &__title {
    color: $color-dark-blue;
    font-weight: $font-weight-bold;
    line-height: $line-height-small;
  }
}

// requirment: the width layout must not affect dealer-locator component
.width-1,
.width-1-2,
.width-1-3,
.width-1-4,
.width-2-3 {
  &:has(> .preferred-dealer) {
    width: 100% !important;
  }
}

@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.special-offers-listing {
  &__card {
    @include media($from: tbl-p) {
      padding: 0 $padding-base * 2;
    }
  }

  &__card-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: $margin-base * 2.5;
    margin: $margin-base * 2 $margin-base * 2.375 $margin-base * 2.5;

    @include media($from: tbl-p) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: $margin-base;
      row-gap: $margin-base * 4;
      margin: $margin-base * 2 $margin-base * 2 $margin-base * 4;
    }
  }

  &__card-container {
    @include media($from: dsk-l) {
      max-width: $width-max-page;
      margin: 0 auto;
    }
  }

  .pagination {
    padding-top: 0;
  }

  .pagination__container {
    padding-left: 0;
  }
}

@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.news-detail {
  max-width: 800px;
  margin: 0 auto;
  padding: $padding-base * 5 0;

  @include media($to: tbl-l) {
    padding: $padding-base * 3 $padding-base $padding-base * 2.5;
  }

  &__eyebrow {
    font-size: $font-size-small;
    line-height: $line-height-small;
    color: $color-medium-grey;
    letter-spacing: $letter-spacing-base;
    margin-bottom: $margin-base * 0.5;
    text-transform: uppercase;

    @include media($from: tbl-p) {
      font-size: $font-size-base;
    }
  }

  &__title {
    font-size: $font-size-large;
    line-height: $line-height-medium;
    color: $color-dark-blue;
    letter-spacing: $letter-spacing-medium;
    margin: 0 0 $margin-base 0;
    font-weight: $font-weight-bold;

    @include media($from: tbl-p) {
      font-size: $font-size-larger;
      line-height: $line-height-large;
      letter-spacing: $letter-spacing-large;
      margin: 0 0 $margin-base * 1.5 0;
    }
  }

  &__date,
  &__description {
    font-size: $font-size-small;
    line-height: $line-height-small;
    color: $color-dark-blue;
    letter-spacing: $letter-spacing-base;

    @include media($from: tbl-p) {
      font-size: $font-size-base;
    }
  }

  &__description {
    @include reset-rich-text-styles();
    margin-top: $margin-base * 2;
  }

  &__social-media-bar {
    margin: $margin-base * 3 0 0 0;

    .social-media__social {
      padding: 0;

      &-icon-link:last-of-type {
        margin-right: 0;
      }
    }
  }

  &__categories {
    &-wrapper {
      margin-top: $margin-base * 2.5;
    }

    &-title {
      font-size: $font-size-base;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
      color: $color-dark-blue;
      margin-right: $margin-base * 0.25;
      &::after {
        content: ':';
      }

      @include media($to: tbl-p) {
        font-size: $font-size-small;
        margin-right: $margin-base / 2;
      }
    }

    &-tags-wrapper {
      display: inline;
      font-size: $font-size-small;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
      color: $color-primary-blue;
      font-weight: $font-weight-bold;
    }

    &-tag {
      &--name {
        text-decoration: underline;
      }

      &--link {
        color: inherit;
        text-decoration: none;
        &:not(:last-of-type) {
          &::after {
            content: ',';
            margin-right: $margin-base * 0.3125;
          }
        }
      }
    }
  }

  &__media {
    &-wrapper {
      margin-top: $margin-base * 1.5;
      position: relative;
      width: 100%;
    }

    &--video {
      height: 192px;
      @include media($from: tbl-p) {
        height: 450px;
      }

      .play-icon {
        &:hover {
          background-color: $color-primary-blue;
          box-shadow: 0 10px 20px 0 $color-arrow-hover-box-shadow;
        }
      }
    }

    &--image {
      @include fixed-picture-aspect-ratio(343, 192);

      @include media($from: tbl-p) {
        @include fixed-picture-aspect-ratio(800, 450);
      }
    }

    &--no-media {
      border-bottom: 1px solid $color-light-grey;
      width: 100%;
    }
  }

  &__cta-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: $margin-base * 1.5;
    row-gap: 8px;
    @include media($from: tbl-p) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__cta-primary {
    &.button {
      line-height: $line-height-small;
    }
  }

  &__cta-secondary {
    &.button {
      @include media($to: tbl-p) {
        padding: $padding-base * 0.8125 $padding-base $padding-base * 0.8125 $padding-base * 1.5;
      }
    }
  }
}

@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

body.body--with-filter-model {
  // prevent scrolling
  overflow: hidden;
}

.product-download-brochure-listing-with-filter--mbl {
  .download-brochure-filter-modal-switch {
    background-color: $color-light-grey-opacity03;
    padding: $padding-base;
    display: flex;
    align-items: center;
    column-gap: $margin-base * 0.5;
    font-weight: $font-weight-normal;
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $color-dark-blue;
    justify-content: flex-end;
  }

  .download-brochure-filter-modal {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: $white;
    left: 0;
    top: 0;
    z-index: $z-index-download-brochure-listing-filter-modal;
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      justify-content: space-between;
      padding: $padding-base;
      background-color: $color-dark-blue;
      color: $white;
    }

    &__title {
      font-size: $font-size-small;
      font-weight: bold;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
    }

    &__body {
      flex-grow: 1;
      overflow: auto;
    }

    &__ctas {
      display: flex;
      column-gap: $margin-base;
      row-gap: $margin-base;
      flex-wrap: wrap;
      padding: $padding-base;
      box-shadow: 0 0 20px 0 rgba($black, 0.1);

      .button {
        flex-grow: 1;
        justify-content: center;
      }
    }
  }

  .download-brochure-filter__search {
    margin: $margin-base * 1.5 $margin-base;
  }
}

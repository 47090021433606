@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

$text-max-height: 168px;

.compatible-product-card {
  $root: &;
  width: 100%;
  height: 100%;
  margin-bottom: $margin-base * 1.5;
  position: relative;
  align-items: center;
  text-decoration: none;
  background-color: $white;

  &:hover {
    @include media($from: tbl-p) {
      #{$root}__image-yellow-dots {
        transform: translate(-32px, -32px);
        opacity: 1;
      }

      #{$root}__body {
        position: absolute;
        z-index: $z-index-compatible-products-hover;
        box-shadow: $card-overlay-box-shadow;
        max-height: unset;
        min-height: stretch;
        height: auto;
        left: -$margin-base * 2;
        right: -$margin-base * 2;
        padding: 0 $padding-base * 2.5 $padding-base * 2;
        background-color: $white;

        &:before {
          content: unset;
        }
      }
    }
  }

  &__image-wrapper {
    overflow: hidden;
    justify-content: center;
    align-items: center;
    display: flex;

    @include media($from: tbl-p) {
      width: 100%;
    }
  }

  &__image {
    background-color: $white;
    @include fixed-picture-aspect-ratio(119, 67);

    @include media($from: tbl-p) {
      @include fixed-picture-aspect-ratio(328, 185);
      width: 100%;
    }

    &-yellow-dots {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transition: 0.3s;
      opacity: 0;
      height: 235px;
    }
  }

  &__body {
    padding: $padding-base;

    @include media($from: tbl-p) {
      padding: 0 $padding-base / 2;
      max-height: $text-max-height;
      overflow: hidden;
      position: relative;
      background-color: $white;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: $text-max-height;
        background: $compatible-products-card-gradient;
      }
    }
  }

  &__title {
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-small;
    color: $blue-dark;
    padding: $padding-base 0 0;
    margin: 0;

    @include media($to: tbl-p) {
      padding: 0;
    }
  }

  &__description {
    word-break: break-word;
    padding: $padding-base / 2 0 0;
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $blue-dark;
  }

  &--none {
    pointer-events: none;
  }
}

@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.dealer-locator-landing {
  &__map {
    width: 100%;
    height: 650px;
    display: block;
    position: relative;

    @include media($from: tbl-l) {
      height: 750px;
    }
  }

  &__map-container {
    width: 100%;
    height: 100%;
  }

  &__map-tooltip {
    box-shadow: 0 0 30px -10px $color-pastel;
    position: absolute;
    background-color: $color-white;
    padding: $padding-base;
    transform: translate(-50%, 100%);
    font-size: $font-size-small;
    line-height: $line-height-small;
    font-weight: $font-weight-bold;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      display: block;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%) rotate(-45deg);
      border-right: 12px solid $color-white;
      border-left: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-top: 12px solid $color-white;
    }
  }

  &__map-dealer-pin-label {
    position: relative;
    top: -5px;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    font-family: $font-family;
    z-index: 1;
  }

  &__map-icon {
    position: absolute;
    height: 40px;
    width: 40px;
    background-color: $color-dark-blue;
    display: grid;
    place-content: center;
    cursor: pointer;

    &:hover {
      box-shadow: 0 10px 20px 0 rgba(0, 62, 133, 0.2);
      background-color: $color-primary-blue;
    }

    &--get-location {
      top: $margin-base * 2.5;
      right: $margin-base * 5;
    }

    &--zoom-in {
      right: $margin-base * 5;
      bottom: $margin-base * 6;
    }

    &--zoom-out {
      right: $margin-base * 5;
      bottom: $margin-base * 2.5;
    }

    &--get-location,
    &--zoom-in,
    &--zoom-out {
      @include media($to: tbl-l) {
        display: none;
      }
    }
  }

  // UI in List Mode(Overlay result card)
  @include media($to: tbl-l) {
    &__map--list-mode {
      position: absolute;
      transform: scale(0);
    }
  }
}

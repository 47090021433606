@import '../../../styles/_mixins.scss';

.disabled-component {
  @include max-width;

  padding: ($padding-base * 2) 0;

  &__title {
    font-size: $font-size-base;
  }
}

@import '../../../../../../styles/_variables.scss';

.desktop-login-tab {
  margin-right: $margin-base * 1.5;

  .navigation__menu-item__tray {
    box-sizing: border-box;
    width: 336px;
    right: 0;
    left: initial;
    padding: ($padding-base * 2) ($padding-base * 2) ($padding-base * 2) ($padding-base * 1.25);
    background-color: $grey-lighter;

    a,
    a:hover,
    a:visited {
      text-decoration: none;
    }
  }

  .user {
    padding: 0 0 ($padding-base * 0.5) 0;
    border-bottom: 1px solid $color-light-grey;

    &-details {
      list-style: none;
      margin-bottom: $margin-base;

      &__name {
        font-size: $font-size-base;
        line-height: $line-height-small;
        font-weight: $font-weight-bold;
        letter-spacing: $letter-spacing-base;
      }

      &__company {
        font-size: $font-size-small;
        line-height: $line-height-small;
        letter-spacing: $letter-spacing-base;
      }
    }

    &-account {
      &__link {
        font-size: $font-size-base;
        line-height: $line-height-smaller + 2;
        letter-spacing: normal;
        color: $color-black;
        font-weight: $font-weight-bold;
        margin-bottom: $margin-base;

        a {
          text-decoration: none;
        }
      }
    }
  }

  .quick-access--dsk {
    margin: ($margin-base * 1.5) 0 0;
  }

  .other-links--dsk {
    margin: ($margin-base * 2) 0 0;

    .quick-access-other__link__label {
      font-weight: $font-weight-normal;
    }
  }
}
@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

$main-breakpoint: map-get($breakpoints, tbl-p);

@mixin desktop {
  @media (min-width: $main-breakpoint) {
    @content;
  }
}

.media-carousel {
  position: relative;
  overflow: hidden;

  @include media($to: tbl-p) {
    width: 100%;
  }

  &__wrapper {
    position: relative;
    height: 100%;
  }

  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide > div {
    height: 100%;
  }

  &__controls {
    margin: 0px;
    position: absolute;
    align-items: center;
    bottom: 18px;
    display: flex;
    left: 0;
    right: 0;
    justify-content: center;
    pointer-events: none;

    @include desktop {
      bottom: 32px;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 80px;
      background-image: linear-gradient(to top, rgba(10, 26, 50, 0.7), rgba(10, 26, 50, 0.5) 67%, rgba(10, 26, 50, 0));
      pointer-events: none;
      bottom: -32px;

      @include media($to: tbl-p) {
        height: 40px;
        bottom: -18px;
      }
    }

    .carousel-dots-wrapper {
      pointer-events: all;
      z-index: $z-index-feature-carousel-items;
    }
  }

  &__animate-icons {
    opacity: 1;
    transition: opacity 1s ease-in;

    &--animate-fade {
      opacity: 0;
    }

    @include media($to: tbl-p) {
      opacity: 0;
      pointer-events: none;

      &--animate-fade {
        @include nav-arrows-fade();
      }
    }

    &--hide {
      opacity: 0;
    }

    &--show {
      opacity: 1;
      pointer-events: inherit;
    }
  }
}

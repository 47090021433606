@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.model-listing-card {
  position: relative;
  width: 100%;
  height: 100%;
  border-bottom: 2px solid $grey-light;
  margin-bottom: $margin-base * 1.5;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: $margin-base * 0.75;

  @include media($from: tbl-p) {
    column-gap: unset;
    flex-direction: column;
    align-items: flex-start;
  }

  &__price-tag {
    background-color: $color-pastel;
    color: $color-black;
    font-size: $font-size-smaller;
    font-weight: $font-weight-bold;
    z-index: $z-index-product-card-price-tag;
    text-align: center;
    max-width: 100%;
    width: fit-content;
    padding:$padding-base * 0.25 $padding-base/2;
    margin-bottom: $margin-base * 0.75;

    @include media($from: tbl-p) {
      position: absolute;
      right: 0;
      margin: 0;
      top: 0;
      white-space: normal;
    }
  }

  &__image-wrapper {
    min-width: 119px;
    min-height: 67px;
    width: 119px;
    height: 67px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    display: flex;

    @include media($from: tbl-p) {
      width: 100%;
      height: 185px;
      margin-bottom: $margin-base / 1.5;
    }
  }

  &__image {
    @include fixed-picture-aspect-ratio(119, 67);

    @include media($from: tbl-p) {
      @include fixed-picture-aspect-ratio(328, 185);
      width: 100%;
    }
  }

  &__body {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    @include media($from: tbl-p) {
      margin: 0 $margin-base * 0.5;
    }
  }

  &__title {
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
    line-height: 1.2;
    letter-spacing: 0.5px;
    color: $color-primary;
    margin: 0 0 $margin-base / 2;
  }

  &__spec1,
  &__spec2 {
    flex-direction: column;
    align-items: flex-start;
  }

  &__spec1 {
    display: flex;

    @include media($from: tbl-p) {
      margin-bottom: $margin-base / 2;
    }
  }

  &__spec2 {
    display: none;

    @include media($from: tbl-p) {
      display: flex;
    }
  }

  &__spec-text,
  &__spec-value {
    font-size: $font-size-smaller;
    line-height: 1.67;
    letter-spacing: normal;
    color: $grey-dark;
    text-transform: uppercase;

    &--no-transform {
      text-transform: none;
    }

    @include media($from: tbl-p) {
      font-size: $font-size-small;
      line-height: $line-height-small;
    }
  }

  &__CTA-wrapper {
    display: flex;
    flex-direction: column;
    margin: auto 0 $margin-base * 1.5 0;
    gap: $padding-base;

    @include media($to: tbl-p) {
      margin-top: $margin-base * 1.5;
    }

    & .model-listing-card__secondary-cta {
      padding: 0 $padding-base;
    }
  }
}

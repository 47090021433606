@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.media-gallery {
  background: $color-light-grey;

  &__wrapper {
    @include max-width;
  }

  &__content {
    padding: $padding-base * 5 $padding-base * 2 0;
    @include media($to: tbl-p) {
      padding: $padding-base * 3 $padding-base $padding-base * 1.5;
    }
  }

  .overlay-carousel-popup {
    &__heading-wrapper {
      padding: $padding-base * 4 0 0;
    }

    &__focus-image {
      &__text-description {
        @include media($from: tbl-l) {
          width: 62.3%;
        }
      }
    }
  }
}

@import '../../../../../../styles/_variables.scss';
@import '../../../../../../styles/_mixins.scss';

.dealer-accordion-filter {
  background-color: $color-light-grey-opacity03;

  .dealer-accordion-font-styles {
    font-size: $font-size-small;
    line-height: $line-height-small;
    color: $color-dark-blue;
    letter-spacing: $letter-spacing-base;
  }

  &__switch {
    @extend .dealer-accordion-font-styles;
    display: flex;
    justify-content: space-between;
    padding: $padding-base 0;
    margin: 0 $margin-base;
    border-bottom: 1px solid $color-light-grey;
    cursor: pointer;

    &--open {
      border: none;
    }
  }

  @keyframes grow {
    0% {
      max-height: 0vh;
    }

    100% {
      max-height: 100vh;
    }
  }

  @keyframes shrink {
    0% {
      max-height: 100vh;
    }

    100% {
      max-height: 0px;
    }
  }

  &__panel {
    background-color: $white;
    padding: 0 $padding-base $padding-base;
    overflow: hidden;

    &--open {
      animation: grow 0.3s;
    }

    &--closed {
      animation: shrink 0.3s;
    }

    &-item {
      @extend .dealer-accordion-font-styles;
      letter-spacing: $letter-spacing-base;
      width: 100%;
      display: flex;
      column-gap: $margin-base / 2;
      padding: $padding-base 0;
      border-bottom: 1px solid $color-light-grey;
      cursor: pointer;
      align-items: center;

      .icon-wrapper {
        flex-shrink: 0;
      }
    }

    &-radio {
      display: none;
    }
  }
}

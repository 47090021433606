@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.latest-news {
  @include max-width-fullscreen();
  padding: $padding-base * 3 $padding-base 0;
  background-color: $color-dark-blue;

  @include media($from: tbl-l) {
    padding: $padding-base * 5 $padding-base * 2 $padding-base * 4;
  }

  @include media($from: dsk-l) {
    min-width: initial;
    padding-right: 0;
    padding-left: 0;
  }

  &__heading {
    &-wrapper {
      color: $color-white;

      @include media($from: tbl-l) {
        max-width: 800px;
      }

      @include media($from: dsk-l) {
        padding-left: $padding-base * 2;
      }
    }

    &-eyebrow {
      font-size: $font-size-small;
      margin-bottom: $margin-base / 2;
      font-weight: $font-weight-normal;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
      text-transform: uppercase;

      @include media($from: tbl-l) {
        font-size: $font-size-base;
      }
    }

    &-text {
      display: flex;
      align-items: baseline;
      flex-direction: column;

      @include media($from: tbl-l) {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    &-title {
      font-size: $font-size-large;
      font-weight: $font-weight-bold;
      line-height: $line-height-medium;
      letter-spacing: $letter-spacing-medium;
      margin-bottom: $margin-base;

      @include media($from: tbl-l) {
        margin-right: $margin-base * 1.46875;
        font-size: $font-size-larger;
        line-height: $line-height-large;
        letter-spacing: $letter-spacing-large;
        margin-bottom: $margin-base * 2;
      }
    }

    &-link {
      display: flex;
      color: $color-white;
      text-decoration: none;
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
      align-items: center;
      margin-bottom: $margin-base * 1.5;
      text-transform: uppercase;

      &:hover {
        text-decoration: underline;
        text-underline-position: under;

        .icon-wrapper {
          margin-left: $margin-base / 2;
        }
      }

      @include media($from: tbl-l) {
        margin-bottom: $margin-base * 2;
      }

      .icon-wrapper {
        @include media($to: tbl-l) {
          margin-left: $margin-base / 2;
        }
      }
    }
  }

  &__cards-wrapper {
    display: flex;
    flex-direction: column;

    @include media($from: tbl-l) {
      display: grid;
      flex-direction: initial;
      grid-column-gap: $margin-base;
      margin-top: $margin-base * 2;
      grid-template-columns: repeat(3, 1fr);
    }

    @include media($from: dsk-l) {
      padding-left: $padding-base * 2;
      padding-right: $padding-base * 2;
    }
  }
}

@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.category-dashboard {
  @include max-width;

  &--regular {
    &__title {
      color: $color-dark-blue;
      font-size: $font-size-larger;
      line-height: $line-height-large;
      letter-spacing: $letter-spacing-large;
      padding: $padding-base * 5 $padding-base * 2 $padding-base * 2;
      margin: 0;
      @include media($to: tbl-p) {
        font-size: $font-size-large;
        line-height: $line-height-medium;
        letter-spacing: $letter-spacing-medium;
        padding: $padding-base * 3 $padding-base $padding-base * 1.5;
      }
    }

    &__wrapper {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));

      @include media($from: tbl-p) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: $margin-base * 5;
        grid-row-gap: $margin-base * 4;
        padding: $padding-base * 2 $padding-base * 4;
      }

      @include media($from: dsk) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }

      // margin-top: 3 * $margin-base;
      // grid-row-gap: $margin-base * 4;
      // padding: 0 $padding-base * 4;

      // @include media($to: tbl-p) {
      //   width: 100%;
      //   height: auto;
      //   margin-top: $margin-base * 1.8;
      //   grid-row-gap: $margin-base * 3;
      //   padding: 0;
      // }

      // @include media($from: tbl-p, $to: dsk) {
      //   grid-template-columns: repeat(3, 1fr);
      //   grid-template-rows: repeat(3, 1fr);
      // }

      // @include media($from: dsk) {
      //   grid-template-columns: repeat(4, 1fr);
      //   grid-template-rows: repeat(1, 1fr);
      // }
    }
  }
}

@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.special-offers-listing-sort-cta {
  @include reset-button-styles();
  color: $color-dark-blue;
  font-size: $font-size-small;
  line-height: $line-height-small;
  letter-spacing: $letter-spacing-base;
  display: flex;
  align-items: center;
  column-gap: $margin-base;
}

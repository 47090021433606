@import '../../../../../styles/_variables.scss';
@import '../../../../../styles/_mixins.scss';

$interval: 8s;
$slide-button-width-height: 40px;
$slide-arrow-left-right-position: 80px;
$slide-print-top-right-position: 16px;
$slide-print-top-position-dsk: 24px;
$slide-print-right-position-dsk: 32px;

.split-slide {
  position: relative;

  &__controls {
    display: flex;
    justify-content: center;

    &--icons {
      background-color: $white;
    }
  }

  button {
    appearance: none;
    border: none;
    position: absolute;
    width: $slide-button-width-height;
    height: $slide-button-width-height;
    top: calc(50% - 40px / 2);
    background-color: $color-primary-blue;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: $font-size-medium + 4;

    &:hover {
      background-color: $color-primary-blue;
    }

    @include media($from: dsk) {
      font-size: $font-size-large + 2;
    }

    &.controls__arrow {
      display: none;

      @include media($from: dsk) {
        display: flex;
      }

      .icon-wrapper {
        display: block;
      }

      &--previous {
        left: $slide-arrow-left-right-position;
      }

      &--next {
        right: $slide-arrow-left-right-position;
      }

      &--download {
        display: flex;
        top: $slide-print-top-right-position;
        right: $slide-print-top-right-position;

        @include media($from: dsk) {
          top: $slide-print-top-position-dsk;
          right: $slide-print-right-position-dsk;
        }
      }
    }
  }

  .carousel-dots-wrapper {
    position: absolute;
    bottom: $margin-base;

    @include media($to: tbl-p) {
      padding-bottom: $padding-base;
    }

    .carousel-dots {
      background-color: $white;
      opacity: 0.8;

      &--active {
        opacity: 1;
      }
    }
  }
}

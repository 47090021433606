@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.dealerLocator {
  color: black;

  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  &__column, &__double-column {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 10px;
  } 
  
  &__double-column {
      flex: 2;
  }

  &__label, &__button {
    margin-left: 10px;
  }

  &__button {
    margin-left: 10px;
    background-color: #ffd300;
  }

  &__input {
    margin-left: 5px;
    border: 1px solid $color-light-grey;
  }

  &__switch-button-on, &__switch-button-off {
    margin-left: 10px;
    background-color: green;
    cursor: pointer;
    padding: 1px 6px;
    border-width: 2px;
    border-style: outset;
    border-color: black;
  }

  &__switch-button-off {
    background-color: red;
  }
}
@import '../../../../../../../../styles/_variables.scss';
@import '../../../../../../../../styles/mixins';

.gdpr-consent {
  display: flex;
  flex-direction: column;
  padding: $padding-base * 1.5 0;
  max-width: 823px;

  @include media($to: tbl-p) {
    padding: 0;
  }

  &__checkbox-section {
    margin-bottom: $margin-base * 1.5;

    input[type='checkbox'] {
      appearance: none;
      margin: 0;
    }

    &--wrapper {
      display: flex;
      cursor: pointer;

      @include media($to: tbl-p) {
        align-items: flex-start;
      }
    }

    &--label {
      font-size: $font-size-small;
      line-height: $line-height-small;
      color: $color-dark-blue;
      letter-spacing: $letter-spacing-base;

      a {
        color: $color-primary-blue;
        text-decoration: underline;
        text-underline-position: under;
      }
    }

    &--icon {
      margin-right: $margin-base / 2;
      min-width: 20px;
    }
  }

  &__radio-section {
    display: flex;
    flex-direction: column;
    gap: $margin-base;
    &:not(:last-child) {
      margin-bottom: $margin-base * 1.5;
    }

    input[type='radio'],
    input[type='checkbox'] {
      appearance: none;
      margin: 0;
    }

    &-desc {
        font-size: $font-size-smaller;
        line-height: $line-height-smaller;
        color: $color-dark-grey;
    }
    &-title {
      font-size: $font-size-small;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
      color: $color-dark-blue;
      font-weight: $font-weight-bold;

      @include media($from: tbl-p) {
        font-size: $font-size-base;
      }
    }
  }

  &__contact-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: $padding-base * 1.125 $padding-base $padding-base * 1.125 0;
    border: none;
    background-color: $color-white;
    margin-bottom: $margin-base;

    @include media($to: tbl-p) {
      display: flex;
      flex-direction: column-reverse;
      padding: $padding-base / 2 $padding-base * 0.625 $padding-base 0;
    }

    input[type='radio'],
    input[type='checkbox'] {
      appearance: none;
      margin: 0;
    }
  }

  &__contact-section--disabled {
    position: relative;

    * {
      pointer-events: none;
    }

    &::after {
      position: absolute;
      content: '';
      height: 100%;
      width: 100%;
      background-color: rgba($color-white, 0.6);
      left: 0;
      top: 0;
      cursor: not-allowed;
    }
  }

  &__radio-section-options,
  &__contact-section-left {
    display: flex;

    label {
      margin-right: $margin-base * 1.5;
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: $margin-base * 0.5;

      p {
        font-size: $font-size-small;
        line-height: $line-height-small;
        letter-spacing: $letter-spacing-base;
        color: $blue-dark;
      }
    }

    &__radio-icon {
      margin-right: $margin-base / 2;
      min-width: 20px;
    }
  }

  &__contact-section-left {
    flex-direction: column;
    grid-gap: $margin-base;

    label {
      gap: 0px;
    }

    @include media($to: tbl-p) {
      grid-gap: $margin-base * 1.5;

      label:not(:last-of-type) {
        margin-bottom: 0;
      }
    }
  }

  &__contact-section-icon {
    margin-right: $margin-base / 2;
    min-width: 20px;
  }

  &__contact-section-right {
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
  }
}


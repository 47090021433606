@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.preferred-dealer-carousel {
  display: flex;
  flex-direction: column;
  gap: $margin-base * 2.5;

  @include media($from: tbl-p) {
    gap: $margin-base * 4;
    .carousel-arrow {
      display: none;
    }

    &:hover {
      .carousel-arrow {
        display: flex;
      }
    }
  }

  &__arrow {
    top: 32px;
    visibility: visible !important;

    &--hidden {
      visibility: hidden !important;
    }

    .custom-arrow__button {
      display: flex;
    }
  }

  .custom-arrow--right {
    .custom-arrow__button {
      margin-right: $margin-base/2;
    }
  }

  &__dots {
    // overwrite the default library style react-slick that put on style display: block
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-top: $margin-base * 1.5;
    margin-bottom: $margin-base * 2;
    overflow-x: auto;
    flex-wrap: wrap;

    li {
      border-radius: 50%;
      width: 8px;
      min-width: 8px;
      height: 8px;
      min-height: 8px;
      opacity: 0.3;
      background-color: $blue-dark;
      margin: $margin-base / 4 $margin-base;
    }

    .slick-active {
      opacity: 1;
      min-width: 10px;
      width: 10px;
      height: 10px;
      min-height: 10px;
    }
  }

  &__search-no-match {
    text-align: center;
    color: $black;
    padding: $padding-base 0 $padding-base * 2.5 0;
    background: $white;
    overflow-x: hidden;
    margin: 0;

    .dealer-info-icon {
      margin: 0 auto;
    }

    &-title {
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
      margin: $margin-base 0 0 0;
      color: $blue-dark;

      @include media($to: tbl-p) {
        font-size: $font-size-small;
      }
    }

    &-desc {
      font-size: $font-size-base;
      margin: $margin-base * 2 0 0 0;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
      color: $blue-dark;

      @include media($to: tbl-p) {
        margin: $margin-base * 1.5 $margin-base / 2 0;
        font-size: $font-size-small;
      }
    }
  }

  .slick-slide div {
    display: flex;
    align-items: stretch;
  }

  &__card-group {
    display: flex !important;
    gap: $margin-base;
  }

  .custom-arrow {
    height: 50%;
  }
}

.preferred-dealer-card {
  display: flex;
  gap: $margin-base * 0.5;
  justify-content: flex-start;
  padding: 0 0 $padding-base * 2 0;
  margin: 0 $margin-base * 2 0 $margin-base * 0.25;
  border-bottom: 1px solid $color-medium-grey;
  line-height: $line-height-small;
  letter-spacing: $letter-spacing-base;
  width: 300px;

  @include media($from: tbl-l) {
    width: 392px;
    &--large {
      width: 657px;
    }
  }

  &__name {
    font-weight: $font-weight-bold;
    font-size: $font-size-base;
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: $margin-base * 0.5;
  }

}
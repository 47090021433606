@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

$floating-link-width: 60px;
$floating-links-mbl-height: 64px;
$floating-link-text-box-height: 50px;
$floating-link-line-width: 16px;
$icon-dsk-width: 28px;
$icon-mbl-width: 20px;
$transition-x-value: calc(100% + 32px);
$right-initial-value: -1000px;

.floating-links-container {
  opacity: 0;
  pointer-events: none;

  &--show,
  &--editor {
    opacity: 1;
    pointer-events: all;
  }
}

.floating-links {
  position: fixed;
  transition: 1.5s ease-out;
  z-index: $z-index-floating-links;

  &--editor {
    animation: slideRight 3s linear forwards;
    transform: translateX(0);

    & .floating-links-link__link,
    & .floating-links-link__link a {
      @include media($from: tbl-p) {
        animation: slideRightInner 5s linear;
        animation-delay: 1.5s;
      }
    }

    @keyframes slideRight {
      0% {
        right: $right-initial-value;
      }
      50% {
        right: $right-initial-value;
      }
      100% {
        right: 0px;
      }
    }
    @keyframes slideRightInner {
      0% {
        transform: translateX(0);
      }
      70% {
        transform: translateX(0);
      }
      100% {
        transform: translateX($transition-x-value);
      }
    }
  }

  @include media($to: tbl-p) {
    background-color: $white;
    border-top-left-radius: ($floating-link-width/3);
    border-top-right-radius: ($floating-link-width/3);
    box-shadow: 0px -5px 20px rgba(134, 188, 237, 0.3);
    bottom: $right-initial-value;
    left: 0;
    right: 0;
    display: block;
    padding: $padding-base / 2 $padding-base / 2.4 $padding-base;
    transition: bottom 1.5s ease-out;
    z-index: $z-floating-menu-links-mobile;
  }

  @include media($from: tbl-p) {
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    display: flex;
    text-align: end;
    &--editor {
      // changes for forms overlay - generic popup
      .generic-popup__button {
        position: relative;
        right: -233px;
      }

      .editor_popup--visible {
        left: calc(283px - 100vw);
        top: 139px;
        height: calc(100vh - 139px); // editor mode header 139px - (top property)
      }
    }
  }

  &--load {
    @include media($to: tbl-p) {
      bottom: 0;
      transition: 1.5s ease-out;
    }
    @include media($from: tbl-p) {
      right: 0;
      transition: 1.5s ease-out;
    }
  }

  &--unload {
    @include media($to: tbl-p) {
      bottom: $right-initial-value;
      transition: 1.5s ease-out;
    }
    @include media($from: tbl-p) {
      right: $right-initial-value;
      transition: 1.5s ease-out;
    }
  }

  &__wrapper {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-right: calc(var(--body-scrollbar-width--scroll-hidden) - 1px);

    & li {
      margin: 0;
    }

    @include media($to: tbl-p) {
      height: $floating-links-mbl-height;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      & li {
        display: inline-block;
        height: $floating-links-mbl-height;
        overflow: hidden;
      }
    }
    @include media($from: tbl-p) {
      & li { 
        &:hover .floating-links-link__link,
        &:hover .floating-links-link__link a {
          right: 0px;
          width: auto;
        }
      }
    }
  }

  &-link__link {
    margin-right: calc(-1 * calc(var(--body-scrollbar-width--scroll-hidden) + 1px));
  }
}

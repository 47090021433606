@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

$yellow-banner-width: 185px;

.news-card {
  $root: &;
  padding: $padding-base $padding-base 0;
  box-sizing: border-box;
  margin-bottom: $margin-base * 2.5;

  @include media($from: dsk) {
    padding: $padding-base * 2 0 0;
    margin-bottom: 0;
    #{$root}__media-bg-image,
    #{$root}__banner-yellow,
    #{$root}__subtitle,
    #{$root}__cta {
      opacity: 0;
      transition: 0.5s opacity;
    }

    #{$root}__image {
      transition: 0.5s max-width;
    }

    #{$root}__banner-yellow {
      width: $yellow-banner-width / 2;
      transition: 0.5s all;
    }

    &:hover {
      #{$root}__media-bg-image,
      #{$root}__banner-yellow,
      #{$root}__subtitle,
      #{$root}__cta {
        opacity: 1;
      }
      #{$root}__image {
        max-width: 500px;
      }
      #{$root}__media-container:before {
        width: 0;
      }

      #{$root}__banner-yellow {
        width: $yellow-banner-width;
      }
    }
  }

  &--no-underline {
    text-decoration: none;
  }

  &__media-container {
    background-color: $white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $padding-base * 1.4 $padding-base * 1.7 $padding-base;

    @include media($from: dsk) {
      padding: 0;
      justify-content: flex-start;
    }
  }

  &__banner-yellow {
    position: absolute;
    background-color: $yellow-primary;
    height: 64px;
    bottom: 0;
    left: $margin-base * 3.2;
    width: $yellow-banner-width;
    z-index: $z-index-medium;

    @include media($from: dsk) {
      bottom: -16px;
    }
  }

  &__media-bg-image {
    position: absolute;
    top: 0;
    left: 0;

    @include media($from: tbl-p) {
      height: 85%;
    }

    @include media($from: dsk) {
      top: -9%;
      left: -10%;
    }
  }

  &__image {
    object-fit: cover;
    max-width: 100%;
    width: 100%;

    @include fixed-picture-aspect-ratio(289, 274);
    @include media($from: tbl-p) {
      @include fixed-picture-aspect-ratio(500, 280);
    }

    @include media($from: dsk) {
      max-width: 72%;
    }
  }

  &__content {
    padding-top: $padding-base * 1.5;
    @include media($from: dsk) {
      margin-left: $margin-base * 4;
    }
  }

  &__title {
    color: $blue-dark;
    font-size: $font-size-medium;
    line-height: $line-height-base;
    margin: 0 0 $margin-base / 2;
  }

  &__subtitle {
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    line-height: $line-height-small;
    margin: 0 0 $margin-base * 1.5;
    color: $blue-dark;
  }

  &__cta {
    font-size: $font-size-base;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    font-weight: $font-weight-bold;
    color: $color-primary;
    text-decoration: none;
    display: flex;
    align-items: center;

    .icon-wrapper {
      background-color: $color-primary;
    }
  }
}

@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.dealer-locator-detail {
    &__contacts {
        margin-top: $margin-base * 4;
        background-color: $color-backgroud-grey;

        @include media($from: tbl-p) {
            margin-top: $margin-base * 2.5;
        }

        &--wrapper {
            display: grid;
            gap: $margin-base;
            grid-template-columns: 1fr 1fr;
            padding: $padding-base * 2 0;

            @include media($to: tbl-l) {
                flex-direction: column;
                gap: $margin-base * 2;
                margin-top: $margin-base * 1.5;
                grid-template-columns: 1fr;
            }

            line-height: $line-height-small;

        }

        &__attributes {
            font-size: $font-size-small;
            display: flex;
            color: $color-primary;
            gap: $margin-base * 0.5;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
            &--wrapper {
                display: flex;
                flex-direction: column;
                gap: $margin-base;
                margin-top: $margin-base * 2;
            }

            p {
                min-width: 0;
                width: calc(100% - 40px);
                word-wrap: break-word;
            }


        }

        &__details {
            display: flex;
            flex-direction: column;
            min-width: 0;
        }

        &__title {
            font-weight: bold;
            color: $color-dark-blue;
            font-size: $font-size-medium;
            letter-spacing: $letter-spacing-small;

            @include media($to: tbl-l) {
                line-height: $line-height-base;
            }
        }

        &__address {
            font-size: $font-size-base;
            color: $color-dark-blue;
            margin-top: $margin-base * 0.5;
            letter-spacing: $letter-spacing-base;

            @include media($to: tbl-l) {
                font-size: $font-size-small;
            }
        }

        &__directions {
            font-size: $font-size-base;
            color: $color-primary;
            text-transform: uppercase;
            text-decoration: none;
            font-weight: bold;
            margin-top: $margin-base * 0.5;
            letter-spacing: $letter-spacing-base;
            display: flex;
            gap: $margin-base * 0.5;
            &:hover {
                color: $color-dark-blue;                
            }

            @include media($to: tbl-l) {
                font-size: $font-size-small;
                margin-top: $margin-base;
            }
        }

        &__map {
            position: relative;
            max-width: 392px; // max-width of map
            @include media($to: tbl-l) {
                max-width: 343px;
            }

        }
    }

    &__map-icon {
        position: absolute;
        height: 40px;
        width: 40px;
        display: grid;
        place-content: center;
        cursor: pointer;

        &--get-location {
            top: 50%;
            left: 50%;
            transform: translate(-50%, calc(-50% - 20px )); // 20px = height of icon(40px)/2;
        }

        &--zoom-in {
            background-color: $color-dark-blue;
            right: $margin-base * 1.5;
            bottom: $margin-base * 5;
            &:hover {
                background-color: $color-primary-blue;
            }
        }

        &--zoom-out {
            background-color: $color-dark-blue;
            right: $margin-base * 1.5;
            bottom: $margin-base * 1.5;
            &:hover {
                background-color: $color-primary-blue;
            }
        }
    }
}
@import '../../../styles/_variables.scss';

// Animations
@-webkit-keyframes slide-in {
  0% {
    display: none;
    opacity: 0;
    bottom: 0px;
  }

  80% {
    opacity: 1;
    bottom: -13px;
  }

  100% {
    display: block;
    opacity: 1;
    bottom: -15px;
  }
}

@-moz-keyframes slide-in {
  0% {
    display: none;
    opacity: 0;
    bottom: 0px;
  }

  80% {
    opacity: 1;
    bottom: -13px;
  }

  100% {
    display: block;
    opacity: 1;
    bottom: -15px;
  }
}

@keyframes slide-in {
  0% {
    display: none;
    opacity: 0;
    bottom: 0px;
  }

  80% {
    opacity: 1;
    bottom: -13px;
  }

  100% {
    display: block;
    opacity: 1;
    bottom: -15px;
  }
}

.info-tooltip-sep {
  position: relative;
  display: inline;
  vertical-align: baseline;
  top: -1px;

  &__icon {
    display: inline;
    vertical-align: baseline;

    .icon-wrapper {
      width: 16px;
      height: 16px;
    }
  }

  &__children-wrapper {
    position: fixed;
    z-index: $z-index-tooltip;
  }

  &__children {
    position: relative;
    transform: translate(-50%, 5px);
    box-shadow: 0 4px 25px 0 $color-tooltip-shadow;
    background: $color-white;
    opacity: 1;
    animation: slide-in 0.3s ease-out forwards;

    &--arrow {
      position: absolute;
      top: -9px;
      left: 50%;
      transform: translateX(-8px);
      height: 0px;
      width: 0px;
      border-bottom: 10px solid $color-white;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }

    &--hidden {
      display: none;
      pointer-events: none;
    }
  }
}

@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.news-listing-card {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__video-play-button {
    position: absolute;
    top: 50%;
    z-index: $z-index-carousel-video-play-button;
    padding: $padding-base * 0.75;
    transform: translate(-50%, -50%);
    left: 50%;
    cursor: pointer;
    background-color: $blue-dark;
    --pfe-theme--color--ui-complement: #{$white};

    &:hover {
      background: #{$color-primary};
    }

    @include media($to: tbl-p) {
      padding: $padding-base/2;
    }

    & .icon-wrapper {
      background-color: $white;
    }
  }

  &__border {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-bottom: 2px solid $color-light-grey-opacity03;
    margin: 0 $margin-base * 0.5;
  }

  &__button {
    margin: $margin-base * 2 0;

    &-wrapper {
      margin-top: auto;
    }
  }

  &__text {
    font-size: $font-size-small;
    color: $blue-dark;
    line-height: $line-height-small;

    &--title {
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      margin-top: $margin-base * 0.5;
    }

    &--content {
      margin-top: $margin-base * 0.75;
    }
  }

  &__date {
    margin-top: $margin-base;
    color: $color-medium-grey;
    font-weight: $font-weight-bold;
  }

  &__badge {
    position: absolute;
    top: $margin-base;
    right: $margin-base;
    z-index: 1;
    font-size: $font-size-smaller;
    line-height: $line-height-smaller;
    color: $black;
    padding: $padding-base / 4 $padding-base / 1.5;
    background-color: $grey-light;
    text-transform: uppercase;

    * {
      margin: 0;
    }
  }

  @include media($from: tbl-l) {
    img {
      object-fit: cover;
    }
  }

  &__media-wrapper {
    @include media($to: tbl-p) {
      margin: 0;
    }

    &--thumbnail {
      @include fixed-picture-aspect-ratio(300, 168);

      @include media($from: tbl-p) {
        @include fixed-picture-aspect-ratio(736, 226);
      }
    }

    &--video {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: $z-index-video-player-play-icon;
    }
    &--play-icon {
      width: 40px;
      height: 40px;
      z-index: $z-index-video-player-play-icon;
    }
  }

  &__image {
    @include fixed-picture-aspect-ratio(300, 168);

    @include media($from: tbl-p) {
      @include fixed-picture-aspect-ratio(736, 226);
    }
  }

  .btn-primary-yellow {
    padding: $padding-base * 0.8125 $padding-base * 1.5;
  }
}

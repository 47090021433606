@import '../../styles/_mixins.scss';
@import '../../styles/_variables.scss';

.card-column-layout {
  padding: $padding-base * 3 0;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: $z-index-card-column-layout-hover;

  @include media($to: tbl-p) {
    padding: $padding-base * 3 0;
  }

  &__title-button {
    display: flex;
    flex-direction: column;
    column-gap: $margin-base;
    row-gap: $margin-base;
    align-items: baseline;

    @include media($from: tbl-p) {
      flex-direction: row;
      padding: $padding-base * 5 0 0 0;
    }
  }

  @include max-width;

  &__header {
    padding: 0 0 $padding-base * 2 $padding-base * 2;

    @include media($to: tbl-p) {
      padding: 0 $padding-base $padding-base;
    }
  }

  &__eyebrow {
    text-transform: uppercase;
    margin: 0 0 $margin-base / 2 0;
    font-size: $font-size-base;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;

    @include media($to: tbl-p) {
      font-size: $font-size-small;
    }
  }

  &__title {
    font-weight: $font-weight-bold;
    font-size: $font-size-larger;
    line-height: $line-height-large;
    letter-spacing: $letter-spacing-large;
    margin: 0;

    @include media($to: tbl-p) {
      font-size: $font-size-large;
      line-height: $line-height-medium;
      letter-spacing: $letter-spacing-medium;
      margin: 0;
    }
  }

  &__subtitle {
    margin: $margin-base 0 0;
    font-size: $font-size-base;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $blue-dark;

    @include media($to: tbl-p) {
      font-size: $font-size-small;
    }
  }

  &__link {
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-small;
    font-size: $font-size-base;

    .icon-wrapper {
      width: 20px;
      height: 20px;
    }
  }

  & &__link,
  & &__pop-up {
    & .s-blue,
    & s.white {
      padding: 0;
    }
  }

  &__pop-up {
    flex-grow: 1;
  }

  &__left-margin {
    @include media($to: tbl-p) {
      margin-left: $margin-base * 1.5;
    }
  }

  &__cards {
    display: flex;
    flex-direction: column;
    z-index: $z-index-card-layout;

    @include media($from: tbl-p) {
      display: grid;
      flex-direction: initial;
      grid-column-gap: $margin-base * 5;
      grid-row-gap: $margin-base * 5;
      padding: $padding-base * 2 $padding-base * 4;
      position: relative;

      &--2ColumnLayout {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      &--3ColumnLayout {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      &--4ColumnLayout {
        grid-template-columns: repeat(4, minmax(0, 1fr));

        @include media($to: dsk) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      }
    }
  }

  &__footer {
    margin: $margin-base auto 0;
    width: fit-content;
  }

  &__items-count {
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $color-medium-grey;
    padding: 0 $padding-base * 2.5;
  }

  &__progress {
    display: block;
    width: 100%;
    min-width: 200px;
    height: 2px;
    margin-top: $margin-base * 0.5;
    position: relative;

    &--total {
      display: block;
      width: 100%;
      height: 100%;
      background-color: $grey-light;
    }

    &--completed {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background-color: $grey-medium;
    }
  }

  & &__load-more {
    margin: 0 auto;
    padding-bottom: 0;
    cursor: pointer;
  }
}

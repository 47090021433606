@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

@mixin text-styles() {
  font-size: $font-size-small;
  line-height: $line-height-small;
  letter-spacing: $letter-spacing-base;
  @include media($from: tbl-p) {
    font-size: $font-size-base;
  }
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include media($from: tbl-p, $to: tbl-l) {
    max-width: calc(100% - 80px);
  }

  @include media($from: tbl-l) {
    max-width: 800px;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: flex-start;
  }

  &__eyebrow {
    text-transform: uppercase;
    @include text-styles();
    color: $color-medium-grey;
    margin: 0 0 $margin-base / 2;
  }

  &__title-wrapper {
    margin: 0 0 $margin-base * 1.5;
  }

  &__title {
    font-size: $font-size-large;
    line-height: $line-height-medium;
    letter-spacing: $letter-spacing-medium;
    font-weight: $font-weight-bold;
    color: $color-dark-blue;
    margin: 0 0 $margin-base;

    @include media($to: tbl-p) {
      &--margin-bottom {
        margin: 0 0 $margin-base * 1.5;
      }
    }

    @include media($from: tbl-p) {
      display: inline;
      font-size: $font-size-larger;
      line-height: $line-height-large;
      letter-spacing: $letter-spacing-large;
      margin: 0 $margin-base * 1.5 0 0;
    }
  }

  &__link {
    display: inline-block;
    cursor: pointer;
    margin: 0 $margin-base * 1.5 $margin-base 0;

    @include media($from: tbl-p) {
      margin: $margin-base * 1.125 $margin-base * 1.5 0 0;
    }

    .s-blue {
      padding: 0;
    }
  }

  &__link-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__subtitle {
    @include text-styles();
    color: $color-dark-blue;
    margin: 0 0 $margin-base * 1.5;

    @include media($from: tbl-p) {
      margin: 0 0 $margin-base * 2;
      flex: 0 0 100%;
    }
  }

  &__generic-popup {
    margin: 0 0 $margin-base;
    @include media($from: tbl-p) {
      margin: 0 0 $margin-base * 1.5;
    }

    .scEmptyPlaceholder {
      width: clamp(80px, 100vw, 200px);
      height: clamp(20px, 100vh, 50px);
    }
  }
}

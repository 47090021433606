@import '../../../styles/_mixins.scss';
@import '../../../styles/_variables.scss';

.emptyApiDataMessage {
  @include max-width;

  margin: $margin-base;

  &__title {
    font-size: $font-size-base;
  }
}

@import '../../styles/_mixins.scss';
@import '../../styles/_variables.scss';

@mixin power-bar {
  background: $yellow-bright;
  content: '';
  display: block;
  height: 7px;

  @include media($from: dsk) {
    left: 0;
    width: 100vw;
  }
}

.news {
  @include max-width();
  background: $white;
  padding-top: $padding-base * 2;
  width: 100%;

  @include media($from: tbl-p) {
    &:hover {
      .custom-arrow__button {
        display: flex;
      }

      &::after {
        width: 465px;
      }
    }
  }

  @include media($to: dsk) {
    padding-bottom: $padding-base * 3;
  }

  @include media($from: dsk) {
    margin-bottom: $margin-base * 2.5;
  }

  &__top {
    padding: 0 0 $padding-base * 1.25;
    @include media($from: dsk) {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 0 0 0 $padding-base * 2;
    }
  }

  &__text {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    box-sizing: border-box;

    @include media($from: dsk) {
      width: 50%;
      align-items: center;
      flex-direction: unset;
      margin-bottom: $margin-base * 3.5;

      .link-with-arrow {
        margin-top: $margin-base * 2;
      }
    }

    @include media($to: dsk) {
      padding-left: 20px;
    }

    .link-with-arrow {
      line-height: $line-height-small;
      font-size: $font-size-base;
      color: $color-primary;

      a {
        line-height: $line-height-small;
        font-size: $font-size-base;
        color: $color-primary;
      }
    }
  }

  &__title {
    color: $color-dark-blue;
    font-size: $font-size-large;
    line-height: $line-height-medium;
    letter-spacing: $letter-spacing-medium;
    font-weight: $font-weight-bold;
    margin-bottom: $margin-base;
    margin-top: $margin-base * 2;

    @include media($from: dsk) {
      font-size: $font-size-larger;
      line-height: $line-height-large;
      letter-spacing: $letter-spacing-large;
      margin-right: $margin-base * 1.5;
    }
  }

  &__subtitle {
    flex-basis: 100%;
    margin: 0;
    margin-bottom: $margin-base;

    @include media($from: dsk) {
      font-size: $font-size-medium;
    }
  }

  &__social {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    order: 1;

    @include media($to: dsk) {
      justify-content: flex-end;
      flex-direction: column;
      align-items: center;
    }
  }

  &__social-text {
    display: block;
    font-size: $font-size-base;
    line-height: $line-height-small;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    width: 50%;
    color: $blue-dark;

    @include media($to: dsk) {
      text-align: center;
      padding-bottom: $padding-base;
    }
  }

  &__social-icons {
    display: flex;
    margin-bottom: $margin-base * 0.5;
    padding-bottom: $padding-base * 1;
    position: relative;

    @include media($from: dsk) {
      padding-bottom: $padding-base * 1.5;
      margin-left: $margin-base * 1.5;
    }

    &::after {
      $gap-caused-by-the-diagonal: 3px;

      @include power-bar();
      bottom: 0;
      position: absolute;
      right: -#{$padding-base * 2 + $gap-caused-by-the-diagonal};

      @include media($to: dsk) {
        width: 100%;
      }

      @include media($to: dsk) {
        right: 0;
        left: 0;
      }
    }

    .icon-wrapper {
      margin: 0 $margin-base;
      background-color: $blue-dark;
    }
  }

  &__social-icon-link {
    align-items: center;
    display: flex;
    justify-content: center;

    &:hover {
      .icon-wrapper {
        background-color: $color-primary;
      }
    }

    @include media($from: dsk) {
      &:last-child {
        margin-right: $margin-base * 4;
      }
    }
  }

  .custom-arrow {
    width: 9%;
    display: none;

    &__button {
      display: none;
    }
  }

  &__card-wrapper {
    display: flex;
    justify-content: space-between;
    margin: ($margin-base * 4.25) ($margin-base * 2) 0;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin: 0 $margin-base * 5.4;
    grid-column-gap: $margin-base * 2;
  }

  .slick-dots {
    display: flex !important; // override the inline-style that apply the library
    justify-content: center;
    align-items: center;
    position: unset;
    bottom: unset;
    & > li {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $blue-dark;
      opacity: 0.75;
      transition: all 0.3s;
      margin: 0 $margin-base / 2;

      & > button:before {
        content: unset;
      }

      &.slick-active {
        width: 10px;
        height: 10px;
        opacity: 1;
      }
    }
  }
}

@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.footer-cta {
  margin-top: $margin-base * 2;

  @include media($from: tbl-p) {
    margin-top: $margin-base * 2;
  }

  &__text {
    font-size: $font-size-smaller;
    font-weight: $font-weight-bold;
    line-height: $line-height-smaller;
    margin: 0;
    text-transform: uppercase;
  }

  .button {
    margin-top: $margin-base * 0.5;
    text-align: center;
    line-height: $line-height-small;
  }
}
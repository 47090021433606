@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.search {
  h2,
  h3,
  p,
  label {
    margin: 0;
  }

  @include max-width-fullscreen;
  background-color: $white;

  &__bar {
    padding: 0 $padding-base * 2 $padding-base * 1.5;
    @include max-width-fullscreen;
    background-color: $color-backgroud-grey;

    @include media($to: tbl-p) {
      padding: 0 $padding-base $padding-base * 1.5;
    }
  }

  &__wrapper {
    position: relative;
    width: 50%;

    @include media($to: tbl-p) {
      width: 100%;
    }
  }

  &__title {
    font-size: $font-size-larger;
    line-height: $line-height-large;
    color: $color-dark-blue;
    font-weight: $font-weight-bold;
    padding-top: $padding-base * 5;

    @include media($to: tbl-p) {
      font-size: $font-size-medium-large;
      line-height: $line-height-base + 2;
      letter-spacing: 0;
      padding-top: $padding-base * 2.5;
    }
  }

  &__subtitle {
    padding: $padding-base * 1.5 0 $padding-base * 2 0;

    &-results {
      font-size: $font-size-base;
      font-weight: $font-weight-normal;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
      color: $color-dark-blue;
    }

    @include media($to: tbl-p) {
      margin: $margin-base 0 $margin-base * 1.5 0;
      padding: 0;

      &-results {
        font-size: $font-size-small;
      }
    }
  }

  &__input-row {
    display: flex;
  }

  &__input-control {
    display: flex;
    align-items: center;
    background-color: $white;
    flex-grow: 1;
    box-shadow: 0 4px 25px 0 $color-button-shadow;

    @include media($from: tbl-p) {
      position: relative;
    }

    &-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      &-label {
        font-size: $font-size-smaller;
        font-weight: $font-weight-normal;
        line-height: $line-height-smaller;
        color: $color-dark-grey;
        padding: 0 $padding-base / 2;
      }
    }
  }

  &__button {
    padding: $padding-base * 0.8125 $padding-base * 1.5;
    font-family: $font-family;
  }

  &__input {
    padding: 0 $padding-base / 2;
    border: none;
    line-height: $line-height-small;
    outline: none;
    background-color: transparent;
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    color: $color-dark-blue;
    font-family: $font-family;
    letter-spacing: $letter-spacing-base;

    &--search-icon {
      margin: $margin-base * 0.75 $margin-base / 2;
      background-color: $color-medium-grey;
    }
  }

  &__item {
    background-color: $white;
    border-bottom: 1px solid $color-light-grey;
    padding: $padding-base 0 $padding-base * 1.5;

    a {
      text-decoration: none;
    }

    &__title {
      font-size: $font-size-medium;
      font-weight: $font-weight-bold;
      line-height: $line-height-small;
      color: $color-primary;
      margin: 0;
      padding-bottom: $padding-base / 2;
    }

    &__description {
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      line-height: $line-height-small;
      color: $color-dark-blue;
      padding-bottom: $padding-base / 2;

      @include media($to: tbl-p) {
        font-size: $font-size-small;
      }
    }

    &__snippet {
      font-size: $font-size-small;
      font-weight: $font-weight-normal;
      line-height: $line-height-smaller;
      color: $color-dark-blue;

      strong {
        background-color: $color-background-strong;
        font-weight: $font-weight-normal;
      }
    }
  }

  @mixin noResultsStyle {
    font-size: $font-size-base;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    text-align: center;
    color: $color-dark-blue;
  }

  &__content,
  &__no-results {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding-top: $padding-base * 2;

    @include media($to: tbl-p) {
      padding: $padding-base * 2 $padding-base 0;
    }

    &__title {
      @include noResultsStyle;
      padding-top: $padding-base;
      font-weight: $font-weight-bold;

      @include media($to: tbl-p) {
        line-height: $line-height-smaller + 2;
        letter-spacing: 0;
      }
    }

    &__description {
      @include noResultsStyle;
      font-weight: $font-weight-normal;
      padding-top: $padding-base * 2;

      @include media($to: tbl-p) {
        padding-top: $padding-base * 1.5;
        font-size: $font-size-small;
        line-height: $line-height-smaller;
        letter-spacing: 0;
      }
    }
  }

  &__content {
    padding: $padding-base * 2.5 $padding-base $padding-base * 5.375;

    @include media($to: tbl-p) {
      &__title {
        line-height: $line-height-smaller + 2;
        letter-spacing: 0;
      }

      &__description {
        font-size: $font-size-small;
        line-height: $line-height-smaller;
        letter-spacing: 0;
      }
    }
  }

  &__results {
    padding: $padding-base * 2.5 $padding-base * 2 0;

    @include media($from: tbl-p) {
      display: grid;
      grid-template-columns: minmax(392px, 25%) 1fr;
      column-gap: 16px;
    }

    @include media($to: tbl-p) {
      padding: $padding-base * 2 0 $padding-base * 1.5;
    }

    &__list {
      padding-left: $padding-base * 2.5;

      @include media($to: tbl-p) {
        padding: 0 $padding-base 0;
      }
    }

    &__accordian {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;

      &--title {
        font-size: $font-size-small;
        font-weight: $font-weight-normal;
        line-height: $line-height-small;
        font-family: $font-family;
        color: $color-dark-blue;
        letter-spacing: $letter-spacing-base;
      }

      button {
        padding: $padding-base;
        background-color: $color-backgroud-grey;
        display: flex;
        border: 0px;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  &__filters {
    background-color: $color-backgroud-grey;
    padding: $padding-base * 2 $padding-base * 2 $padding-base;

    @include media($to: tbl-p) {
      padding: $padding-base;
    }

    &__header {
      &--dsk,
      &--mbl {
        &-resetbtn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: $padding-base;
          word-break: break-word;
          flex-wrap: wrap;
          row-gap: $margin-base;
        }
      }

      @include media($from: tbl-p) {
        &--mbl {
          display: none;
        }
      }

      @include media($to: tbl-p) {
        &--dsk {
          display: none;
        }
      }

      &--icon {
        margin-left: $padding-base / 2;
        background-color: $color-primary;

        @include media($from: tbl-p) {
          flex: 0 0 24px;
        }
      }

      &--title {
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
        line-height: $line-height-medium;
        color: $blue-dark;
        word-break: break-word;

        @include media($to: tbl-p) {
          font-size: $font-size-medium;
          line-height: $line-height-base;
          padding-bottom: $padding-base;
        }

        @include media($from: tbl-p) {
          flex: 0 0 225px;
        }
      }

      button {
        padding: 0;
      }

      &__reset-btn {
        display: flex;
        align-items: center;
        background: none;
        border: none;
        outline: none;
        font-size: $font-size-base;
        font-weight: $font-weight-bold;
        line-height: $line-height-small;
        color: $color-primary;

        &:hover {
          cursor: pointer;
        }
      }
    }

    &__subtitle {
      font-size: $font-size-base;
      font-weight: $font-weight-normal;
      line-height: $line-height-small;
      color: $black;
      padding-bottom: $padding-base * 0.8125;

      @include media($to: tbl-p) {
        font-size: $font-size-base;
        line-height: $line-height-small;
        padding-bottom: 0;
      }
    }
  }

  // remove default ul and li styles
  ul {
    appearance: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: block;
  }

  .btn-primary-yellow {
    padding: $padding-base * 0.8125 $padding-base * 1.5;
    color: $color-dark-blue;

    &:disabled {
      background-color: $color-medium-grey;
      color: $white;
      pointer-events: none;
      font-size: $font-size-base;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
    }

    @include media($to: mbl) {
      padding: 0 $padding-base * 0.5625;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
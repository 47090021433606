@import '../../../../styles/_variables';
@import '../../../../styles/_mixins';

.dealer-locator-dsk-filters {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap-reverse;
  padding: $padding-base * 1.5 $padding-base * 5;
  row-gap: $margin-base * 1.5;

  &__droplists {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  &__reset-link {
    font-size: $font-size-base;
    font-weight: bold;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $color-primary-blue;
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: $margin-base * 0.5;
    margin-left: auto;

    @include media($from: dsk) {
      position: absolute;
      right: $margin-base * 5;
    }
  }
}

@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.stories-wrapper {
  background: $white;
}

.stories {
  position: relative;
  @include max-width;
  padding: $padding-base * 5 0 $padding-base * 4 $padding-base * 2;
  margin-left: 0;

  @include media($to: tbl-p) {
    padding: $padding-base * 3 0 $padding-base * 2.5 $padding-base;
  }

  @include media($from: dsk-max) {
    @include max-width;
    width: 100%;
    left: 0;
    right: 0;
  }

  &__card-container {
    margin-right: $margin-base/2;
    @include media($from: tbl-p) {
      margin-right: $margin-base * 2;
    }
  }

  &__container-no-slider {
    display: flex;
    overflow: hidden;
  }

  & > .slick-slider,
  &__container-no-slider {
    position: relative;
    margin-top: $margin-base * 0.5;

    @include media($from: tbl-p) {
      margin-top: 0;
    }
  }

  .custom-arrow {
    display: none;
    position: absolute;

    &--right {
      top: 0;
      right: calc(4% + 1px);
    }

    &--left {
      left: calc(4% + 1px);
    }
    &__button {
      padding: $padding-base / 4;
      &:hover {
        background: $color-primary;
        cursor: pointer;
        box-shadow: 0 2px 20px $color-arrow-hover-box-shadow;
      }
    }
  }

  &:hover {
    @include media($from: dsk) {
      .custom-arrow {
        display: flex;
      }
    }
  }

  & > .stories-card {
    margin: 0 $margin-base/2 0 0;
    @include media($from: tbl-p) {
      margin: 0 $margin-base 0 0;
    }
  }
}

@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.model-series-listing-wrapper {
  position: relative;
  margin: 0 auto;
  max-width: $width-max-page;
  padding: 0 $padding-base;

  @include media($from: tbl-p) {
    padding-left: $padding-base * 2;
  }
}

.model-series-listing {
  &__header {
    padding: $padding-base * 2.5 0 $padding-base;

    @include media($from: tbl-p) {
      padding: $padding-base * 5 0 0;
    }
  }

  &__eyebrow {
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $grey-medium;
    text-transform: uppercase;
    font-weight: $font-weight-normal;

    @include media($from: tbl-p) {
      font-size: $font-size-base;
    }
  }

  &__title {
    margin: 0;
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
    line-height: $line-height-medium;
    letter-spacing: $letter-spacing-medium;
    color: $blue-dark;

    @include media($from: tbl-p) {
      font-size: $font-size-larger;
      line-height: $line-height-large;
      letter-spacing: $letter-spacing-large;
    }
  }

  &__subtitle {
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $blue-dark;
    margin: $margin-base / 4 0 0;

    @include media($from: tbl-p) {
      font-size: $font-size-base;
      margin: $margin-base * 1.5 0 0;
    }
  }

  &__cards {
    padding: 0 0 $padding-base * 2;
    @include grid-layout(1);

    @include media($from: tbl-p) {
      position: relative;
      @include grid-layout(3);

      &--two-columns {
        @include grid-layout(2);
      }
    }
  }

  &__show-more {
    width: 100%;
    margin: 0 0 $margin-base * 2.5;

    @include media($from: tbl-p) {
      margin-bottom: $margin-base * 4;
    }
  }

  &__show-more-cta {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    text-align: center;
    color: $color-primary;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;

    .icon-wrapper {
      margin-left: $margin-base / 2;
      background-color: $color-primary;
    }
  }
}

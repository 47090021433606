@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.content-hub-nav-bar {
  &__wrapper {
    @include max-width;
    padding: $padding-base;

    @include media($from: tbl-p) {
      padding: initial;
    }

    @include media($from: dsk-l) {
      margin: 0 calc((100vw - #{$width-max-page}) / 2);
    }
  }

  &__no-hero-banner {
    background-color: $blue-pastel;
  }

  &__hero-banner {
    position: absolute;
    top: var(--navigation-custom-height, $height-navigation);
    z-index: $z-index-hero-banner-navigation-bar;
    width: 100%;
    min-height: 52px;

    @include media($from: tbl-p) {
      background-color: $color-pastel-opacity01;
    }
  }

  &__breadcrumb {
    display: none;
    flex-wrap: wrap;

    @include media($from: tbl-p) {
      display: flex;
      margin-top: 0;
      margin-bottom: 0;
      padding: $padding-base 0 $padding-base $padding-base * 2;
      align-items: center;
    }

    @include media($from: dsk-l) {
      margin-bottom: 0;
    }

    .nav-bar__item {
      align-items: center;
    }
  }

  a {
    text-underline-position: under;

    &:hover {
      text-decoration: underline;
    }

    &.link {
      text-decoration: none;
      color: $color-dark-blue;
      font-size: $font-size-small;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
      display: flex;
      align-items: center;

      @media screen and (min-width: map-get($breakpoints, tbl-p)) {
        display: none;
      }
    }
  }

  &__item {
    color: $color-dark-blue;
    display: flex;

    &--last {
      font-weight: $font-weight-bold;
      font-size: $font-size-small;
      line-height: $line-height-small;
    }

    &--text {
      font-size: $font-size-small;
      line-height: $line-height-small;
    }

    a {
      color: $color-dark-blue;
      text-decoration: none;
      font-size: $font-size-small;
      line-height: $line-height-small;
    }
  }

  &__back-icon {
    margin-right: $margin-base * 0.5;
  }

  .icon-wrapper {
    display: inline-block;
  }
}

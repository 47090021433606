@import '../../../../../styles/_variables.scss';
@import '../../../../../styles/_mixins.scss';

.filter-dropdown {
  .dropdown-font-styles {
    font-size: $font-size-small;
    line-height: $line-height-small;
    color: $color-dark-blue;
    letter-spacing: $letter-spacing-base;
  }

  position: relative;
  cursor: pointer;
  display: flex;
  column-gap: $margin-base;
  padding: 0 $padding-base * 1.5;
  margin: $margin-base 0;
  border-left: 2px solid $color-light-grey;

  &__title {
    @extend .dropdown-font-styles;
    pointer-events: none;
  }

  &:first-child {
    padding-left: 0;
    border-left: none;
  }

  &__icon {
    pointer-events: none;
  }

  &__option {
    &-wrapper {
      width: 262px;
      max-height: 492px;
      overflow-y: auto;
      @include scrollbars();
      position: absolute;
      top: calc(100% + #{$margin-base} * 0.75);
      z-index: $z-index-attachment-dropdown;
      background-color: $white;
      padding: 0 $padding-base;
      box-shadow: $shadow-blue-001;
    }

    position: relative;
    padding: $padding-base * 0.5 0;
    border-top: 1px solid $color-light-grey;
    @extend .dropdown-font-styles;

    &:first-child {
      border-top: none;
    }

    &:hover::before {
      content: '';
      width: calc(100% + 32px);
      position: absolute;
      left: -$padding-base;
      top: 0;
      height: 100%;
      background-color: $grey-light;
      z-index: $z-index-attachment-dropdown-item-background;
    }
  }
}

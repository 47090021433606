@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.next-steps {
  width: 100%;
  background-color: $color-backgroud-grey;
  border-top: 1px solid $color-light-grey;
  border-bottom: 1px solid $color-light-grey;

  &-list {
    margin: 0 auto;
    max-width: $width-max-page;
    display: flex;
    flex-direction: column;
    @include background-color();

    @include media($from: tbl-p) {
      flex-direction: row;
      justify-content: center;
    }

    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid $color-light-grey;
      margin: 0;
      padding: $padding-base * 1.75 $padding-base;

      @include media($from: tbl-p) {
        border-bottom: none;
        justify-content: center;
        flex-grow: 1;
        flex-basis: 0;
        align-items: flex-start;
        padding: $padding-base * 2.56 0;
      }

      &:last-of-type {
        a {
          border-bottom: none;
          @include media($from: tbl-p) {
            border-right: none;
          }
        }
      }

      &--title {
        font-size: $font-size-larger;
        line-height: $line-height-medium;
        color: $color-primary-blue;
        letter-spacing: $letter-spacing-medium;
        font-weight: $font-weight-bold;
        @include media($from: tbl-p) {
          font-size: $font-size-larger;
          line-height: $line-height-large;
          letter-spacing: $letter-spacing-large;
          @include background-color();
          align-items: center;
        }
      }
    }

    &__item-link {
      text-decoration: none;
      font-size: $font-size-base;
      font-weight: bold;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-transform: uppercase;
      border: 0;

      @include media($from: tbl-p) {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        width: 100%;
        border-right: 2px solid $color-light-grey;
        height: 100%;
      }
    }

    &__item-link-icon {
      margin-right: $padding-base * 1.5;
      @include media($from: tbl-p) {
        margin-right: unset;
        margin-bottom: $padding-base;
      }
    }

    &__item-link-text {
      color: $color-primary-blue;
      font-size: $font-size-base;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
      text-transform: uppercase;
    }
  }
}

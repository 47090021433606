@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.youtube-video-wrapper {
  width: 100%;
  height: 100%;
  margin: 0;

  iframe {
    width: 100% !important;
    height: 100% !important;
    display: block !important;
    margin: 0 !important;
  }

  .play-icon-center {
    background-color: $blue-dark;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .play-icon-defaults {
    width: 80px;
    height: 80px;
    z-index: $z-index-video-player-play-icon;
    @include media($to: tbl-p) {
      width: 40px;
      height: 40px;
    }
  }

  &__icon {
    background-color: $white;
  }
}

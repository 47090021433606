@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

@mixin fixed-full-view {
  position: fixed;
  top: var(--navigation-offset, 0);
  bottom: 0;
  left: 0;
  right: 0;
  transition: top 0.4s ease;
}

@mixin one-line-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

$active-el-attribute: data-active-el;

@mixin active-el-position($position) {
  &[#{$active-el-attribute}='#{$position}'] {
    @content;
  }
}

$z-index-offerings-overlapping-elements: 2; // this is relative, since the z-index of the sticky wrapper is set to 0

$icon-size: 20px;

.offerings {
  overflow: visible;
  min-height: $vh100;
  position: relative;

  @include media($to: dsk) {
    margin-top: $margin-base * 2;
  }

  @include media($from: dsk) {
    margin-top: $margin-base * 2.5;
  }

  // remove default ul and li styles
  ul {
    appearance: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: block;
  }

  &,
  * {
    box-sizing: border-box;
  }

  &--fully-in-view {
    .offerings {
      &__sticky-wrapper {
        @include media($from: dsk) {
          @include fixed-full-view;
          height: calc(100vh - var(--navigation-offset));
        }
      }

      @include media(null, dsk) {
        &__card-list {
          overflow-y: scroll;
          scroll-snap-type: y mandatory;
        }
      }
    }
  }

  &--above-view {
    .offerings {
      &__sticky-wrapper {
        bottom: 0;
      }

      @include media(null, dsk) {
        &__card-list {
          overflow-y: hidden;
        }
      }
    }
  }

  &__sticky-wrapper {
    @include media(dsk) {
      position: absolute;
      height: $vh100;
      width: 100%;
      display: flex;
      // this z-index is 0 so that every overlapping element's z-index is relative
      // and does not impact floating elements
      z-index: 0;
    }
  }

  &__title {
    display: inline-block;
    font-weight: $font-weight-bold;
    font-size: $font-size-large;
    line-height: $line-height-medium;
    letter-spacing: 1.8px;
    color: $blue-dark;
    max-width: 100%;
    background-color: $white;
    position: absolute;
    top: 0;
    z-index: $z-index-offerings-overlapping-elements;
    margin: 0;
    padding: $padding-base * 0.5 $padding-base;
    width: 282px;
    transform: translateY(-50%);
    box-sizing: border-box;

    &--sticky {
      position: fixed;
      top: var(--position-offering-title-sticky, 0);
      transform: translateY(0%);
    }

    &--sticky-mobile {
      position: unset;
    }
  }

  // region mobile only
  &__card-list {
    @include media($from: dsk) {
      height: 100vh;
      transform: translateY(0);
      transition: transform 0.8s ease;
    }
  }

  &__card {
    @include media($from: dsk) {
      height: calc(100vh - var(--navigation--Height--actual));
      scroll-snap-align: start;
      scroll-snap-stop: always;
    }
  }

  &__card-inner {
    height: 100%;
    width: 100%;
  }

  &__card-text-wrapper {
    padding: $padding-base $padding-base $padding-base * 3;
  }

  // endregion mobile only

  @include media(null, dsk) {
    &__title {
      transform: translateY(0%);
    }

    &__card {
      padding-top: $padding-base * 2.5;

      &--adjust-for-header {
        padding-top: $padding-base * 7.8125;
      }
    }

    &__card-image {
      width: 100%;
      height: auto;
    }

    &__card-text-wrapper {
      padding: $padding-base $padding-base $padding-base/2;
    }
  }

  &__card-title {
    display: block;
    color: $blue-dark;
    font-weight: $font-weight-bold;
    font-size: $font-size-medium;
    line-height: $line-height-small;
    margin-bottom: $margin-base;
    letter-spacing: 0.56px;
  }

  &__card-subtitle {
    color: $blue-dark;
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: 0.8px;
  }

  &__card-link {
    &--first {
      &.link-with-arrow {
        &,
        a {
          color: $color-primary;
        }
      }

      margin: $margin-base * 0.5 0 $margin-base * 1.5;
    }

    &--second {
      margin-top: $margin-base;
    }
  }

  @include media(dsk) {
    $margin-top-content-wrapper: $margin-base * 4.5;
    $line-height-title: $line-height-large;

    &__container {
      max-width: $width-max-page;
      width: 100%;
      margin: auto;
    }

    &__title {
      background-color: transparent;
      margin: 0 0 0 $margin-base * 2;
      font-size: $font-size-larger;
      line-height: $line-height-title;
      letter-spacing: 2px;
      @include one-line-ellipsis;
      position: static;
      z-index: auto;
      padding: 0;
      width: auto;
    }

    &__content-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      position: relative;
      margin-top: $margin-top-content-wrapper;
    }

    &__start-box {
      // left when text direction is left to right
      background-color: $yellow-primary;
      position: absolute;
      left: 0;
      width: 3/12 * 100%;
      bottom: 0;
      height: 315px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: stretch;
      z-index: $z-index-offerings-overlapping-elements;
    }

    &__start-box-whiteBar {
      width: 11px;
      height: 36px;
      position: absolute;
      right: 0;
      top: var(--white-bar-top, 57px);
      transform: translate(50%, -50%);
      background-color: $white;
      transition: all 0.4s ease;
    }

    &__card-title-wrapper {
      font-size: $font-size-medium;
      line-height: $line-height-small;
      font-weight: $font-weight-bold;
      text-align: center;
      color: $grey-dark;
      width: 100%;
      cursor: pointer;

      @include active-el-position('this') {
        font-size: $font-size-large;
        line-height: $line-height-medium;
        position: relative;
        color: $blue-dark;

        & .offerings__card-title {
          font-size: $font-size-large;
          letter-spacing: 0.56px;
          line-height: $line-height-medium;
          transform: scale(1.1);
        }
      }
    }

    &__card-title {
      max-width: 100%;
      color: inherit;
      margin: 0;
      @include one-line-ellipsis;
      letter-spacing: 0.5px;
      text-align: left;
      padding-left: $padding-base * 1.5;
      transition: transform 0.4s ease;

      @include media($from: dsk) {
        padding-left: $padding-base * 4;
      }

      @include media($from: dsk-l) {
        padding-left: $padding-base * 10.5;
      }
    }

    &__images-wrapper {
      width: 920/1680 * 100%;
      position: relative;
      overflow: hidden;

      @include media($from: dsk) {
        height: 520px;
        max-height: calc(
          #{$vh100} - #{$line-height-title + $margin-top-content-wrapper}
        ); // prevent overflow when the window's height is low
      }
    }

    &__card-image-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: transform 0.8s ease;
      transform: translateY(0);
      overflow: hidden;

      @include active-el-position('above') {
        transform: translateY(100%);
      }
    }

    &__card-image,
    &__card-image-wrapper .sc-image-wrapper img {
      position: absolute;
      right: 0;
      bottom: 0;
    }

    &__card-image {
      pointer-events: none;
      width: 100%;
      height: 100%;
      object-fit: cover;

      &--active {
        pointer-events: all;
      }
    }

    &__end-box {
      // right when text direction is left to right
      width: 3/12 * 100%;
      height: 368px;
      position: relative;
      overflow: hidden;
      max-height: calc(#{$vh100} - #{$line-height-title + $margin-top-content-wrapper}); // prevent overflow when the window's height is low

      &:before {
        content: '';
        display: block;
        height: 298px;
        background-color: $grey-light;
        opacity: 0.45;
      }
    }

    &__card-content {
      padding: 0 $padding-base * 4.5 $padding-base * 3 $padding-base * 2;
      position: absolute;
      width: 100%;
      max-height: 100%;
      bottom: 0;
      transition: bottom 0.8s ease, opacity 0.4s ease;
      opacity: 1;
      display: flex;
      flex-direction: column;

      & .icon-wrapper {
        height: $icon-size;
        width: $icon-size;
      }

      @include active-el-position('above') {
        bottom: -100%;
        opacity: 0;
      }

      @include active-el-position('below') {
        bottom: 100%;
        opacity: 0;
      }
    }

    &__card-subtitle {
      font-size: $font-size-base;
      $line-height: $line-height-small;
      line-height: $line-height;
      max-height: $line-height * 6; // @todo
      margin: $margin-base * 2 0;
      flex-shrink: 1;
      overflow: hidden;
    }

    &__card-link {
      &--first {
        margin: 0;
      }

      &--second {
        margin-top: $margin-base * 1.5;
      }
    }
  }
}

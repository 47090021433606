@import '../../../styles/_variables.scss';

.icon-wrapper {
  display: grid;
  place-content: center;
  background-color: $blue-dark;

  &.primary {
    background-color: $color-primary;
  }

  &.white {
    background-color: $white;
  }

  &.warning {
    background-color: $color-warning;
  }

  &.error {
    background-color: $color-error;
  }

  &.grey {
    background-color: $color-medium-grey;
  }

  &.black {
    background-color: $black;
  }

  &.success {
    background-color: $color-success;
  }
}

@import '../../../../styles/_variables.scss';
@import '../../../../styles/mixins';

.high-level-alert-message {
  margin: auto;
  display: flex;
  gap: $padding-base * 2;
  padding: $padding-base * 1.5 $padding-base * 1.5 $padding-base * 1.5 $padding-base * 2.5;
  border-radius: $border-radius-alert-messag;
  border: $border-alert-messag;
  background-color: $alert-message-background-color;

  @include media($to: tbl-p) {
    padding: $padding-base;
    gap: $padding-base;
  }

  &__without-description {
    display: flex;
    align-items: center;
  }
  &__alert-message-content {
    flex: 1 1;
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $color-dark-blue;
    vertical-align: middle;
  }

  &__description {
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $color-dark-blue;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: 16px;
  }
}

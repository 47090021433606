@import 'styles/_reset.scss';
@import 'styles/_mixins.scss';
@import 'styles/_variables.scss';
@import 'styles/_cookies';

@import './styles/_utils.scss';
@import './styles/_grid.scss';
@import './styles/@shared/rich-text.scss';

@import 'styles/slick/slick-DO-NOT-EDIT-ME.scss';
@import 'styles/slick/slick-theme-DO-NOT-EDIT-ME';

body {
  font-family: 'Lato', sans-serif;
  --pfe-reveal-duration: 0.1618s;
  --pfe-reveal-delay: 2s;
  -webkit-transition: opacity var(--pfe-reveal-duration) ease-in-out;
  transition: opacity var(--pfe-reveal-duration) ease-in-out;
  --pfe-broadcasted--text: var(--pfe-theme--color--text, #1e1e1d);
  --pfe-broadcasted--link: var(--pfe-theme--color--link, #004b93);
  --pfe-broadcasted--link--hover: var(--pfe-theme--color--link--hover, #004b93);
  --pfe-broadcasted--link--focus: var(--pfe-theme--color--link--focus, #004b93);
  --pfe-broadcasted--link--visited: var(--pfe-theme--color--link--visited, #004b93);
  --pfe-broadcasted--link-decoration: none;
  --pfe-broadcasted--link-decoration--hover: underline;
  --pfe-broadcasted--link-decoration--focus: underline;
  --pfe-broadcasted--link-decoration--visited: none;
  .navigation button {
    font-family: $font-family;
  }
}

body[unresolved] {
  opacity: 0;
  -webkit-animation: reveal var(--pfe-reveal-duration) var(--pfe-reveal-delay) 1 forwards;
  animation: reveal var(--pfe-reveal-duration) var(--pfe-reveal-delay) 1 forwards;
}

@-webkit-keyframes reveal {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes reveal {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// this set the correct margin between header and other components
#root {
  padding-top: var(--navigation-custom-height, $height-navigation);
  min-height: calc(100vh - #{$height-navigation});
  display: flex;
  flex-direction: column;
}

#root-footer {
  margin-top: auto;
}

.root-padding-none {
  // important is required to override above padding-top
  padding: 0 !important;
}

h1 {
  font-weight: 700;

  @include media($to: tbl-p) {
    font-size: $font-size-large;
    letter-spacing: $letter-spacing-medium;
    line-height: $line-height-medium;
  }

  @include media($from: tbl-p) {
    font-size: $font-size-extra-large;
    letter-spacing: $letter-spacing-large;
    line-height: $line-height-larger;
  }
}

h2 {
  font-weight: 700;

  @include media($to: tbl-p) {
    font-size: $font-size-large;
    letter-spacing: $letter-spacing-medium;
    line-height: $line-height-medium;
  }

  @include media($from: tbl-p) {
    font-size: $font-size-larger;
    letter-spacing: $letter-spacing-large;
    line-height: $line-height-large;
  }
}

h3 {
  font-weight: 700;
  letter-spacing: $letter-spacing-small;

  @include media($to: tbl-p) {
    font-size: $font-size-smaller;
    line-height: $line-height-small;
  }

  @include media($from: tbl-p) {
    font-size: $font-size-larger;
    line-height: $line-height-medium;
  }
}

h4 {
  font-weight: 700;
  letter-spacing: $letter-spacing-small;
  font-size: $font-size-smaller;
  line-height: $line-height-base;
}

h5 {
  font-size: $font-size-smaller;
  line-height: $line-height-smaller;
}

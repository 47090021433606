@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.special-offer-detail {
  @mixin special-offer-common-text-styles {
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
  }

  &-wrapper {
    @include max-width();
    color: $color-dark-blue;
  }

  margin: 0;
  padding: $padding-base * 1.5 $padding-base;

  @include media($from: tbl-p) {
    width: 47.62%;
    padding: $padding-base * 4 $padding-base * 2;
  }

  &__title {
    margin: $margin-base * 1.375 0 0;
    font-size: $font-size-large;
    line-height: $line-height-medium;
    letter-spacing: $letter-spacing-medium;

    @include media($from: tbl-p) {
      margin-top: $margin-base;
      font-size: $font-size-larger;
      line-height: $line-height-large;
      letter-spacing: $letter-spacing-large;
    }
  }

  &-categories {
    margin: $margin-base * 1.375 0 0;

    @include media($from: tbl-p) {
      margin-top: $margin-base * 2;
    }

    &__title {
      @include special-offer-common-text-styles;
      margin-right: $margin-base * 0.5;

      @include media($from: tbl-p) {
        font-size: $font-size-base;
        margin-right: $margin-base * 0.25;
      }
    }

    &__link {
      @include special-offer-common-text-styles;
      color: $color-primary;
      font-weight: $font-weight-bold;

      &,
      &:focus,
      &:visited,
      &:hover {
        color: $color-primary;
      }
    }
  }

  &__description {
    @include special-offer-common-text-styles;
    margin: $margin-base * 1.25 0 0;

    @include media($from: tbl-p) {
      font-size: $font-size-base;
      margin-top: $margin-base;
    }
  }

  &__disclaimer {
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
    padding: 0;
    @include special-offer-common-text-styles;
    font-weight: $font-weight-bold;
    margin-top: $margin-base;
    color: $color-primary;
    text-decoration: underline;
    cursor: pointer;
  }

  &__expiry {
    @include special-offer-common-text-styles;
    font-weight: $font-weight-bold;
    margin: $margin-base * 0.5 0 0;
  }

  &-modal__content {
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;

    @include media($from: tbl-p) {
      font-size: $font-size-base;
    }
  }

  &__button-wrapper {
    margin-top: $margin-base * 1.5;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    row-gap: $margin-base;

    @include media($from: tbl-p) {
      flex-direction: row;
    }
  }

  &__button {
    &-wrapper &--secondary {
      padding: 0 $padding-base !important;
    }
  }

}

.my-modal-dialog.special-offer-detail-modal {
  padding: $padding-base * 5 $padding-base * 2 $padding-base * 4;

  @include media($to: tbl-p) {
    padding: $padding-base $padding-base $padding-base * 2.5;
  }

  &>div {
    text-align: left;
    margin: 0 auto;

    @include media($from: tbl-p) {
      max-width: 632px;
    }

    @include media($to: tbl-p) {
      max-width: 343px;
    }
  }
}
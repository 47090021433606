@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.dealer-locator-detail {
    &__business-hours-section {
        display: grid;
        grid-template-columns: 2.06fr 1fr;
        column-gap: $margin-base;
        color: $blue-dark;
        line-height: $line-height-small;
        padding: $padding-base * 2 0 $padding-base * 1.5 0;

        @include media($to: tbl-l) {
            grid-template-columns: 1fr;
            gap: $margin-base * 2;
        }
    }

    &__business-hours {
        font-size: $font-size-base;
        letter-spacing: $letter-spacing-base;

        @include media($to: tbl-l) {
            font-size: $font-size-small;
        }

        &__title {
            font-size: $font-size-medium;
            font-weight: bold;
            letter-spacing: $letter-spacing-small;

            @include media($to: tbl-l) {
                line-height: $line-height-base;
                padding: 0 $padding-base;
            }
        }

        &__table {
            margin-top: $margin-base;

            @include media($to: tbl-l) {
                margin-top: $margin-base * 0.75;
            }
        }

        &__table-row {
            display: grid;
            grid-template-columns: 2fr 1fr 1fr;
            column-gap: $margin-base;
            padding: $padding-base * 0.5 0;

            &:nth-child(even) {
                background-color: $white;
            }

            &:nth-child(odd) {
                background-color: $color-backgroud-grey;
            }

            &__day {
                font-weight: bold;
                margin-left: $margin-base * 0.5;
            }

            @include media($to: tbl-l) {
                grid-template-columns: 1fr 1fr 1fr;
                font-size: $font-size-small;

                &__day {
                    margin-left: $margin-base;
                }
            }
        }
    }

    &__certifications {
        @include media($to: tbl-l) {
            padding: 0 $padding-base;
        }

        &__title {
            font-size: $font-size-medium;
            font-weight: bold;
            letter-spacing: $letter-spacing-small;

            @include media($to: tbl-l) {
                line-height: $line-height-base;
            }
        }

        &__awards-section {
            margin-top: $margin-base;
            display: flex;
            flex-direction: column;
            gap: $margin-base;

            @include media($to: tbl-l) {
                flex-direction: row;
            }
        }

        &__award {
            max-width: 256px;
            max-height: 130px;
            object-fit: contain;

            @include media($to: tbl-l) {
                max-width: 160px;
                max-height: 103px;
            }
        }
    }
}
@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

$link-color: $blue-dark;
$link-color-hover: $color-primary;

.footer-navigation {
  $line-height-link: $line-height-smaller;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  &__item {
    align-items: center;
    display: flex;
    margin: 0;
    margin-bottom: $margin-base * 0.5;
    padding: 0 $padding-base 0 0;

    &:first-child {
      padding-left: 0;
    }

    &:not(:last-child):after {
      border-right: 1px solid rgba(125, 123, 123, 0.5);
      content: '';
      display: block;
      height: $line-height-link;
      padding-left: $padding-base;
    }
  }

  &__link {
    color: $link-color;
    font-size: $font-size-smaller;
    text-decoration: none;
    line-height: $line-height-link;
    letter-spacing: normal;

    &:hover {
      color: $link-color-hover;
      text-decoration: underline;
    }
  }

  &__cookieBtn {
    // overrides third party external consent cookie notice button style
    // important is required to override
    border: none !important;
    background: transparent !important;
    color: $link-color !important;
    padding: 0 !important;
    font-size: $font-size-smaller !important;
    font-family: inherit;
    letter-spacing: normal;
    line-height: $line-height-smaller !important;
    &:hover {
      color: $link-color-hover !important;
      text-decoration: underline;
    }
  }
}

@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.model-listing-wrapper {
  position: relative;
  margin: 0 auto;
  max-width: $width-max-page;
  display: flex;
  flex-direction: column;
  padding: 0 $padding-base;

  @include media($from: tbl-p) {
    box-sizing: border-box;
    padding: 0 $padding-base * 2 $padding-base * 4;
  }
}

.model-listing {
  display: flex;
  flex-direction: column;

  .heading {
    &__eyebrow {
      padding-top: $padding-base * 3;

      @include media($from: tbl-p) {
        padding-top: $padding-base * 5;
      }
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: $margin-base * 5;
    row-gap: $margin-base * 4;

    @include media($from: tbl-p) {
      grid-template-columns: repeat(2, 1fr);
      padding: $padding-base * 4 $padding-base * 2 0;
    }

    @include media($from: tbl-l) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media($from: dsk) {
      grid-template-columns: repeat(4, 1fr);
    }

   
  }
  &__animate-card {
    @include fade-in-up-animation();
    animation-fill-mode: both;
  }

  &__show-more {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: $margin-base * 2.5 0;
  }

  &__show-more-cta {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    line-height: 1.5;
    letter-spacing: 0.8px;
    text-align: center;
    color: $color-primary;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin: 0 ($margin-base / 2) 0 0;

    .icon-wrapper {
      margin-left: $margin-base / 2;
      background-color: $color-primary;
    }
  }
}
@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.splash-component {
  &__container {
    @include max-width();
    padding-left: $padding-base;
    padding-right: $padding-base;
    width: 100%;
    box-sizing: border-box;

    @include media($from: tbl-p) {
      padding-left: $padding-base * 2;
      padding-right: $padding-base * 2;
    }
  }

  &__desktop-img,
  &__mobile-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  &__desktop-img {
    display: none;

    @include media($from: tbl-p) {
      display: block;
    }
  }

  &__mobile-img {
    display: block;

    @include media($from: tbl-p) {
      display: none;
    }
  }

  &__wrapper {
    position: relative;
    min-height: calc(100vh - #{$splash-page-header-height});
  }

  &__content-wrapper{
    position: relative;
    z-index: $z-index-splash-page-content;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - #{$splash-page-header-height});
  }
}

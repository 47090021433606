@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

$item-height: 104px;

.other-quick-link-wrapper {
  background-color: $white;
  @include max-width();
}

.other-quick-link {
  position: relative;

  @include media($to: tbl-l) {
    padding: ($padding-base * 12.25) $padding-base ($padding-base * 6) $padding-base;
  }

  @include media($from: tbl-l) {
    padding: ($padding-base * 5.5) 0 ($padding-base * 9);
  }

  &__text-heading {
    @include media($to: tbl-l) {
      margin: 0;
    }
    @include media($from: tbl-l) {
      margin: 0 0 ($margin-base * 2.5) 0;
    }
  }

  &__text-box {
    box-sizing: border-box !important;
    background-color: $blue-dark;
    color: $white;
    z-index: $z-index-find-equipment-text-box;

    @include media($to: tbl-l) {
      left: 0;
      padding: ($padding-base * 2.5) ($padding-base * 1.5) $padding-base;
      height: 164px;
      position: absolute;
      top: 40px;
      width: 68vw;
    }

    @include media($from: tbl-l) {
      padding: ($padding-base * 5) 4vw ($padding-base * 5.25) 10vw;
      position: relative;
      width: 45vw;
      max-width: 756px;
    }

    @include media($from: dsk-l) {
      padding: ($padding-base * 5) ($padding-base * 4) ($padding-base * 5.25) ($padding-base * 10.5);
    }
  }

  &__subtitle-wrapper {
    @include media($to: tbl-l) {
      display: none;
    }
    @include media($from: tbl-l) {
      max-height: $line-height-small * 4;
      overflow: hidden;
    }
  }

  &__subtitle *,
  &__subtitle {
    font-size: $font-size-base;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-small;
    margin: 0;
  }

  &__grid {
    max-height: 301px;
    position: absolute;

    @include media($to: tbl-l) {
      height: 52vw;
      right: 11.5vw;
      top: 75px;
    }

    @include media($from: tbl-l) {
      bottom: $padding-base * 2;
      height: 18vw;
      left: 31.5vw;
    }

    @include media($from: dsk-l) {
      left: 530px;
    }
  }

  &__buttons {
    display: inline-block;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;

    @include media($to: tbl-l) {
      background-color: $white;
      position: relative;
      width: 100%;
      z-index: $z-index-find-equipment-text-box + 1;
      padding: 0 0 $padding-base * 1.5 0;
      top: -44px;
    }

    @include media($from: tbl-l) {
      height: $item-height;
      position: absolute;
      right: 0;
      top: 217px;
    }
  }
}

@import '../../../../../../styles/_mixins.scss';
@import '../../../../../../styles/_variables.scss';

.gdpr-popup {
  display: flex;
  flex-direction: column;
  padding: $padding-base * 1.5 0;
  max-width: 823px;
  margin: 0 auto;

  @include media($to: tbl-p) {
    padding: 0;
  }

  &__checkbox-section {
    margin-bottom: $margin-base;

    @include media($to: tbl-p) {
      margin-bottom: $margin-base * 1.5;
    }

    input[type='checkbox'] {
      appearance: none;
      margin: 0;
    }

    &--wrapper {
      display: flex;
      cursor: pointer;

      @include media($to: tbl-p) {
        align-items: flex-start;
      }
    }

    &--label {
      font-size: $font-size-small;
      line-height: $line-height-small;
      color: $color-dark-blue;
      letter-spacing: $letter-spacing-base;

      a {
        color: $color-primary-blue;
        text-decoration: underline;
        text-underline-position: under;
      }
    }

    &--icon {
      margin-right: $margin-base / 2;
      min-width: 20px;
    }
  }

  &__radio-section,
  &__contact-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: $padding-base * 1.125 $padding-base;
    border: none;
    background-color: $color-white;
    margin-bottom: $margin-base;

    @include media($to: tbl-p) {
      display: flex;
      flex-direction: column-reverse;
      padding: $padding-base / 2 $padding-base * 0.625 $padding-base $padding-base / 2;
    }

    input[type='radio'],
    input[type='checkbox'] {
      appearance: none;
      margin: 0;
    }
  }

  &__contact-section--disabled {
    position: relative;

    * {
      pointer-events: none;
    }

    &::after {
      position: absolute;
      content: '';
      height: 100%;
      width: 100%;
      background-color: rgba($color-white, 0.6);
      left: 0;
      top: 0;
      cursor: not-allowed;
    }
  }

  &__radio-section-left,
  &__contact-section-left {
    display: flex;

    @include media($to: tbl-p) {
      margin: $margin-base * 1.5 0 0 0;
      flex-direction: column;
    }

    label {
      margin-right: $margin-base * 1.5;
      display: flex;
      align-items: center;
      cursor: pointer;

      @include media($to: tbl-p) {
        &:not(:last-of-type) {
          margin-bottom: $margin-base * 1.5;
        }
      }

      p {
        font-size: $font-size-small;
        line-height: $line-height-small;
        letter-spacing: $letter-spacing-base;
        color: $blue-dark;
      }
    }

    &__radio-icon {
      margin-right: $margin-base / 2;
      min-width: 20px;
    }
  }

  &__contact-section-left {
    flex-direction: column;
    grid-gap: $margin-base;

    @include media($to: tbl-p) {
      grid-gap: $margin-base * 1.5;

      label:not(:last-of-type) {
        margin-bottom: 0;
      }
    }
  }

  &__contact-section-icon {
    margin-right: $margin-base / 2;
    min-width: 20px;
  }

  &__contact-section-right {
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
  }

  &__radio-button-section {
    &-title {
      text-transform: uppercase;
      margin-bottom: $margin-base * 0.8125;
      font-size: $font-size-base;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
      color: $color-black;
      font-weight: $font-weight-bold;

      @include media($to: tbl-p) {
        margin-bottom: $margin-base * 0.6875;
      }
    }

    &-desc {
      font-size: $font-size-smaller;
      line-height: $line-height-smaller;
      color: $color-black;
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: $margin-base * 0.5;

    @include media($to: tbl-p) {
      justify-content: space-between;
    }
  }

  &__submit-button,
  &__cancel-button {
    padding: 0.8125 * $padding-base 1.5 * $padding-base;
    font-family: $font-family;
    cursor: pointer;
    text-transform: uppercase;
    border: none;
    outline: none;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    text-align: center;
  }

  &__submit-button {
    background-color: $color-primary-yellow;
    color: $color-black;

    &:hover {
      box-shadow: $primary-btn-box-shadow;
      background-color: $color-bright-yellow;
    }

    &:disabled {
      background-color: $color-medium-grey;
      color: $color-white;
      box-shadow: none;
      cursor: not-allowed;
    }
  }

  &__cancel-button {
    margin-right: $margin-base;
    background: $color-white;
    border: 2px solid $color-dark-blue;
    color: $color-dark-blue;

    &:hover {
      box-shadow: $secondary-btn-box-shadow;
      border: 2px solid $color-primary-blue;
      color: $color-primary-blue;
    }
  }
}

.gdpr-popup-modal {
  &__overlay {
    bottom: 0;
    transform: none;
    left: 0;
    margin: 0;
    right: 0;
    width: 100%;

    @include media($from: tbl-p) {
      .top-aligned-modal.my-modal-dialog {
        top: 50px;
        transform: translateX(-50%);
      }

      .center-aligned-modal.my-modal-dialog {
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__content {
    position: relative;
    width: 100%;
    background-color: $color-light-grey;
    left: 50%;
    max-width: 993px;

    @include media($to: tbl-p) {
      padding: 16px;
      top: 0;
      left: 0;
      transform: none;
    }
  }
}

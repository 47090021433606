@import './_variables.scss';
@import './_mixins.scss';

.container {
  @include max-width();
  box-sizing: border-box;
  margin: auto;
  width: 100%;
  padding: 0 $padding-base;

  @include media($from: tbl-p) {
    padding: 0 $padding-base * 2;
  }

  &--fluid {
    padding: 0;
  }

  .col {
    box-sizing: border-box;
    margin-right: $gutter;

    &:last-child {
      margin-right: 0;
    }
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
}

@mixin grid-generator($grid-columns) {
  @for $i from 1 through $grid-columns {
    .col-#{$i} {
      @extend .col;
      width: calc(#{percentage($i / $grid-columns)} - #{$gutter});
    }

    .col-offset-#{$i} {
      margin-left: percentage($i / $grid-columns);
    }
  }

  @for $i from 1 through $grid-columns {
    .col-xs-#{$i} {
      @extend .col;
      @include media($from: mbl-s) {
        width: calc(#{percentage($i / $grid-columns)} - #{$gutter});
      }
    }

    .col-xs-offset-#{$i} {
      @include media($from: mbl-s) {
        margin-left: percentage($i / $grid-columns);
      }
    }
  }

  @for $i from 1 through $grid-columns {
    .col-md-#{$i} {
      @extend .col;
      @include media($from: tbl-p) {
        width: calc(#{percentage($i / $grid-columns)} - #{$gutter});
      }
    }

    .col-md-offset-#{$i} {
      @include media($from: tbl-p) {
        margin-left: percentage($i / $grid-columns);
      }
    }
  }

  @for $i from 1 through $grid-columns {
    .col-lg-#{$i} {
      @extend .col;
      @include media($from: dsk) {
        width: calc(#{percentage($i / $grid-columns)} - #{$gutter});
      }
    }

    .col-lg-offset-#{$i} {
      @include media($from: dsk) {
        margin-left: percentage($i / $grid-columns);
      }
    }
  }

  @for $i from 1 through $grid-columns {
    .col-xl-#{$i} {
      @extend .col;
      @include media($from: dsk-l) {
        width: calc(#{percentage($i / $grid-columns)} - #{$gutter});
      }
    }

    .col-xl-offset-#{$i} {
      @include media($from: dsk-l) {
        margin-left: percentage($i / $grid-columns);
      }
    }
  }
}

@include grid-generator(12);

// EXAMPLE
// <div className="container">
// <div className="row">
//   <div className="col-3"></div>
//   <div className="col-3"></div>
//   <div className="col-3"></div>
//   <div className="col-3"></div>
// </div>
// </div>

@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

[id*='comm100-float-button'] > div:first-child {

    @include media($from: dsk) {
        right: $margin-base * 2 !important;
        bottom: 100px !important;
        left: unset !important;
        transform: unset !important;
    }
  
    @include media($to: dsk) {
        left: unset !important;
        bottom: 133px !important;
        right: 0px !important;
        transform: rotateZ(270deg) translateX(100%) !important;
        transform-origin: 100% 100% !important;
    }
}
@import '../../../styles/_variables.scss';

$box-shadow-cta: 0px 10px 20px;

.button {
  display: flex;
  width: fit-content;
  align-items: center;
  cursor: pointer;

  &.p-blue,
  &.p-yellow,
  &.p-ghost {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    text-align: left;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-smaller;
    text-transform: uppercase;
    padding: $padding-base * 0.8125 $padding-base * 1.5;
    position: relative;
    text-decoration: none;
    transform: perspective(1px) translateZ(0);
    transition: all 0.1s ease-in;

    &::before {
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transform: scaleY(0);
      transform-origin: 50% 100%;
      transition-property: transform;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
      z-index: $z-index-background;
    }

    &:hover::before {
      transform: scaleY(1);
    }
  }

  &.p-yellow {
    background: $yellow-primary;
    color: $black;

    &::before {
      background: $yellow-bright;
    }

    &:hover {
      box-shadow: $box-shadow-cta rgba(255, 211, 0, 0.3);
    }
  }

  &.p-blue {
    background: $blue-dark;
    color: $white;

    &:hover {
      box-shadow: $box-shadow-cta rgba(0, 62, 133, 0.2);
      color: $white;
    }

    &::before {
      background: $color-primary;
    }
  }

  &.p-ghost {
    border: 2px solid $blue-dark;
    background-color: $white;
    color: $blue-dark;

    &:hover {
      color: $color-primary;
      border-color: $color-primary;
      box-shadow: $box-shadow-cta rgba(134, 188, 237, 0.3);
    }
  }

  &.s-white,
  &.s-blue {
    color: $white;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    text-align: left;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-small;
    text-transform: uppercase;
    padding: $padding-base;
    position: relative;
    text-decoration: none;
    transform: perspective(1px) translateZ(0);
    transition: all 0.1s ease-in;

    &:hover {
      text-decoration: underline;
      text-underline-offset: 6px;

      .cta-arrow {
        transform: translateX($padding-base * 0.5);
      }
    }
  }

  &.s-blue {
    color: $color-primary;

    .icon-wrapper {
      background-color: $color-primary;
    }
  }

  &.button--small {
    padding: $padding-base * 0.25 $padding-base * 1.5;
  }

  // for EE (Experience Editor)
  a {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    text-decoration: inherit;
  }
}

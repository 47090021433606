@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

$floating-link-width: 50px;
$floating-links-mbl-height: 64px;
$floating-link-text-box-height: 50px;
$floating-link-line-width: 16px;
$icon-dsk-width: 28px;
$icon-mbl-width: 20px;
$line-dsk-height: 2px;

@mixin floating-link-mobile-styles {
  text-align: center;
  vertical-align: top;
  flex-basis: 25%;
  flex-grow: 1;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: $margin-base;
    right: 0;
    width: 2px;
    height: 16px;
    background-color: $blue-pastel;
  }
}

.floating-links-link {
  cursor: pointer;

  @include media($to: tbl-p) {
    @include floating-link-mobile-styles();

    &:last-child {
      &:after {
        content: unset;
      }
    }
  }

  @include media($from: tbl-p) {
    position: relative;

    &:hover {
      & .floating-links-link__link,
      & .floating-links-link__link a {
        transform: translateX(0);
      }
    }
  }

  &__link,
  & .floating-links-link__link a {
    display: inline-block;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active,
    &:visited {
      text-decoration: none;
    }

    @include media($to: tbl-p) {
      height: calc(100% - calc(#{$padding-base} * 0.125));
      overflow: hidden;
      text-decoration: none;
      width: 100%;

      & p,
      & a {
        color: $blue-dark;
        font-size: $font-size-medium / 2;
        line-height: $line-height-small / 2;
        margin: 0;
        margin-top: $margin-base / 2;
      }

      &:hover,
      &:focus,
      &:active,
      &:visited {
        color: $blue-dark;
      }
    }

    @include media($from: tbl-p) {
      background: $floating-links-gradient-background;
      border-radius: 50px;
      color: $white;
      font-weight: $font-wight-medium-bold;
      font-size: $font-size-base;
      height: $floating-link-text-box-height;
      letter-spacing: $letter-spacing-base;
      line-height: $line-height-small;
      margin: 0;
      position: absolute;
      right: 0;
      transform: translateX(100%);
      transition: transform 0.5s ease-out;
      vertical-align: top;
      white-space: nowrap;

      & p,
      & a {
        display: inline-block;
        height: auto !important;
        margin: 0 !important;
        padding: (($floating-link-text-box-height - $line-height-small) * 0.5) ($padding-base + $floating-link-width)
          (($floating-link-text-box-height - $line-height-small) * 0.5) $padding-base;
        text-transform: uppercase;
      }
    }
  }

  &__image-wrapper {
    @include media($to: tbl-p) {
      display: block;
      height: $icon-mbl-width;
      margin: ($margin-base * 0.75) auto 0;
      width: $icon-mbl-width;
    }

    @include media($from: tbl-p) {
      background-color: $white;
      box-shadow: $shadow-pastel4;
      display: inline-block;
      height: $floating-link-width;
      position: relative;
      width: $floating-link-width;
    }
  }

  @include media($from: tbl-p) {
    &:first-child &__image-wrapper {
      border-top-left-radius: $floating-link-width/3;
    }

    &__chat:nth-child(3) &__image-wrapper {
      border-top-left-radius: $floating-link-width/3;
    }

    &:last-child &__image-wrapper {
      border-bottom-left-radius: $floating-link-width/3;
    }
  }

  &__image {
    height: 100%;

    @include media($to: tbl-p) {
      overflow: hidden;

      & img {
        max-height: $icon-mbl-width;
        max-width: $icon-mbl-width;
      }
    }

    @include media($from: tbl-p) {
      align-items: center;
      display: flex;
      justify-content: center;
      overflow: hidden;

      & img {
        max-height: $icon-dsk-width;
        max-width: $icon-dsk-width;
      }
    }
  }

  &__line {
    background-color: $blue-pastel;
    bottom: 0;
    height: $line-dsk-height;
    left: ($floating-link-width - $floating-link-line-width) * 0.5;
    position: absolute;
    width: $floating-link-line-width;
  }

  &:last-child &__line {
    display: none;
  }
}

.floating-links__contactUs {
  .floating-links-link {
    &__image-wrapper {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  @include media($from: tbl-p) {
    .floating-links-link {
      &__line {
        display: block !important;
      }
    }

    &:first-child .floating-links-link__image-wrapper {
      border-top-left-radius: $floating-link-width/3 !important;
    }

    &:last-child {
      .floating-links-link {
        &:after {
          content: unset;
        }

        &__image-wrapper {
          border-bottom-left-radius: $floating-link-width/3 !important;
        }

        &__line {
          display: none !important;
        }
      }
    }
  }

  @include media($to: tbl-p) {
    @include floating-link-mobile-styles();

    .floating-links-link {
      &:after {
        display: none;
      }
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}

@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

@keyframes grow {
  0% {
    max-height: 0vh;
  }

  100% {
    max-height: 100vh;
  }
}

@keyframes shrink {
  0% {
    max-height: 100vh;
  }

  100% {
    max-height: 0px;
  }
}

@mixin fit-child-width-to-content {
  // this is so that link do not take full width if not necessary
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@mixin primary-text-style {
  color: $color-text-navigation;
  font-style: normal;
  font-weight: $font-weight-bold;
  font-size: $font-size-small;
  line-height: $line-height-smaller;
  text-decoration: none;
}

@mixin media-breakpoint-main-navigation() {
  @include media(dsk) {
    @content;
  }
}

@mixin link-decoration() {
  text-decoration: underline;
  text-decoration-color: $color-primary;
  text-decoration-thickness: 3px;
  text-underline-position: under;
}

$padding-horizontal-navigation--desktop: $padding-base;
$padding-horizontal-navigation--mobile: 0;
$padding-horizontal-navigation-item--mobile: $padding-base;
$padding-horizontal-navigation-item--desktop: $padding-base;
$padding-horizontal-navigation-item-main--desktop: $padding-base * 1.25;
$padding-horizontal-navigation-item-right--desktop: $padding-base;
$color-background-label-open: rgba(220, 221, 222, 0.2);
$color-background-navigation: $white;
$color-background-navigation-menu-item-open: linear-gradient(0deg, rgba(220, 221, 222, 0.2), rgba(220, 221, 222, 0.2)), $white;
$color-text-navigation: $blue-dark;
$color-text-navigation-menu-item-open: $blue-dark;
$breakpoint-main-navigation: map-get($breakpoints, dsk);
$media-query-gap: 1px;
$column-dsk-width: 272px;

.navigation {
  // region NEW STYLE

  display: flex;
  flex-direction: column;
  width: 100%;
  height: $height-navigation;
  z-index: $z-index-navigation;
  margin-top: 0;
  transition: all 0.3s ease-out;
  position: relative;

  &--EE-active {
    // Experience Editor active
    position: relative;

    a {
      &:not(.navigation__menu-item__trigger) {
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
        text-decoration: inherit;
      }
    }
  }

  &--hidden-up {
    height: 0;
    overflow: hidden;
    pointer-events: none; // avoid the possibility of clicking the menu during the transition up
  }

  &--menu-open {
    &::before {
      opacity: 1 !important;
      box-shadow: 0px 4px 20px -10px rgba(0, 0, 0, 0.2);
    }
  }

  &::before {
    background-color: $color-background-navigation;
    content: '';
    height: 100%;
    left: 0;
    opacity: 1;
    position: absolute;
    transition: opacity 0.3s ease-out;
    top: 0;
    width: 100%;
    box-shadow: 0px 4px 20px -10px rgba(0, 0, 0, 0.2);
  }

  &--hide {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-out;
  }

  &--trasparent {
    &::before {
      background-color: transparent;
      box-shadow: none;
      transition: background-color 0.3s ease;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      pointer-events: none;
      background-color: transparent;
      z-index: $z-index-transparent-nav-after;
      height: 118px;
      background-image: linear-gradient(to bottom, rgba($white, 0.7), rgba($white, 0.5) 49%, rgba($white, 0));
    }
  }

  &:hover {
    &::before {
      background-color: $color-background-navigation;
      box-shadow: 0px 4px 20px -10px rgba(0, 0, 0, 0.2);
    }

    &::after {
      display: none;
    }
  }

  &__container {
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
    height: 100%;
    width: 100%;
    background-color: transparent;
    padding: 0 #{$padding-horizontal-navigation--mobile};
    position: relative;
    @include max-width;

    @include media-breakpoint-main-navigation {
      padding: 0 #{$padding-horizontal-navigation--desktop};
    }
  }

  &__item-wrapper {
    display: flex;
    align-items: stretch;
    margin-bottom: 0; // override default for ul
    margin-top: 0;

    &--main {
      & .navigation__menu-item__trigger {
        padding: 0 $padding-horizontal-navigation-item--mobile;

        @include media-breakpoint-main-navigation {
          padding: 0 $padding-horizontal-navigation-item-main--desktop;
        }
      }
    }

    &--right {
      z-index: $z-index-header;
      margin-left: auto;
      margin-top: 0;

      & .navigation__menu-item__trigger {
        padding: 0 $padding-horizontal-navigation-item--mobile;

        @include media-breakpoint-main-navigation {
          padding: 0 $padding-horizontal-navigation-item--desktop;
        }
      }
    }
  }

  &__logo {
    display: block;
    margin: 0;
    font-size: $font-size-large + 4;
    font-weight: $font-weight-bold;
    line-height: $line-height-large;

    & > span.navigation__logo__link {
      display: none;
    }

    &__link {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 $padding-horizontal-navigation-item--mobile;
      font-size: $font-size-smaller; // for alt image text

      @include media-breakpoint-main-navigation {
        padding: 0 $padding-horizontal-navigation-item--desktop;
      }
    }

    &__image {
      display: block;
      width: auto;
      max-height: 40px;
    }
  }

  &__menu-item {
    display: block;
    height: 100%;
    margin-bottom: 0; // override default for li

    &__trigger {
      cursor: pointer;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      appearance: none;
      border: none;
      height: 100%;
      font-size: $font-size-base;
      font-weight: 500;
      line-height: $line-height-small;
      background-color: transparent;
      color: $color-text-navigation;
      text-decoration: none;

      @include media-breakpoint-main-navigation {
        min-width: 100px;
      }

      &:hover,
      &:focus {
        // for link
        text-decoration: none;
      }

      &:hover {
        font-weight: $font-weight-bold;
        transition: all 0.3s ease-in-out;

        & .navigation__menu-item__trigger__text {
          font-weight: $font-weight-bold;
        }
      }

      &:visited {
        color: $color-text-navigation;
      }

      &__content {
        display: block;

        &::before {
          display: block;
          content: attr(title);
          font-weight: bold;
          height: 0;
          overflow: hidden;
          visibility: hidden;
        }

        &--with-icon {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: $font-size-small;
          line-height: $line-height-small;
        }

        &--with-circle {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 40px;
          width: 40px;
          background-color: $color-primary;
          color: $white;
          border-radius: 50%;
        }
      }

      &__icon {
        margin-bottom: 4px;
        background-color: $color-dark-blue;
      }

      &__text {
        color: $blue-dark;
        font-size: $font-size-small;
        line-height: $line-height-smaller;
        &::before {
          display: block;
          content: attr(title);
          font-weight: bold;
          height: 0;
          overflow: hidden;
          visibility: hidden;
        }
      }

      &__text--with-circle {
        color: $white;
        font-size: $font-size-base + 2;
        line-height: $line-height-smaller + 2;
        font-weight: $font-weight-bold;
      }
    }

    &__tray {
      position: absolute;
      top: $height-navigation;
      left: 0;
      width: 100%;
      max-height: calc(100vh - #{$height-navigation});
      overflow: auto;
      background: $grey-lighter;
      color: $color-text-navigation-menu-item-open;
      z-index: $z-index-sticky-nav-dropdown;

      &--open {
        max-height: calc(100vh - #{$height-navigation});
        visibility: visible;
        transition: all 0.3s ease-in-out;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      &--close {
        max-height: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
        &::-webkit-scrollbar {
          display: none;
        }
      }

      @include media($to: tbl-p) {
        position: fixed;
        bottom: 0;
      }

      a {
        text-decoration: none;

        &,
        &:visited {
          color: $color-text-navigation;
        }

        &:hover {
          @include link-decoration;
          transition: all 0.3s ease-in-out;
        }
      }

      &__container {
        &--search {
          padding: $padding-base * 3 0 $padding-base * 3.5;
          background-color: $grey-lighter;

          .header-search-popup {
            position: relative;
            max-width: 800px;
            margin: 0 auto;
          }

          .search {
            &__input-row {
              display: grid;
              grid-template-columns: minmax(100px, 669px) 1fr;
              min-height: 50px;
              width: 100%;

              @include media($from: tbl-p) {
                column-gap: 16px;
              }
            }

            &__input-control {
              background-color: transparent;
              border-bottom: 1px solid $black;
              box-shadow: none;

              &-container {
                width: calc(100% - 40px);

                &-label {
                  padding: 0 $padding-base / 2 0 0;
                  letter-spacing: 0;
                }
              }
            }

            &__button {
              padding: $padding-base * 0.8125 $padding-base * 1.5;
              font-family: $font-family;

              &:hover {
                box-shadow: $primary-btn-box-shadow;
              }
            }

            &__input {
              background-color: transparent;
              padding: 0 $padding-base / 2 0 0;
              color: $color-dark-blue;
              font-family: $font-family;
              letter-spacing: $letter-spacing-base;

              &--search-icon {
                margin: $margin-base * 0.75 $margin-base / 2;
                background-color: $color-medium-grey;
              }
            }
          }

          .search-suggestions--search-page-input {
            position: relative;
            top: 0px;
            transform: none;
          }
        }

        .search__button:disabled {
          background-color: $color-medium-grey;
          color: $white;
          pointer-events: none;
          font-size: $font-size-base;
          line-height: $line-height-small;
          letter-spacing: $letter-spacing-base;
        }
      }
    }

    &__hamburger-tray {
      max-height: unset;
      bottom: 0;
      height: 0;
      overflow: hidden;
      transition: height 0.5s ease-out;
    }

    &--open {
      background: $color-background-label-open;

      .navigation__menu-item {
        &__trigger {
          background-color: $color-background-navigation-menu-item-open;
          color: $color-text-navigation;
          font-weight: $font-weight-bold;

          &:visited {
            color: $color-text-navigation-menu-item-open;
          }
        }

        &__hamburger-tray {
          height: calc(100% - #{$height-navigation});
          overflow: auto;

          @include media($from: tbl-p) {
            height: calc(100vh - #{$height-navigation});
          }
        }
      }
    }

    &--mobile-menu {
      .navigation__menu-item {
        &__trigger {
          @include media(null, tbl-l) {
            background-color: transparent;
          }
        }

        &__tray {
          @include media(tbl-l) {
            max-width: 432px;
            left: auto;
            right: 0;
          }
        }
      }

      .navigation__utility__icon {
        margin-right: 0;
      }
    }
  }

  &__overlay {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $vh100;
    z-index: $z-index-background;
  }

  a,
  button {
    outline: none;
  }

  &__menu-mobile-wrapper {
    display: flex;
    min-height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }

  &__menu-mobile-line {
    background: $grey-medium;
    border: 0;
    height: 1px;
    margin: 0 $margin-base;
    opacity: 0.5;
    width: calc(100% - calc(#{$padding-base} * 2));
  }

  // region ACCORDION ITEM

  &__accordion-wrapper {
    margin-bottom: $margin-base * 2.5;
  }

  &__accordion-item {
    margin: ($margin-base * 1.5) 0 0;

    &__trigger {
      display: flex;
      width: 100%;
      appearance: none;
      border: none;
      justify-content: space-between;
      background-color: transparent;
      font-size: $font-size-base;
      line-height: $line-height-medium;
      padding: 0 $padding-base;

      @include media(tbl-l) {
        padding: 0 $padding-base * 2;
      }

      &:hover,
      &:focus {
        // for link
        text-decoration: none;
      }

      &__text {
        color: $color-text-navigation;
        font-size: $font-size-base;
        font-weight: 500;
        line-height: $line-height-small;
      }

      &__icon {
        display: block;
        font-size: 24px; // icon size
        background-color: $color-primary;
      }
    }

    @include media($from: dsk) {
      .pfe-navigation-item {
        &__tray {
          margin-top: $margin-base * 1.5;
        }
      }
    }
  }

  // endregion ACCORDION ITEM

  &__utility {
    &,
    &:last-child {
      // override default for ul
      margin-bottom: $margin-base * 2.5;
    }

    &:before {
      content: '';
      height: 1px;
      margin: 0 $margin-base;
      display: block;
      background-color: $color-background-navigation-menu-item-open; // @todo
    }

    &__item {
      margin: $margin-base * 1.5 $margin-base 0;
    }

    &__link {
      display: inline-flex;
      align-items: center;

      &:hover,
      &:visited,
      &:focus {
        color: $color-text-navigation;
        text-decoration: none;
      }
    }

    &__icon {
      font-size: $font-size-medium-large; // icon size
      margin-right: $margin-base * 1.5;
      background-color: $blue-dark;
    }

    &__text {
      font-size: $font-size-small;
      font-weight: $font-weight-normal;
      line-height: $line-height-smaller;
    }
  }

  // endregion NEW STYLE

  .pfe-navigation {
    &__custom-column {
      @include media($to: dsk) {
        margin-top: $margin-base;

        &:first-child {
          margin-top: $margin-base * 1.5;
        }
      }

      width: 100%;

      @include fit-child-width-to-content;

      padding: 0 $padding-base * 2.5;

      @include media(dsk) {
        box-sizing: border-box;
        padding: $padding-base * 2 $padding-base * 0.5;
        width: $column-dsk-width;

        &:nth-child(5n-4) {
          // first, sixth
          padding-left: $padding-base;
        }

        &:nth-child(5n) {
          // fifth, tenth
          padding-right: $padding-base * 3;
        }
      }
    }
  }

  .pfe-navigation-item {
    &__tray {
      &--full-width {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        overflow: hidden;
      }

      &--open {
        animation: grow 0.3s;
      }

      &--close {
        @include media($to: dsk) {
          animation: shrink 0.3s;
        }
      }
    }
  }

  .navigation-link {
    display: block;
    color: #1e1e1d;

    margin-bottom: $margin-base * 1.5;

    @media (min-width: $breakpoint-main-navigation) {
      margin-bottom: $margin-base;
    }

    &:last-child {
      @media (min-width: $breakpoint-main-navigation) {
        margin-bottom: 0;
      }
    }

    &--primary {
      @include primary-text-style;
      @include fit-child-width-to-content; // for children in desktop

      @media (min-width: $breakpoint-main-navigation) {
        &:hover {
          color: $color-primary;
          text-decoration: underline;
          transition: all 0.3s ease-in-out;
        }
      }

      // all nested links
      .navigation-link {
        @media (max-width: ($breakpoint-main-navigation - $media-query-gap)) {
          display: none;
        }
      }
    }

    &--has-secondary {
      &:not(:last-child) {
        @media (min-width: $breakpoint-main-navigation) {
          margin-bottom: $margin-base * 2;
        }
      }
    }

    &--secondary,
    &--secondary-with-bold {
      font-size: $font-size-small;
    }

    &--secondary {
      font-weight: $font-weight-normal;
    }

    &--secondary-with-bold {
      .navigation-link {
        &__non-bold-text {
          font-weight: $font-weight-normal;
        }

        &__divider {
          color: #a71931;
        }
      }
    }

    &__main-anchor,
    &__view-all {
      display: block;
    }

    &__main-anchor {
      @media (min-width: $breakpoint-main-navigation) {
        margin-bottom: $margin-base * 1.5;
      }
    }

    &__view-all {
      @media (max-width: ($breakpoint-main-navigation - $media-query-gap)) {
        display: none;
      }

      font-weight: $font-weight-normal;
      text-decoration: underline;
    }

    &--title {
      font-weight: 300;
      font-size: $font-size-medium;
      line-height: $line-height-smaller;
      letter-spacing: $letter-spacing-large;
      margin: ($margin-base * 1.5) 0 ($margin-base * 1.5) 0;
      color: $blue-dark;

      @include media($to: dsk) {
        margin-top: $margin-base;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    // region for image type

    $underline-thickness: 8px;

    &__image-wrapper {
      display: none;
    }

    &__text {
      @include primary-text-style;
    }

    @media (min-width: $breakpoint-main-navigation) {
      &--image {
        width: 100%;
        position: relative;
        overflow: hidden;

        &:not(:last-child) {
          margin-bottom: $margin-base * 2;
        }

        &:hover {
          .navigation-link {
            &__text {
              text-decoration: none;
            }

            &__text-wrapper {
              transform: translateY(0);
            }
          }
        }
      }

      &__text {
        display: block;
        font-weight: $font-weight-bold;
        font-size: $font-size-base;
        line-height: $line-height-base;
        color: $white;
        text-align: center;
        padding: 0 $padding-base * 0.5;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__text-wrapper {
        display: block;
        position: absolute;
        bottom: 0;
        max-height: 72px;
        width: 100%;
        transform: translateY($underline-thickness);
        transition: transform 0.2s ease;
      }

      &__underline {
        display: flex;
        margin: $margin-base $margin-base * 2.5 0;

        &:before,
        &:after {
          content: '';
          display: block;
          width: 50%;
        }

        &:before {
          border-bottom: $color-primary solid $underline-thickness;
          border-left: transparent solid 6px;
        }

        &:after {
          border-top: $color-primary solid $underline-thickness;
          border-right: transparent solid 2px;
        }
      }

      &__image-wrapper {
        height: 150px;
        max-width: 100%;
        position: relative;
        overflow: hidden;
        display: block;

        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          height: 72px;
          background: linear-gradient(180deg, rgba(6, 8, 8, 0) 0%, rgba(6, 8, 8, 0.78) 90.1%);
          left: 0;
          right: 0;
        }
      }

      &__image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: none;
        min-width: 100%;
        min-height: 100%;
      }
    }

    // endregion for image type
  }
}

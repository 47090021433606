@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

$min-card-height-inner-split-module: 468px;
$media-top: $margin-base * 3.5;
$svg-height-width: 301px;
$dots-svg-right-left: $margin-base * 7.5;
$dots-svg-right-left--no-media: $margin-base * 5.875;
$text-wrapper-width-dsk: 58.4%;
$text-wrapper-width-dsk--no-media: 65.7%;
$image-wrapper-width-dsk: 49%;
$text-svg-gap-with-text-margin: calc(#{$svg-height-width} - #{$dots-svg-right-left} + #{$margin-base} * 3 + 2px);
/* prettier-ignore */
$text-svg-gap-with-text-margin--no-media: calc(#{$text-svg-gap-with-text-margin} + #{$text-wrapper-width-dsk--no-media} - #{$text-wrapper-width-dsk} - #{$margin-base}/ 2);

section.inner-split-modules {
  @include media($from: tbl-p) {
    overflow: hidden;
    padding-top: $padding-base;
    padding-bottom: $padding-base * 4;
  }
}

.inner-split-module {
  background-color: $white;
  $root: &;
  position: relative;
  margin-top: $margin-base * 5;

  @include media($from: tbl-p) {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    justify-content: flex-start;
    margin-top: 0;
    padding-bottom: $media-top;
    padding-top: $padding-base * 5.5;

    &:nth-child(2n + 1) {
      #{$root}__image-wrapper {
        left: 0;
      }

      #{$root}__text-wrapper {
        padding-left: $text-svg-gap-with-text-margin;
        padding-right: 2 * $padding-base;

        &--no-media {
          padding-left: $text-svg-gap-with-text-margin--no-media;
        }
      }

      #{$root}__dots-image-dsk {
        left: -$dots-svg-right-left;

        &--no-media {
          left: -$dots-svg-right-left--no-media;
        }
      }

      @include media($from: tbl-p) {
        flex-direction: row-reverse;
      }
    }
  }

  &__text-wrapper {
    align-self: flex-start;
    padding: $padding-base;
    background-color: $blue-dark;
    color: $white;

    @include media($from: tbl-p) {
      box-sizing: border-box;
      width: $text-wrapper-width-dsk;
      min-height: $min-card-height-inner-split-module;
      padding: (5 * $padding-base - 6) 2 * $padding-base 2 * $padding-base;
      padding-right: $text-svg-gap-with-text-margin;
      position: relative;
    }

    &--no-media {
      @include media($from: tbl-p) {
        padding-right: $text-svg-gap-with-text-margin--no-media;
        padding-bottom: $padding-base * 6.875;
        width: $text-wrapper-width-dsk--no-media;
      }

      @include media($to: tbl-p) {
        position: relative;
        z-index: $z-index-inner-split-card-text-content;
        padding-bottom: $padding-base * 2.5;
      }
    }
  }

  &__dots-image-dsk {
    display: none;

    @include media($from: tbl-p) {
      display: block;
      content: '';
      position: absolute;
      height: $svg-height-width;
      width: $svg-height-width;
      top: -$margin-base * 1.5;
      right: -$dots-svg-right-left;
      background: url('../../../../assets/brand-assets-n-hag-dots.png') no-repeat;

      &--no-media {
        right: -$dots-svg-right-left--no-media;
      }
    }
  }

  &__eyebrow {
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    margin: 0 0 $margin-base/2 0;

    @include media($from: tbl-p) {
      margin: 0 0 $margin-base * 0.5 0;
      font-size: $font-size-base;
    }
  }

  &__title {
    font-size: $font-size-large;
    margin: 0 0 $margin-base * 0.5 0;
    line-height: $line-height-medium;
    letter-spacing: $letter-spacing-medium;
    font-weight: $font-weight-bold;
    word-break: break-word;

    @include media($from: tbl-p) {
      margin: 0 0 $margin-base 0;
      letter-spacing: $letter-spacing-small;
      word-break: keep-all;
    }
  }

  &__description {
    font-size: $font-size-small;
    margin: 0 0 $margin-base / 2 0;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    @include reset-rich-text-styles;

    @include media($from: tbl-p) {
      font-size: $font-size-base;
      margin: 0 0 $margin-base * 1.5 0;
    }
  }

  &__image-wrapper {
    &::after {
      content: '';
      position: absolute;
      height: 150px;
      width: 150px;
      background: url('../../../../assets/n-hag-dots.svg');
      background-position: top right;
      transform: translateY(-48px);
      top: 0;
      right: 0;
      z-index: $z-index-base;
    }

    @include media($from: tbl-p) {
      position: absolute;
      margin-top: $media-top + 2;
      right: 0;
      width: $image-wrapper-width-dsk;
      z-index: $z-index-inner-split-card-text-content;

      &::after {
        display: none;
      }
    }
  }

  &__media-carousel {
    z-index: $z-index-inner-split-card-text-content;
  }

  &__media-height {
    @include media($from: tbl-p) {
      @include fixed-picture-aspect-ratio(664, 374);
    }

    @include media($to: tbl-p) {
      @include fixed-picture-aspect-ratio(375, 210);
    }
  }

  &__buttons {
    margin-top: 1.875 * $margin-base;
    display: flex;
    flex-direction: column;
    row-gap: 1.5 * $margin-base;
    column-gap: 1.5 * $margin-base;

    @include media($from: tbl-p) {
      flex-direction: row;
    }

    &--secondary {
      display: inline;

      span.icon-wrapper.primary {
        background-color: $white;
      }

      .cta-arrow {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: $margin-base * 0.1875;
      }

      @include media($to: tbl-p) {
        padding: 0px 0px 0px $padding-base * 1.5 !important;
      }
    }

    & > &--secondary {
      padding: $padding-base 0;
    }
  }

  &--no-media {
    position: relative;

    @include media($from: tbl-p) {
      padding-bottom: 0;
    }

    @include media($to: tbl-p) {
      &::after {
        content: '';
        position: absolute;
        height: 137px;
        width: 137px;
        background: url('../../../../assets/n-hag-dots.svg');
        background-position: top right;
        transform: translateY(-48px);
        top: 0;
        right: $margin-base * 0.75;
        z-index: $z-index-base;
      }
    }
  }
}

@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.plm-screen-wrapper {
  @media screen and (min-width: map-get($breakpoints, dsk-l)) {
    position: relative;
  }
}

.plm-series-details {
  @include max-width-halfscreen($left-color: $color-light-grey-opacity03, $right-color: $color-white);

  @include media($to: dsk-l) {
    background: $color-light-grey-opacity03;
  }

  @media screen and (min-width: map-get($breakpoints, tbl-p)) {
    margin-top: $margin-base * 3.5625;
  }

  &__container {
    @include media($from: tbl-p) {
      position: relative;
      height: 768px;
      display: grid;
      overflow: hidden;
      grid-template-columns: 28.57% 71.43%;

      .plm-series-details__tabs-wrapper {
        height: 768px;
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__popup {
    margin-top: $margin-base * 1.5;
  }

  &__tabs {
    padding: 0;
    margin: 0;

    @include media($from: tbl-p) {
      margin-top: $margin-base * 2.625;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__tabs-wrapper {
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    padding: 0;
    overflow-y: auto;
  }

  &__shadow {
    position: absolute;
    left: 0;
    z-index: $z-index-scroll-shadow;
    width: 100%;
    height: 40px;
  }

  &__shadow-light-top {
    top: 0;
    background-image: linear-gradient(to top, $background-light-gradient-overlay-shadow, rgba(10, 26, 50, 0.12));
    transition: background-image 0.3s ease;
  }

  &__shadow-light-bottom {
    bottom: 0;
    background-image: linear-gradient(to bottom, $background-light-gradient-overlay-shadow, rgba(10, 26, 50, 0.12));
    transition: background-image 0.3s ease;
  }

  &__tab {
    font-weight: $font-weight-bold;

    &--selected,
    &:last-child {
      border-bottom: 0;
    }

    @media screen and (min-width: map-get($breakpoints, tbl-p)) {
      width: auto;
      margin: 0 $margin-base 0 $margin-base * 2;
      padding: 0;
      position: relative;

      &--selected:after {
        content: '';
        position: absolute;
        top: 50%;
        right: -(2 * $margin-base);
        transform: translate(-100%, -50%);
        width: 11px;
        height: 30px;
        background-color: $blue-dark;
        z-index: $z-index-selected-tab-indicator;
      }
    }
  }

  &__tab-button {
    all: unset;
    cursor: pointer;
    width: calc(100% - 32px);
    font-weight: $font-weight-bold;
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $blue-dark;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $padding-base - 2 0;
    margin: 0 $margin-base;
    border-bottom: 1px solid $grey-light;
    box-sizing: border-box;

    &:hover {
      opacity: 1;
    }

    @media screen and (min-width: map-get($breakpoints, tbl-p)) {
      height: auto;
      margin: 0;
      position: relative;
      opacity: 0.5;
      border: none;
      padding: 0;

      &--selected {
        text-decoration: none;
        opacity: 1;
      }

      h3 {
        padding: 0;
        font-size: $font-size-small;
        line-height: $line-height-small;
        transition: font-size 0.3s;
        margin: 0 0 $margin-base * 1.375;
      }
    }

    &--selected {
      opacity: 1;
      border: 0;

      h3 {
        font-size: $font-size-base;
        padding: 0;
        margin: 0 0 $margin-base * 1.375;
      }
    }

    .icon-wrapper {
      background-color: $color-primary;
    }
  }

  &__tab-title {
    margin: 0;
    font-size: $font-size-small;
  }

  &__component {
    &-section {
      position: relative;
    }

    &-wrapper {
      position: absolute;
      width: 100%;
      background-color: $white;
      z-index: $z-index-series-detail-component-wrapper;
    }
  }

  &__component--hidden {
    display: none;
  }

  &__header {
    @media screen and (min-width: map-get($breakpoints, tbl-p)) {
      display: flex;
      align-items: center;
      padding: ($padding-base * 5) $padding-base * 2 0;
    }
  }

  &__title {
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
    line-height: $line-height-medium;
    letter-spacing: $letter-spacing-medium;
    color: $blue-dark;
    margin: 0;
    padding: $padding-base * 3 $padding-base $padding-base * 1.5 $padding-base;

    @include media($from: tbl-p) {
      padding: 0;
    }

    @media screen and (min-width: map-get($breakpoints, tbl-p)) {
      font-size: $font-size-larger;
      line-height: $line-height-large;
      letter-spacing: $letter-spacing-large;
    }
  }
}

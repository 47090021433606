@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.dealer-locator-landing-toast-msg {
    padding: $padding-base $padding-base * 2.25;
    box-shadow: $shadow-pastel;
    position: absolute;
    max-width: 343px;
    box-sizing: border-box;
    z-index: 0;
    background-color: $white;
    top: $padding-base * 2.5;
    left: 50%;
    transform: translateX(-50%);
    color: $color-primary-blue;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    font-size: $font-size-small;
    transition: all 0.3s ease-in;
    &--show {
      @include fade-in-animation;
      opacity: 1;
      z-index: $z-index-medium;
    }

    &--hide {
      @include fade-out-animation;
      opacity: 0;
      z-index: 0;
      transition: z-index 0.3s ease-in;
    }

    &__close {
      top: $padding-base;
      right: $padding-base;
      position: absolute;
      cursor: pointer;
    }

    &__title {
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }
  }
@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.news-listing-with-filter {
  &__reset {
    display: flex;
    column-gap: $margin-base * 0.5;
    cursor: pointer;
    font-size: $font-size-base;
    font-weight: bold;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $color-primary-blue;
    align-items: center;
    text-transform: uppercase;
    @include media($to: dsk) {
      margin-top: $margin-base * 0.625;
      margin-left: auto;
    }

    &:hover {
      text-decoration: underline;
      text-underline-offset: 6px;
    }
  }

  &--dsk {
    @include max-width-fullscreen;
    background-color: $color-light-grey-opacity03;
    padding: $padding-base * 0.5 0;
    display: flex;
    flex-direction: column;
    row-gap: $margin-base * 1.5;
    @include media($from: dsk-l) {
      background-color: initial;
      &::before {
        background-color: $color-light-grey-opacity03;
      }
    }

    .news-filter {
      display: flex;
      align-items: center;
      flex-wrap: wrap-reverse;
      justify-content: space-between;
      padding: 0 $padding-base * 5 0 $padding-base * 2;

      &__dropdown-wrapper {
        display: flex;
      }
    }
  }
}

@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

$image-height-3column: 261px;
$image-height-4column: 185px;

$text-max-height: 168px;

.product-plm-solutions-card {
  $root: &;
  position: relative;
  margin-bottom: $margin-base * 2.5;
  background-color: $color-white;
  display: flex;
  flex-direction: column;

  @include media($from: tbl-p) {
    margin-bottom: $margin-base * 2.5;
  }

  &:hover {
    @include media($from: tbl-p) {
      z-index: $z-index-plm-solution-card-hover;

      #{$root}__media-bg-image {
        transform: translate(-32px, -32px);
        opacity: 1;
      }
    }

    #{$root}__body {
      @include media($from: tbl-p) {
        position: absolute;
        z-index: 1;
        max-height: unset;
        height: unset;
        left: -$margin-base * 2;
        right: -$margin-base * 2;
        padding: 0 $padding-base * 2.5 $padding-base * 2;
        min-height: 100%;
      }
    }

    #{$root}__content-text {
      @include media($from: tbl-p) {
        // min-height: $text-max-height;
        height: unset;
        max-height: fit-content;
        padding: $padding-base 0 0;

        &:before {
          content: unset;
        }
      }
    }

    #{$root}__description,
    #{$root}__title {
      @include media($from: tbl-p) {
        display: initial;
        overflow: unset;
        white-space: initial;
      }
    }
  }

  &--white {
    background-color: $color-white;

    #{$root}__media,
    #{$root}__body,
    #{$root}__buttons {
      background-color: $color-white;
    }
  }

  // start media section style

  &__media {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__media-bg-image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $z-index-background;
    transition: 0.3s;
    opacity: 0;
    height: 235px;
  }

  &__media-picture {
    object-fit: cover;
    max-width: 100%;
    width: 100%;

    @include fixed-picture-aspect-ratio(375, 211);

    @include media($to: tbl-p) {
      width: 100vw;
    }

    @include media($from: tbl-p) {
      @include fixed-picture-aspect-ratio(463, 261);
    }

    @include media($from: dsk) {
      @include fixed-picture-aspect-ratio(328, 185);
    }
  }

  // end media section style

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 $padding-base;
    height: 100%;

    @include media($from: tbl-p) {
      padding: 0 $padding-base / 2;
      // height: calc(100% - #{$image-height-4column});
      // max-height: calc(100% - #{$image-height-4column});

      &--no-image {
        height: unset;
        max-height: unset;
      }
    }

    &--wrapper {
      flex-grow: 1;
      position: relative;
    }

    &--darkBlue {
      color: $blue-dark;
    }
  }

  &__content-text {
    @include media($from: tbl-p) {
      display: flex;
      flex-direction: column;
      // height: $text-max-height;
      // max-height: $text-max-height;
      padding: $padding-base 0 0;
      overflow: hidden;
      position: relative;
      box-sizing: border-box;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 45%);
      }
    }

    #{$root}__text {
      // min-height: 150px;
      height: auto;
      max-height: unset;
      overflow: initial;

      &:before {
        content: unset;
      }
    }
  }

  &__text {
    @include media($from: tbl-p) {
      display: flex;
      flex-direction: column;
      height: 150px;
      max-height: 150px;
      overflow: hidden;
      margin-bottom: $margin-base * 1.5;
      position: relative;
      padding: 0 $padding-base * 2.5;
    }
  }

  &__title {
    font-size: $font-size-medium;
    line-height: $line-height-small;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-small;
    padding: $padding-base 0;
    word-wrap: break-word;

    @include media($from: tbl-p) {
      padding: 0 0 $padding-base / 2;
    }
  }

  &__description {
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    white-space: normal;
    transition: 0.3s;
    padding: 0;

    @include media($from: tbl-p) {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
  }

  &__content-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    padding-top: $padding-base * 1.5;
  }
}

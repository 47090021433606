@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

@mixin cta-arrow {
  .icon-size {
    width: 20px;
    height: 20px;
    padding-left: $padding-base / 2;
  }
}

.special-offers-editorial-wrapper {
  background-color: $white;
}

.special-offers-editorial {
  padding: $padding-base * 6 0 0 $padding-base;
  position: relative;

  @include max-width;

  @include media($from: tbl-p) {
    padding: $padding-base * 5 0 0 $padding-base * 4;
  }

  .heading {
    @include media($to: tbl-p) {
      padding-right: $padding-base;
    }
  }
  &__title-button {
    margin: 0 0 $margin-base * 1.5;
    display: flex;
    align-items: baseline;
    column-gap: $margin-base * 1.5;

    @include media($to: tbl-p) {
      flex-direction: column;
      row-gap: $margin-base;
    }
  }

  &__ml-24 {
    @include media($to: tbl-p) {
      margin-left: $margin-base * 1.5;
    }
  }

  &--without-eyebrow {
    @include media($from: tbl-p) {
      padding-top: $padding-base * 7;
    }
  }

  & &__title-button {
    & .s-blue,
    & .s-white {
      padding: 0;
    }
  }

  &__pop-up {
    flex-grow: 1;
  }

  &__cards {
    margin-top: $margin-base + 10;
  }

  &__slide {
    position: relative;
    height: 100%;
  }

  &:hover {
    @include media($from: dsk) {
      .custom-arrow {
        display: flex;
      }
    }
  }

  .special-offers-card {
    margin-top: $margin-base * 2;
    margin-right: $margin-base;

    &--large {
      width: 300px;
    }

    &--small {
      width: 328px;
    }

    @include media($from: tbl-p) {
      margin-right: $margin-base * 2;
      margin-left: $margin-base * 2;
      padding-bottom: $padding-base / 2;

      &--large {
        width: 664px;
      }

      &--small {
        width: 392px;
      }
    }

    @include media($to: tbl-p) {
      margin-top: 0;
      margin-bottom: 0;
    }

    & .card-with-header-image {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }

    &__image-wrapper {
      position: relative;

      &--video {
        min-height: 185px;

        @include media($to: tbl-l) {
          min-height: 164px;
        }
      }
    }

    &__video-play-button {
      position: absolute;
      top: 50%;
      z-index: $z-index-carousel-video-play-button;
      padding: $padding-base * 0.75;
      transform: translate(-50%, -50%);
      left: 50%;
      cursor: pointer;
      background-color: $blue-dark;
      --pfe-theme--color--ui-complement: #{$white};

      &:hover {
        background: #{$color-primary};
      }

      @include media($to: tbl-p) {
        padding: $padding-base/2;
      }

      & .icon-wrapper {
        background-color: $white;
      }
    }

    &__border {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
    }

    &__title {
      display: flex;
      flex-direction: column;
      min-height: 74px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: $margin-base $margin-base / 2 0 $margin-base / 2;
    }

    &__text {
      text-decoration: underline;
      font-weight: $font-weight-bold;
      font-size: $font-size-small;
      color: $color-primary;
      cursor: pointer;
      margin: $margin-base / 2 $margin-base;
    }
  }

  &__singleCard {
    padding: 0 $padding-base * 1.8125 $padding-base * 4 $padding-base * 0.8125;

    @include media($from: tbl-p) {
      padding: 0 $padding-base * 4 $padding-base * 4 0;
    }

    @include media($from: tbl-l) {
      padding: $padding-base * 2 $padding-base * 4 $padding-base * 4 $padding-base * 2;
    }

    .special-offers-card {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      border: none;
      padding: 0;
      margin: 0;

      @include media($from: tbl-l) {
        margin: 0;
        padding: 0;
      }

      .card-with-header-image {
        @include media($to: tbl-l) {
          padding: $padding-base * 1.375 0 0;
        }
      }

      & .slide-card {
        @include media($from: tbl-l) {
          grid-template-columns: 549px 1fr;
          display: grid;
          min-height: 311px;

          &__content {
            min-height: 120px;
          }

          &__button {
            margin-left: 0;
            margin-bottom: $margin-base * 1.5;
          }

          img {
            object-fit: cover;
            min-height: 311px;
          }
        }

        @include media($from: dsk) {
          grid-template-columns: 688px 1fr;
        }

        @include media($from: dsk-m) {
          grid-template-columns: 1008px 1fr;
        }

        &__image {
          width: 100%;
          height: 168px;
          display: block;
          margin-top: 0;
          position: absolute;

          @include media($from: mbl) {
            width: 100%;
            height: 100%;
          }

          @include media($to: tbl-l) {
            position: relative;
            max-height: 360px;
          }
        }

        &__button {
          margin: 0 -1 * ($margin-base * 0.5) $margin-base * 1.5 0;

          & span.link-with-arrow {
            padding: 0 $padding-base * 1.5;
            margin: 0;
            vertical-align: middle;
            display: flex;
          }

          & span.link-with-arrow-icon {
            margin: 0;
          }
        }
      }

      .btn-primary-yellow {
        padding: $padding-base * 0.8125 $padding-base * 1.5;
      }

      &__border {
        border-bottom: 2px solid $grey-light;
        margin: 0 $margin-base / 2;

        @include media($from: tbl-l) {
          margin-left: $margin-base * 3;
        }
      }

      &__title {
        margin: $margin-base + 2 0 0;

        @include media($from: tbl-p) {
          margin: $margin-base + 2 0 $margin-base * 1.5;
        }

        @include media($from: tbl-l) {
          margin: $margin-base 0 $margin-base * 1.3125 0;
        }
      }
    }
  }

  .custom-arrow {
    display: none;
    position: absolute;
    top: 30%;
    border-radius: 0;
    width: 32px;
    height: 32px;
    background: $black;

    &__icon {
      background-color: $white;
    }

    &.slick-next,
    &.slick-prev {
      background: $black;

      &::before {
        content: none;
      }
    }

    &--right {
      right: calc(4% + 1px);
      margin-right: $margin-base * 5;
    }

    &--left {
      left: calc(4% + 1px);
    }
  }

  .slick-initialized .slick-slide {
    display: block;
    margin-bottom: $margin-base * 3.1;
    margin-right: $margin-base;

    @include media($to: tbl-p) {
      margin-bottom: $margin-base * 0.75;
    }
  }

  .slick-current::before {
    background: none;
  }

  .slick-slider {
    padding-top: 0;
    background: $white;

    @include media($to: tbl-p) {
      margin-bottom: 0;
    }

    .carousel-arrow {
      opacity: 0;
    }

    &:hover {
      .carousel-arrow {
        opacity: 1;
      }
    }
  }

  .slick-track {
    padding-bottom: $padding-base * 0.75;
  }

  .slick-dots {
    padding-right: 1rem;
    margin-bottom: $margin-base * 1.5;

    .slick-active {
      padding: 0;
      width: 10px !important;
      height: 10px !important;
    }

    li {
      padding: 1px;
      width: 8px !important;
      height: 8px !important;
    }
  }

  .slick-active {
    .slick-dots {
      &__item {
        @include media($to: tbl-p) {
          width: var(--slick-width-active) !important;
        }
      }
    }
  }

  .card-label {
    padding: $padding-base / 4 $padding-base / 2 + 2px $padding-base / 4 - 1px $padding-base / 2 + 3px;
    z-index: 10;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    width: auto;
    max-width: 50%;
    right: $padding-base;
    top: $padding-base;
    line-height: 1.71;
    font-size: $font-size-small;
    background-color: $grey-light;
    box-shadow: 0px 4px 25px $color-input-shadow;
    cursor: default;

    a {
      color: $black;
      font-weight: $font-weight-bold;
      text-decoration: none;
    }
  }

  .slide-card {
    --pfe-card--BorderRadius: 0px;

    &--regular {
      height: 376px;

      @include media($from: tbl-p) {
        padding: $padding-base * 4 $padding-base * 2.5;
      }
    }

    &--strech {
      height: 100%;
    }

    padding: 0;

    &__image {
      height: 164px;
      width: 300px;
      object-fit: cover;
      margin-top: 0;

      @include media($from: tbl-p) {
        height: 185px;
        width: 328px;
        object-fit: cover;
      }

      @include media($to: tbl-p) {
        margin-top: 0;
      }
    }

    img {
      width: 100%;
    }

    &__text {
      line-height: $line-height-small;
      overflow: hidden;
      font-size: $font-size-small;
      white-space: normal;
      text-overflow: ellipsis;
      max-width: 100%;
      color: $color-dark-blue;
      letter-spacing: $letter-spacing-base;

      p {
        margin-bottom: $margin-base * 0.125;
      }

      &--title {
        font-weight: $font-weight-bold;
        overflow: hidden;
        white-space: nowrap;
        color: $color-dark-blue;
        margin-right: $margin-base * 0.5;
        margin-bottom: $margin-base * 0.5;
        font-size: $font-size-base;
        line-height: $line-height-small;
        letter-spacing: $letter-spacing-base;
      }

      @include media($to: tbl-p) {
        white-space: normal;
      }

      &--inline {
        white-space: normal;
        font-size: $font-size-medium;
        line-height: 1.44;
        letter-spacing: 0.4px;

        @include media($to: tbl-p) {
          font-size: $font-size-base;
          line-height: $line-height-small;
          letter-spacing: 0;
        }
      }

      &--overflow-unset {
        overflow: inherit;
      }

      &--strech {
        font-size: $font-size-small;
        display: flex;
        margin: $margin-base;
      }
    }

    &__button-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      @include media($to: tbl-p) {
        margin-top: $margin-base * 1.5;
      }
    }

    &__button {
      margin: 0 $margin-base / 2;
      letter-spacing: 1px;
      display: inline-block;
      text-transform: uppercase;
      text-decoration: none;
      text-align: left;

      span.link-with-arrow {
        display: inline-block;
        padding-top: $padding-base * 1.5;

        &:hover {
          .link-with-arrow-icon {
            margin-left: $margin-base / 2;
            transition: all 0.1s ease-in;
          }
        }

        &-icon {
          background-color: $color-primary-blue;
          display: inline-block;
          vertical-align: middle;
          margin-bottom: $margin-base * 0.1875;
        }

        @include media($from: tbl-p) {
          padding-top: 0;
          padding-left: $padding-base * 1.75;
        }

        @include media($to: tbl-p) {
          margin-left: $margin-base * 1.5;
          margin-bottom: $margin-base * 0.89;
        }

        a {
          color: $color-primary-blue;
        }

        @include cta-arrow;
      }

      @include media($from: tbl-p) {
        width: auto;
        margin: $margin-base * 0.75 $margin-base $margin-base * 0.75 $margin-base;
      }
    }
  }

  .carousel-dots-wrapper {
    margin-top: 0;
  }

  &__footer {
    height: 0px;
    background-color: $color-white;

    @include media($from: tbl-p) {
      height: 50px;
    }
  }
}

@import '../_variables.scss';
@import '../_mixins.scss';

body.body--with-spec-model {
  // prevent scrolling
  overflow: hidden;
}
.model-details-specification-table-header {
  padding: $padding-base * 3 $padding-base $padding-base * 1.5 $padding-base;
  background: $color-light-gray-opacity03;
  @include max-width-fullscreen;
  @include media($from: tbl-p) {
    min-width: auto;
    padding: $padding-base * 5 $padding-base * 2 $padding-base * 2 $padding-base * 2;
  }

  .info-tooltip-sep__children {
    min-height: 145px;
  }

  &__eyebrow {
    color: $grey-medium;
    font-size: $font-size-small;
    margin: 0 0 $margin-base/2 0;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    @include media($from: tbl-p) {
      margin: 0 0 $margin-base/2 0;

      font-size: $font-size-base;
    }
  }

  &__title-link-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: $margin-base * 0.375;
    margin-bottom: $margin-base * 1.25;
    & > .button.s-blue {
      padding: 0;
      margin-top: $margin-base;
      line-height: $line-height-small;
    }
    @include media($from: tbl-p) {
      align-items: baseline;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: $margin-base * 2.125;
    }
  }

  &__title {
    font-weight: 700;
    font-size: $font-size-large;
    line-height: $line-height-medium;
    letter-spacing: $letter-spacing-medium;
    margin: 0;
    @include media($from: tbl-p) {
      font-size: $font-size-larger;
      line-height: $line-height-large;
      margin: 0 $margin-base * 1.5 0 0;
      letter-spacing: $letter-spacing-large;
    }
  }

  &__flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  &__links-wrapper {
    margin-top: $margin-base * 1.25;
    line-height: $line-height-large - 2;
    @include media($from: tbl-p) {
      display: flex;
      margin-top: $margin-base * 2.125;
      justify-content: right;
      padding-right: $margin-base * 3;
    }
  }

  &__link {
    line-height: $line-height-small;
    color: $color-primary;
    font-weight: 700;
    letter-spacing: $letter-spacing-base;
    a {
      color: $black !important;
      line-height: $line-height-base;
      padding: $padding-base * 0.1875 0;
      --pfe-broadcasted--link: #{$black};
      --pfe-broadcasted--link--hover: #{$black};
      --pfe-broadcasted--link-decoration--hover: none;
      box-sizing: border-box;
      text-decoration: none;
    }
    &--button,
    &--print,
    &--export {
      all: unset;
      border: 0;
      font-size: $font-size-base;
      padding: 0;
      margin: 0;
      font-weight: 700;
      color: $color-primary;
      background: transparent;
      letter-spacing: 0;
      cursor: pointer;
      & .icon-wrapper {
        background-color: $color-primary-blue;
      }
    }
    &--button {
      .table-expand-icon {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: -3px;
        margin-left: $margin-base * 0.5625;
        background-color: $color-primary;
      }
      @include media($from: tbl-p) {
        margin-right: $margin-base * 1.75;
        letter-spacing: $letter-spacing-base;
      }
    }

    &--print {
      cursor: pointer;
      margin-left: $margin-base * 2;
      &__text {
        margin-right: $margin-base / 2;
        letter-spacing: $letter-spacing-base;
      }
      &__icon {
        display: inline-block;
      }
      &:hover {
        text-decoration: underline;
      }
    }

    &--export {
      margin-right: $margin-base * 2;
    }

    &-icon {
      position: relative;
      top: 4px;
      margin-left: $margin-base / 2;
      height: 20px;
      display: inline-block;
    }
  }

  &__last-row {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-top: $margin-base * 2;
    @include media($from: tbl-p) {
      margin-top: $margin-base * 2.8125;
    }
  }

  &__arrow-wrapper {
    display: none;
    @include media($from: tbl-p) {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin-right: $margin-base * 3;
    }
  }

  &__arrow {
    background: $blue-dark;
    height: 40px;
    width: 40px;
    margin-left: $margin-base * 1.5;
    box-shadow: 0 4px 25px 0 rgba(126, 126, 126, 0.25);
    cursor: pointer;
    &:hover {
      background: $color-primary-blue;
      box-shadow: 0px 10px 20px $color-arrow-hover-box-shadow;
    }
    &--left {
      .table-arrow-left {
        position: relative;
        top: 4px;
        left: 3px;
        background-color: $white;
      }
    }
    &--right {
      .table-arrow-right {
        position: relative;
        top: 4px;
        left: 5px;
        background-color: $white;
      }
    }
  }

  &__search-input {
    @include media($from: tbl-p) {
      display: inline-block;
    }
    &__container {
      @media (min-width: map-get($breakpoints, 'tbl-p')) and (max-width: 932px) {
        min-width: 350px;
      }
      display: inline-flex;
      align-items: center;
      min-width: 528px;
      background-color: $white;
      box-shadow: 0 4px 25px $color-button-shadow;
      padding-left: $padding-base;
      min-height: 50px;
      box-sizing: border-box;
      @include media($to: tbl-p) {
        width: 100%;
        min-width: auto;
        margin: $margin-base * 1.5 0 0 0;
      }
      .table-search-clear-icon {
        margin-right: $margin-base;
        cursor: pointer;
        background-color: $color-medium-grey;
      }
    }
    &__inner-container {
      flex-grow: 1;
      margin-left: $margin-base / 4;
    }
    &__label {
      font-size: $font-size-smaller;
      line-height: $line-height-smaller;
      font-weight: normal;
      color: $color-dark-grey;
    }
    &__input {
      font-size: $font-size-small;
      font-weight: normal;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
      border: none;
      width: 100%;
      padding: 0;
      color: $color-dark-grey;
    }
    input:focus {
      outline: none;
    }
  }
}

.model-detail-specification-table {
  width: 100%;
  background: $white;
  @include max-width-fullscreen;
  overflow-x: scroll;

  @include media($from: tbl-p) {
    overflow: hidden;
  }

  &__notes {
    font-size: $font-size-smaller;
    line-height: $line-height-smaller;
    color: $color-dark-blue;
    padding: $padding-base $padding-base * 2 $padding-base * 4;
    background-color: $white;

    @include media($to: mbl) {
      padding: $padding-base $padding-base $padding-base * 2.5;
    }

    &-wrapper {
      @include media($from: dsk-l) {
        @include max-width;
        width: 100%;
        left: 0;
        right: 0;
      }
    }
  }

  &__table-tag {
    position: relative;
    background: $color-white;
    height: 1px;
    word-break: break-word;
    @include media($from: tbl-p) {
      overflow-x: visible;
      left: var(--grid-scroll-by, 0);
      transition: left 0.5s ease-in-out;
    }
  }

  &__spec-category-row {
    font-weight: 700;
    cursor: pointer;

    &__inner-box {
      color: $blue-dark;
      padding: $padding-base * 1.5 0;
      margin: 0 0 0 $margin-base * 2;
      z-index: $z-index-specs-table-elevate;
      font-size: $font-size-base;
      letter-spacing: $letter-spacing-base;
      line-height: $line-height-small;

      &--border-visible {
        border-bottom: 1px solid $color-light-gray-opacity05;
      }

      @include media($to: tbl-p) {
        padding: $padding-base 0;
        font-size: $font-size-small;
        margin-left: $margin-base;
      }

      //   icon styles
      .table-plus-icon,
      .table-minus-icon {
        position: relative;
        top: -2px;
        display: inline-block;
        vertical-align: middle;
        margin-right: $margin-base;
        background-color: $color-dark-blue;
      }
    }
  }

  &__left-most-cell {
    z-index: $z-index-specs-table-elevate;
    position: relative;
    background: $white;
    box-sizing: border-box;
    height: 100%;

    @include media($from: tbl-p) {
      left: var(--grid-left-cells-move-by, 0);
      transition: left 0.5s ease-in-out;
      &--omit-transition {
        transition: none;
      }
    }
    &--border-hidden {
      border-bottom: 0;
    }
  }

  &__spec-category-row-cell {
    background: $color-light-gray-opacity05;
  }

  &__left-most-empty-cell {
    width: calc(var(--grid-first-column-width, 180px));
    left: var(--grid-left-cells-move-by, 0);
    @include media($from: tbl-p) {
      width: calc(var(--grid-first-column-width, 424px));
    }
  }

  &__left-side-accordion-spec-title {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: $margin-base;
    padding: $padding-base * 0.75 $padding-base $padding-base * 0.75 0;
    width: calc(var(--grid-first-column-width, 180px) - #{$margin-base});
    height: 100%;
    z-index: $z-index-specs-table-elevate;
    background: $color-light-gray-opacity02;
    color: $color-dark-blue;
    font-size: $font-size-smaller;
    line-height: $line-height-small;
    font-weight: 600;
    letter-spacing: 0.8px;
    box-sizing: border-box;
    border-bottom: 1px solid $color-light-grey;
    @include media($from: tbl-p) {
      width: calc(var(--grid-first-column-width, 424px) - #{$margin-base * 2});
      min-height: 77px;
      margin-left: $margin-base * 2;
      font-size: $font-size-small;
    }

    &--border-hidden {
      border-bottom: 0;
    }

    &:before {
      content: '';
      position: absolute;
      left: -#{$margin-base};
      width: $margin-base;
      height: 100%;
      background: $color-light-gray-opacity02;
      @include media($from: tbl-p) {
        left: -#{$margin-base * 2};
        width: $margin-base * 2;
      }
    }

    &--search-item {
      white-space: pre-wrap;
    }
  }

  &__tooltip-wrapper {
    padding: $padding-base;
    min-width: 200px;
  }

  &__tooltip-title,
  &__tooltip-body {
    color: $color-dark-blue;
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
  }

  &__tooltip-title {
    margin: 0;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__table-cell {
    border-bottom: 1px solid $color-light-grey;
    vertical-align: middle;

    &--even {
      background: $white;
    }

    &--odd {
      background: $color-light-gray-opacity02;
    }

    &--border-hidden {
      border: 0;
    }
  }

  &__search-no-match {
    text-align: center;
    color: $black;
    padding: $padding-base 0 $padding-base * 2.5 0;
    background: $white;
    overflow-x: hidden;
    margin: 0;
    .table-info-icon {
      margin: 0 auto;
    }
    &-title {
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
      margin: $margin-base 0 0 0;
      color: $blue-dark;
      @include media($to: tbl-p) {
        font-size: $font-size-small;
      }
    }

    &-desc {
      font-size: $font-size-base;
      margin: $margin-base * 2 0 0 0;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
      color: $blue-dark;
      @include media($to: tbl-p) {
        margin: $margin-base * 1.5 $margin-base / 2 0;
        font-size: $font-size-small;
      }
    }
  }

  &__spec {
    color: $blue-dark;
    padding: $padding-base;
    width: var(--grid-column-width, 120px);
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 100%;

    &--last-row {
      border: 0;
    }

    @include media($from: tbl-p) {
      padding: $padding-base $padding-base * 0.75 $padding-base $padding-base * 0.75;
      width: var(--grid-column-width, 272px);
      font-size: $font-size-small;
    }
  }

  &__cell-animation {
    opacity: 1;
    box-sizing: border-box;
    transition: opacity 0.3s ease, min-height 0.3s ease, left 0.5s ease-in-out, margin-top 0.4s ease, margin-bottom 0.4s ease,
      padding-top 0.4s ease, padding-bottom 0.4s ease;

    &--show-animation {
      animation: fadeInTableCellAnimation 0.4s ease;
    }

    @keyframes fadeInTableCellAnimation {
      0% {
        height: 0;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        min-height: 0;
        border: 0;
        opacity: 0;
      }

      100% {
        height: 100%;
        opacity: 1;
      }
    }

    &--hide {
      height: 0;
      min-height: 0;
      opacity: 0;
      overflow: hidden;
      border: 0;
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__text {
    display: inline;
    margin: 0;
    font-family: inherit;
    &--highlight {
      background: $blue-pastel;
    }
  }

  &__col-heading-dropdown__wrapper {
    position: relative;
    vertical-align: baseline;
  }

  &__col-heading {
    display: grid;
    grid-template-columns: 1fr;
    color: $color-primary;
    background: $white;
    padding: $padding-base/2 0 $padding-base * 0.25;
    width: var(--grid-column-width, 120px);
    height: 100%;
    letter-spacing: $letter-spacing-base;
    font-weight: 700;
    font-size: $font-size-small;
    line-height: $line-height-small;
    box-sizing: border-box;

    .table-dropdown-icon {
      background-color: $color-primary;
    }

    &__title-spec {
      @include media($to: tbl-p) {
        text-align: start;
      }
    }

    &--clickable {
      cursor: pointer;
      padding: $padding-base * 2.125 $padding-base * 0.75 $padding-base $padding-base * 0.75 !important;
      text-align: left;
    }

    @include media($from: tbl-p) {
      grid-template-columns: auto 1fr;
      width: var(--grid-column-width, 272px);
      padding: $padding-base * 2.125 0 $padding-base $padding-base;
      font-size: $font-size-base;
      letter-spacing: $letter-spacing-base;
      .table-dropdown-icon {
        margin-left: $margin-base;
      }
    }
  }

  &__col-dropdown {
    background-color: $white;
    box-shadow: 0 0 30px -10px $color-pastel;
    z-index: $z-index-dropdown;
    cursor: pointer;

    @include media($to: tbl-p) {
      width: 100vw;
      height: 100vh;
      position: fixed;
      overflow: auto;
      top: 0;
      z-index: $z-index-table-dropdown;
    }

    &--absolute {
      position: absolute;
      width: 100%;
      top: 100%;
      left: 0;
    }

    &--fixed {
      @include media($from: tbl-p) {
        position: fixed;
        top: var(--table-dropdown-top);
        left: var(--table-dropdown-left);
        width: var(--table-dropdown-width);
      }
    }
  }

  &__col-dropdown-item-wrapper {
    padding: 0px $padding-base;

    &:hover {
      background-color: $color-light-gray-opacity03;
    }
    @include media($from: tbl-p) {
      &:last-child {
        .model-detail-specification-table__col-dropdown-item {
          border-bottom: none;
        }
      }
    }
    &--with-title-and-icon {
      letter-spacing: $letter-spacing-base;
      background-color: $grey-light;
      position: relative;
      padding: 0px $padding-base * 2.5 0px $padding-base * 2.5;

      @include media($from: tbl-p) {
        display: none;
      }
      .table-dropdown-close-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: $padding-base;
      }
    }
  }

  &__col-dropdown-item {
    box-sizing: content-box;
    border-bottom: 1px solid $grey-light;
    font-weight: normal;
    font-size: $font-size-small;
    color: $blue-dark;
    line-height: $line-height-small;
    text-align: center;
    padding: $padding-base 0px;
    letter-spacing: $letter-spacing-base;

    @include media($from: tbl-p) {
      text-align: left;
      border-bottom-color: $color-light-grey;
      padding: 0.5 * $padding-base 0px;
    }

    &--with-title-and-icon {
      flex-grow: 1;
      border-bottom: none;
      font-weight: bold;
    }
  }

  &__fixed-col-heading-wrapper {
    position: relative;
    word-break: break-word;
    &--hide {
      height: 0;
      opacity: 0;
      visibility: hidden;
    }
  }

  &__fixed-col-heading {
    display: grid;
    grid-template-columns: 0 var(--grid-first-column-width, 180px) repeat(var(--grid-columns, 0), 120px) 0;
    position: relative;
    width: 100%;
    left: var(--grid-fixed-col-heads-move-by, 0);
    background: $white;
    @include media($from: tbl-p) {
      grid-template-columns:
        var(--grid-outside-space-halved, 0px) var(--grid-first-column-width, 424px) repeat(
          var(--grid-columns, 0),
          var(--grid-column-width, 272px)
        )
        var(--grid-outside-space-halved, 0px);
    }

    &--box-shadow-el {
      position: absolute;
      width: 100%;
      max-width: 1680px;
      top: 0;
      left: var(--grid-outside-space-halved, 0px);
      bottom: 0;
      box-shadow: 0 4px 20px -10px $color-pastel;
    }

    &--left-cover,
    &--right-cover {
      position: absolute;
      background: $color-light-gray-opacity03;
      z-index: $z-index-specs-table-col-cover;
      top: 0;
      bottom: 0;
    }

    &--left-cover {
      width: var(--grid-outside-space-halved, 0px);
      left: 0;
    }

    &--right-cover {
      width: var(--grid-outside-space-halved, 0px);
      right: 0;
    }

    @include media($from: dsk-l) {
      &--left-cover,
      &--right-cover {
        bottom: -1px;
        width: calc(var(--grid-outside-space-halved, 0px) + 1px);
      }
      &--left-cover {
        left: -1px;
      }
      &--right-cover {
        right: -1px;
      }
    }
  }
}

@media screen, speech {
  .model-detail-specification-table-print-wrapper {
    display: none;
  }
}

.model-details-specification-table-wrapper {
  background: $color-light-gray-opacity03;

  &--white-bg {
    background-color: $color-white;
  }
}

.info-tooltip-sep__children {
  min-height: 145px;
}

@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.product-download-brochure-listing-container {
  @include max-width-fullscreen;
  background-color: $color-white;

  &__min-width-none {
    min-width: unset;
  }

  &__label-container {
    padding: $padding-base * 5 $padding-base * 2 $padding-base * 2 $padding-base * 2;

    &--title {
      font-size: $font-size-larger;
      line-height: $line-height-large;
      letter-spacing: $letter-spacing-large;
      font-weight: $font-weight-bold;
      color: $blue-dark;
    }
  }

  &__card-container {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    column-gap: $margin-base;
    row-gap: $margin-base * 3;
    padding: $padding-base * 5.5 $padding-base * 2 $padding-base * 4 $padding-base * 2;
    @include media($to: dsk) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include media($to: tbl-p) {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      row-gap: $margin-base * 2.5;
      padding: $padding-base 0 0;
    }
  }

  .pagination__container {
    padding-left: 0;
  }

  .download-brochure_card {
    &__image-container {
      position: relative;
    }

    &__badge {
      position: absolute;
      right: 0px;
      z-index: 1;
      font-size: $font-size-smaller;
      line-height: $line-height-smaller;
      color: $black;
      padding: $padding-base / 4 $padding-base / 1.5;
      background-color: $grey-light;
      text-transform: uppercase;

      * {
        margin: 0;
      }
    }
  }
}

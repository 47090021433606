@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.series-overview-carousel {
  $parent: &;
  position: relative;
  overflow: hidden;

  &__wrapper {
    position: relative;
    height: 100%;
    z-index: $z-index-image-overview-carousel;
  }

  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide>div {
    height: 100%;
  }

  &__hide-carousel-dots {
    .carousel-dots-wrapper {
      display: none;
    }
  }

  &__slide-wrapper {
    position: relative;
    height: 100%;
    width: 100%;

    img {
      display: block;
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
    }

    @include media($to: tbl-p) {
      box-sizing: border-box;
      @include fixed-picture-aspect-ratio(1179, 767);
      width: 100%;
    }
  }

  &__arrow-control {
    display: block;
    border: none;
    outline: none;
    position: absolute;
    padding: $padding-base/2;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 2px 20px $color-button-shadow;
    background-color: $blue-dark;
    --pfe-theme--color--ui-complement: #{$white};

    &:hover {
      --pfe-theme--color--ui-complement: #{$white};
      background: #{$color-primary};
      box-shadow: 0 2px 20px $color-button-shadow;
    }

    &--previous {
      z-index: $z-index-series-overview-nav-section-arrow;
      left: 30px;
    }

    &--next {
      z-index: $z-index-series-overview-nav-section-arrow;
      right: 30px;
    }

    @include media($from: tbl-p) {
      &--previous {
        z-index: $z-index-series-overview-nav-section-arrow;
        left: 80px;
      }

      &--next {
        z-index: $z-index-series-overview-nav-section-arrow;
        right: 80px;
      }
    }
  }

  &__indicators {
    display: flex;
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100vw;
    z-index: $z-index-series-overview-indicators;

    &__item {
      display: inline-block;
      width: 32px;
      height: 3px;
      background: rgba(255, 255, 255, 0.6);
      margin: 0 $margin-base / 2;

      &:after {
        background-color: $white;
        content: '';
        display: block;
        height: 100%;
        width: 0%;
      }

      &--active {
        width: 64px;

        &:after {
          width: 100%;
        }
      }
    }
  }

  &__animate-icons {
    opacity: 1;
    transition: opacity 1s ease-in;

    &--animate-fade {
      opacity: 0;
    }

    @include media($to: tbl-p) {
      opacity: 0;
      pointer-events: none;

      &--animate-fade {
        @include nav-arrows-fade();
      }
    }

    &--hide {
      opacity: 0;
    }

    &--show {
      opacity: 1;
      pointer-events: inherit;
    }
  }

  .slide {
    #{$parent}__desktop-image {
      display: none;

      @include media($from: tbl-p) {
        display: block;
      }
    }

    #{$parent}__mobile-image {
      @include media($from: tbl-p) {
        display: none;
      }
    }
  }

  &__video-wrapper {
    position: relative;
  }

  &__video-box {
    width: 100%;
    height: 100%;
  }

  &__expand-icon {
    position: absolute;
    right: 83px;
    top: 24px;
    outline: none;
    padding: $padding-base/2;
    cursor: pointer;

    background-color: $blue-dark;
    --pfe-theme--color--ui-complement: #{$white};

    &:hover {
      --pfe-theme--color--ui-complement: #{$white};
      background: #{$color-primary};
    }
  }

  &__focus-image {
    position: absolute;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform: translate(0, 0, 1);
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  &__fixed-aspect-ratio-box {
    @include fixed-picture-aspect-ratio(1179, 767);

    @include media($to: tbl-p) {
      width: 100%;
    }
  }

  .carousel-dots-wrapper {
    bottom: 0;
    width: 100%;
    position: absolute;
    justify-content: center;
    padding: $padding-base * 1.875 0;
    background: linear-gradient(to top, rgba(10, 26, 50, 0.7), rgba(10, 26, 50, 0.5) 67%, rgba(10, 26, 50, 0));
    pointer-events: none;

    .carousel-dots {
      pointer-events: all;
      background-color: $white;
      opacity: 0.8;

      &--active {
        opacity: 1;
      }
    }
  }
}
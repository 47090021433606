@import './_variables.scss';

.no-scroll {
  overflow: hidden;
  width: 100%;
  @include media($from: tbl-p) {
    overflow-y: hidden;
  }
}

.btn-primary {
  &-blue,
  &-yellow,
  &-ghost {
    border: none;
    font-size: $font-size-base;
    font-weight: bold;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-small;
    outline: none;
    padding: 0.8125 * $padding-base 1.75 * $padding-base;
  }

  &-blue {
    background-color: $color-dark-blue;
    color: $color-white;

    &:hover {
      background-color: $color-primary-blue;
      cursor: pointer;
    }
  }

  &-yellow {
    background-color: $color-primary-yellow;
    color: $color-black;

    &:hover {
      background-color: $color-bright-yellow;
      cursor: pointer;
    }
  }

  &-ghost {
    background-color: $color-white;
    border: 2px solid $color-dark-blue;
    color: $color-dark-blue;
    padding: 0.6875 * $padding-base 1.75 * $padding-base;

    &:hover {
      border-color: $color-primary-blue;
      color: $color-primary-blue;
      cursor: pointer;
    }
  }
}

.hide-overflow-x {
  overflow-x: hidden;
}

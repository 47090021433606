@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.media-gallery {
  background: $grey-light;
  &__wrapper {
    @include max-width;
  }
  &__content {
    padding: $padding-base * 5 $padding-base * 2 0;
    @include media($to: tbl-p) {
      padding: $padding-base * 3 $padding-base $padding-base * 1.5;
    }
  }

  .heading__link {
    display: none;
  }

  .overlay-carousel-popup {
    &__heading-wrapper {
      padding: $padding-base * 2 0 0;
    }
    &__focus-image {
      &__text-description {
        @include media($from: tbl-l) {
          width: 62.3%;
        }
      }
    }
  }
}

@import '../../../styles/_variables.scss';

.link-with-arrow {
  align-items: center;
  display: flex;
}

.link-with-arrow,
span.link-with-arrow a {
  $transition-link-arrow: margin-left 0.1s ease-in;

  color: $black;
  font-weight: $font-weight-bold;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    .icon-wrapper {
      margin-left: $margin-base * 0.5;
    }

    text-decoration: underline;
  }

  .icon-wrapper {
    background-color: $color-primary;
    transition: $transition-link-arrow;
  }
}
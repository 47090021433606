@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.filter-control {
  font-size: $font-size-small;
  font-weight: $font-weight-normal;
  line-height: $line-height-smaller;
  color: $black;
  padding: $padding-base 0 $padding-base;
  border-bottom: 1px solid $color-light-grey;
  margin-bottom: 0;

  &:last-child {
    border-bottom: 0;
  }

  &__align {
    display: flex;
    align-items: center;
  }

  &__checkbox {
    width: 24px;
    height: 24px;
    border-radius: 3px;
    display: inline-block;
    margin-right: $margin-base / 2;
    position: relative;
    font-size: $font-size-small;
    color: $blue-dark;
    line-height: $line-height-small;
    font-weight: $font-weight-normal;
    overflow: hidden;
  }

  &__checkbox-icon {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: relative;
  }

  input[type='checkbox'] {
    appearance: none;
    display: none;

    &:checked+label {
      .filter-control__checkbox-icon {
        display: block;
      }
    }
  }
}
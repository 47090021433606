@import './_variables.scss';

@mixin max-width() {
  margin: 0 auto;
  max-width: $width-max-page;
}

@mixin media($from: null, $to: null) {
  $from: map-get($breakpoints, $from);
  $to: map-get($breakpoints, $to);
  $gap: 0.5px;

  @if $from and $to {
    @media (min-width: $from) and (max-width: ($to - $gap)) {
      @content;
    }
  } @else if $from {
    @media (min-width: $from) {
      @content;
    }
  } @else if $to {
    @media (max-width: ($to - $gap)) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin visually-hidden() {
  &:not(:focus):not(:active) {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}

@mixin button-unstyled() {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}

@mixin fixed-picture-aspect-ratio($pictureWidth, $pictureHeight) {
  display: block;
  padding-top: calc(#{$pictureHeight} / #{$pictureWidth} * 100%);
  position: relative;
  width: 100%;

  img {
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

@mixin nav-arrows-fade() {
  @keyframes fade {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  animation: fade 3s ease-in forwards;
}

@mixin fade-in-animation() {
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  animation: fadeIn 0.3s;
}

@mixin fade-out-animation() {
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  animation: fadeOut 0.3s;
}

@mixin fade-in-up-animation() {
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  animation: fadeInUp 0.5s ease-in-out;
}

@mixin max-width-herosection() {
  @include media($from: dsk-l) {
    &:before {
      content: '';
      position: absolute;
      width: calc((100vw - 1680px) / 2);
      background: inherit;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}

@mixin line-clamp($lines: 5) {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  display: -webkit-box;
  overflow: hidden;
}

@mixin text-ellipsis() {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin max-width-fullscreen() {
  @include media($from: dsk-l) {
    max-width: $width-max-page;
    min-width: $width-max-page;
    margin: 0 auto;
    position: relative;

    &:before {
      content: '';
      z-index: $z-index-halfscreen;
      position: absolute;
      left: calc(((100vw - 1680px) / 2) * -1);
      width: 100vw;
      background: inherit;
      height: 100%;
      top: 0;
    }
  }
}

@mixin max-width-halfscreen($left-color: inherit, $right-color: inherit) {
  @include media($from: dsk-l) {
    margin: 0 auto;
    max-width: $width-max-page;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      min-width: 50%;
      z-index: $z-index-halfscreen;
      height: 100%;
    }

    &:before {
      left: 0%;
      background: $left-color;
    }

    &:after {
      right: 0%;
      background: $right-color;
    }
  }
}

@mixin reset-button-styles() {
  font-family: inherit;
  font-weight: inherit;
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

@mixin reset-rich-text-styles() {
  ul {
    list-style: initial;

    li {
      margin-left: $margin-base;

      &::marker {
        color: $color-primary;
        font-size: $font-size-base;
      }
    }
  }
}

@mixin hideScrollbar {
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

@mixin scrollbars(
  $size: 6px,
  $foreground-color: $color-light-grey,
  $background-color: mix($foreground-color, white, 50%),
  $border-gap: 0px,
  $border-radius: 3px
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: $border-radius;
    border-left: $border-gap solid $background-color;
    border-right: $border-gap solid $background-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin custom-scroll-bar() {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-light-grey;
    border: $border-scrollbar;
    border-radius: $border-radius-scrollbar;
  }

  scrollbar-color: $color-light-grey $color-medium-grey;
  scrollbar-width: auto;
}

@mixin custom-scroll-bar-whiteBg() {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: $color-white;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-light-grey;
    border-radius: $border-radius-scrollbar;
  }

  scrollbar-color: $color-light-grey $color-white;
  scrollbar-width: auto;
}

@mixin grid-layout($grid-columns) {
  display: grid;
  grid-template-columns: repeat($grid-columns, 1fr);
  grid-column-gap: $gutter;
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin background-color() {
  &__bg-blue {
    background-color: $color-dark-blue;
    color: $color-white;
  }

  &__bg-light-blue {
    background-color: $color-pastel;
    color: $color-dark-blue;
  }

  &__bg-white {
    background-color: $color-white;
  }

  &__bg-grey {
    background-color: #f4f4f5;
  }
}
@mixin fluid-font($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 ==$u2 and $u1 ==$u3 and $u1 ==$u4 {
    & {
      font-size: $min-font-size;

      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }

      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

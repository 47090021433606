@import '../../../../styles/_variables';
@import '../../../../styles/_mixins';

.dealer-equipments-services {
  &__wrapper {
    background-color: $color-backgroud-grey;

    & ~ & {
      background-color: $white;
    }
  }

  padding: $padding-base * 3 0 $padding-base * 2.5;

  @include media($from: tbl-p) {
    padding: $padding-base * 5 0 $padding-base * 4;
  }

  /* -----Container width & alignment----- */
  margin: 0 ($margin-base - 1) 0 $margin-base; // 15px margin for 3card layout at 375px
  width: fit-content;
  
  &--three-column {
    width: unset;
  }

  @include media($from: tbl-p, $to: tbl-l) {
    margin-left: $margin-base * 2;
    margin-right: $margin-base * 5; // space to prevent overlap of quick links
  }

  @include media($from: tbl-l) {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  /* -----END of Container width & alignment----- */

  &__title {
    margin: 0;
    color: $blue-dark;
    font-weight: bold;
    font-size: $font-size-large;
    line-height: $line-height-medium;
    letter-spacing: $letter-spacing-medium;
    white-space: normal;
    word-break: break-word;

    @include media($from: tbl-p) {
      font-size: $font-size-larger;
      line-height: $line-height-large;
      letter-spacing: $letter-spacing-large;
    }
  }

  &__three-column-cards {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    column-gap: $margin-base;
    row-gap: $margin-base * 1.5;
    margin-top: $margin-base * 1.5;

    @include media($from: tbl-p) {
      grid-template-columns: repeat(3,1fr);
      row-gap: $margin-base * 2;
      margin-top: $margin-base * 2;
    }
    @include media($to: mbl) {
      grid-template-columns: repeat(1,1fr);
      row-gap: $margin-base * 2;
      margin-top: $margin-base * 2;
    }
  }
  
  &-three-column-card {
    width: 100%;

    &__picture {
      width: 100%;
      @include fixed-picture-aspect-ratio(343, 193);
       
      @include media($from: tbl-p) {
        @include fixed-picture-aspect-ratio(256, 144);
      }
      margin-bottom: $margin-base * 0.5;
    }
  }

  &__cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: $margin-base;
    row-gap: $margin-base * 1.5;
    margin-top: $margin-base * 1.5;

    @include media($from: tbl-p) {
      row-gap: $margin-base * 2;
      margin-top: $margin-base * 2;
    }
  }

  &-card {
    width: 104px;

    @include media($from: tbl-p) {
      width: 147px;
    }

    &__image {
      object-fit: cover;
      width: 100%;
      height: 60px;
      margin-bottom: $margin-base * 0.5;

      @include media($from: tbl-p) {
        height: 83.3px; // aspect ratio not needed as per design
      }
    }

    &__title {
      margin: 0;
      font-weight: bold;
      color: $color-primary-blue;
      font-size: $font-size-small;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
      white-space: normal;
      word-break: break-word;
      hyphens: auto;

      @include media($from: tbl-p) {
        font-size: $font-size-base;
      }
    }
  }
}

@import '../_variables.scss';
@import '../_mixins.scss';

$image-width-dsk: 328;
$image-width-mbl: 192;
$image-height-dsk: 185;
$image-height-mbl: 67;

.product-card {
  display: flex;
  flex-direction: column;
  color: inherit;
  background: $white;
  text-decoration: none;
  padding: 0px 2 * $padding-base;
  height: 100%;

  &:hover {
    text-decoration: none;

    .product-card__image {
      transform: scale(1.1);
    }

    @include media($from: tbl-p) {
      .product-card__content {
        border-bottom: 2px $yellow-primary solid;
      }
    }

    .product-card__image-dots-wrapper::after {
      transform: translate(-32px, -32px);
      opacity: 1;
    }
  }

  @include media($to: tbl-p) {
    padding: $padding-base 0px;
    display: flex;
    flex-direction: row;
    column-gap: 13px;
    border-bottom: 2px $grey-light solid;
  }

  &__wrapper {
    @include media($to: tbl-p) {
      width: 100%;
    }
  }

  &__price-tag {
    background-color: $color-pastel;
    color: $color-black;
    font-size: $font-size-smaller;
    font-weight: $font-weight-bold;
    z-index: $z-index-product-card-price-tag;
    text-align: center;
    width: fit-content;
    padding: $padding-base * 0.25 $padding-base/2;
    margin-bottom: $margin-base * 0.75;

    @include media($from: tbl-p) {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      white-space: normal;
    }
  }

  &__image {
    height: $image-height-dsk * 1px;
    width: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
    z-index: $z-index-product-card-image;
    background: $white;

    @include media($to: tbl-p) {
      height: $image-height-mbl * 1px;
      object-fit: contain;
    }

    &-wrapper {
      height: $image-height-dsk * 1px;
      max-width: 100%;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      display: flex;
      background-color: white;

      @include media($to: tbl-p) {
        width: 31.73vw;
        height: 100%;
      }
      picture {
        z-index: $z-index-medium;
      }
    }

    &-dots-wrapper {
      overflow: visible;
      position: relative;

      @include media($from: dsk) {
        &::after {
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 16px;
          opacity: 0;
          background: url('../../assets/Yellow-dots-1.png') no-repeat;
          transform: translateX(-32px);
          transition: transform 0.5s, opacity 0.5s;
        }
      }

      &--no-dots {
        &::after {
          display: none;
        }
      }
    }
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.75 * $padding-base 0 1.5 * $padding-base;
    border-bottom: 2px $grey-light solid;

    @include media($to: tbl-p) {
      border: none;
      padding: 0.25 * $padding-base 0px 0px;
    }

    &--title-summary {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: space-between;
    }
  }

  &__title {
    font-weight: bold;
    font-size: $font-size-medium;
    line-height: $line-height-small;
    letter-spacing: 0.5px;
    color: $color-primary;
    margin: 0px;
    hyphens: auto;
  }

  &__summary-specs {
    &-value {
      font-size: $font-size-small;
      line-height: $line-height-small;
      margin: $margin-base * 0.5 0px 0px;
      color: $grey-dark;
      letter-spacing: 0.8px;
    }
  }

  &__button {
    margin-top: 1.5 * $margin-base;

    @include media($to: tbl-p) {
      display: none;
    }
  }
}

@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.language-selector {
  box-sizing: border-box;
  height: 32px;
  margin: $margin-base 0 $margin-base * 0.5 0;
  position: relative;
  width: fit-content;

  @include media($from: tbl-p) {
    margin: $margin-base 0 0 0;
  }

  &__left-icon,
  &__right-icon {
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: $z-index-icons;
    background-color: $blue-dark;
  }

  &__left-icon {
    left: 10px;
  }

  &__right-icon {
    right: 10px;
  }

  &__select {
    background: rgba(255, 255, 255, 0);
    border: 1px solid $blue-dark;
    border-radius: 0;
    color: $blue-dark;
    font-size: $font-size-smaller;
    font-weight: $font-weight-bold;
    line-height: $line-height-smaller;
    height: 32px;
    outline: none;
    padding: 0 $padding-base * 2;
    -webkit-appearance: none;

    cursor: pointer;

    &:hover {
      border-color: $color-primary;
      color: $color-primary;

      &~.language-selector__left-icon,
      &~.language-selector__right-icon {
        background-color: $color-primary;
      }
    }
  }
}
$margin-base: 16px;
$padding-base: 16px;
$gutter: 16px;
$width-max-page: 1680px;
$height-navigation: 85px;
$height-card: 550px;
$height-quicklinks: 64px;
$height-breadcrumb: 56px;
$shadow-size-card: 80px;
$shadow-trasparency-size-card: 400px;
$width-dsk-modal-content: 1344px;
$width-dsk-max-modal-content: 1680px;
$max-modal-breakpoint: 1920px;
$splash-page-header-height: 85px;
$splash-page-footer-height: 56px;

// region BREAKPOINTS
$breakpoints: (
  mbl-s: 375px,
  mbl: 576px,
  tbl-p: 768px,
  tbl-l: 992px,
  dsk: 1200px,
  dsk-m: 1400px,
  dsk-l: 1680px,
  dsk-modal-bp: $width-dsk-modal-content,
  dsk-max-modal-bp: $max-modal-breakpoint,
);
// endregion BREAKPOINTS

// region COLORS
// Colors naming defined by design
$color-primary-blue: #003e85;
$color-pastel: #86bced;
$color-dark-blue: #00205b;
$color-primary-yellow: #f1b514;
$color-bright-yellow: #ffd300;
$color-error: #c71515;
$color-warning: #de9521;
$color-success: #40ab00;
$color-light-grey: #dcddde;
$color-medium-grey: #7d7b7b;
$color-dark-grey: #424242;
$color-black: #000;
$color-white: #fff;
$color-backgroud-grey: #f5f5f6;

// Custom colors
$black: #000000;
$blue-dark: #00205b;
$blue-pastel: #86bced;
$blue-pastel-075: rgba(134, 188, 237, 0.75);
$blue-pastel-04: rgba(134, 188, 237, 0.4);
$color-primary: #003e85;
$grey-dark: #424242;
$grey-lighter: #f8f9f9;
$grey-light: #dcddde;
$grey-medium: #7d7b7b;
$grey-medium-05: rgba(125, 123, 123, 0.5);
$gray-disabled: #d0d0d0;
$white: #ffffff;
$yellow-bright: #ffd300;
$yellow-primary: #f1b514;
$light-grey-02: rgba(220, 221, 222, 0.2);
$alert-message-background-color: #ffe7ba;

$color-button-shadow: rgba(126, 126, 126, 0.25);
$modal-overlay-background-color: rgba(0, 0, 0, 0.7);
$card-box-scroll-shadow: rgba(0, 0, 0, 0.2);
$primary-btn-box-shadow: 0 10px 20px 0 rgba(255, 211, 0, 0.3);
$secondary-btn-box-shadow: 0 10px 20px 0 rgba(134, 188, 237, 0.3);
$color-arrow-hover-box-shadow: rgba(0, 62, 133, 0.2);
$color-light-gray-opacity02: rgba(220, 221, 222, 0.2);
$color-light-grey-opacity03: rgba($grey-light, 0.3);
$color-light-gray-opacity03: rgba(244, 245, 245, 1);
$color-light-gray-opacity05: rgba(220, 221, 222, 0.5);
$card-overlay-box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.2);
$dealer-result-eybrow-box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
$card-overlay-box-shadow-side: 0 19px 0px 0px white, 0 -9px 0px 0px white, 0 10px 20px -10px rgba(0, 0, 0, 0.2),
  0 10px 20px -10px rgba(0, 0, 0, 0.2);
$color-tooltip-shadow: rgba(0, 0, 0, 0.4);
$color-carousel-dots: rgba(255, 255, 255, 0.6);
$color-background-strong: rgba($blue-pastel, 0.3);
$color-pastel-opacity01: rgba(134, 188, 237, 0.75);
$color-close-btn-view360: rgba(255, 255, 255, 0.6);
$color-blue-dark-opacity05: rgba($blue-dark, 0.5);

$blue-gradient: linear-gradient(270deg, #1b4686 0%, #174587 0.01%, $blue-dark 100%);
$silver-gradient: linear-gradient(135deg, rgba(125, 123, 123, 0.4) 0%, $white 52.08%, rgba(125, 123, 123, 0.4) 100%), $white;
$carousel-image-gradient: linear-gradient(to top, rgba(10, 26, 50, 0.7), rgba(10, 26, 50, 0.5) 67%, rgba(10, 26, 50, 0));
$background-header-tray: linear-gradient(0deg, rgba(199, 205, 208, 0.3), rgba(199, 205, 208, 0.3)), #ffffff;
$modal-overlay-background-color: rgba(0, 0, 0, 0.7);
$color-input-shadow: rgba(0, 0, 0, 0.2);
$quick-links-gradient-background: linear-gradient(270deg, #174587 0%, #174587 0.01%, #090a1c 100%);
$floating-links-gradient-background: linear-gradient(270deg, #174587 0%, #174587 0.01%, #090a1c 100%);
$loader-background: rgba(255, 255, 255, 0.702);
$shadow-black-001: 0 4px 25px 0 rgba(#000, 0.1);
$shadow-blue-001: 0 4px 25px 0 $color-pastel-opacity01;
$shadow-pastel: 0 0 30px -10px $color-pastel;
$shadow-pastel2: 0 4px 20px -10px rgba($color-black, 0.2);
$shadow-pastel3: 0px 20px 50px -30px $color-pastel;
$shadow-pastel4: -10px 0px 20px rgba(134, 188, 237, 0.3);
$background-light-gradient-overlay-shadow: rgba(10, 26, 50, 0);
$shadow-black-20-001: 0 0 20px 0 rgba($color-black, 0.1);
$compatible-products-card-gradient: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 45%);
$box-shadow-primary-dark-blue-cta: 0 10px 20px 0 rgba(0, 62, 133, 0.2);
$box-shadow-dealer-locator-results: 5px 0 6px 0 rgba(0, 0, 0, 0.15);
$color-loader-opacity07: rgba($color-white, 0.7);
$dealer-results-top-gradient-shadow: linear-gradient(to top, rgba(6, 8, 8, 0) 0%, rgba(6, 8, 8, 0.1) 90%);
// endregion COLORS

// region TYPOGRAPHY
$font-family: 'Lato', sans-serif;
$font-family-series-view360: 'tahoma', sans-serif;
$font-family-close-btn-view360: 'Neue Helvetica W02', Arial, sans-serif;

$font-size-smaller: 12px;
$font-size-small: 14px;
$font-size-base: 16px;
$font-size-medium: 20px;
$font-size-medium-large: 24px;
$font-size-large: 30px;
$font-size-larger: 40px;
$font-size-extra-large: 56px;

$font-weight-normal: 400;
$font-wight-medium-bold: 600;
$font-weight-bold: 700;

$line-height-smaller: 20px;
$line-height-small: 24px;
$line-height-base: 30px;
$line-height-normal: 32px;
$line-height-medium: 36px;
$line-height-large: 44px;
$line-height-larger: 60px;

$letter-spacing-smaller: 0px;
$letter-spacing-small: 0.5px;
$letter-spacing-base: 0.8px;
$letter-spacing-normal: 1px;
$letter-spacing-medium: 1.8px;
$letter-spacing-large: 2px;
// endregion TYPOGRAPHY

// region Z-INDEXES
$z-index-carousel-img: -2;
$z-index-full-screen-media-carousel-img: -2;
$z-index-transparent-nav-after: -1;
$z-index-attachment-dropdown-item-background: -1;
$z-index-search-dropdown-item-background: -1;
$z-index-background: -1;
$z-index-hotspot-background: -1;
$z-index-background-media: -1;
$z-index-halfscreen: -1;
$z-index-boxed-hero-banner-video: -1;
$z-index-full-screen-media-carousel-controls-background: 0;
$z-index-full-screen-media-carousel-description: 0;
$z-index-base: 0;
$z-index-full-screen-media-carousel-controls: 1;
$z-index-multi-step-circle: 1;
$z-index-medium: 1;
$z-index-media-carousel-play-button: 1;
$z-index-carousel-video-play-button: 1;
$z-index-feature-carousel-items: 1;
$z-index-gallery-layout-level-one: 1;
$z-index-splash-page-sector-selector: 1;
$z-index-splash-page-content: 1;
$z-index-card-layout: 1;
$z-index-edit-image-cta: 1;
$z-index-carousel-card-layout: 1;
$z-index-hero-banner-navigation-bar: 1;
$z-index-alert-message-bar: 1;
$z-index-dealer-landing-map-mode-dealers: 2;
$z-index-telphone-search: 2;
$z-index-text-input: 2;
$z-index-card-column-loader-background: 2;
$z-index-hero-banner-boxed-in-pop-up: 2;
$z-index-header-box-shadow: 2;
$z-index-gallery-layout-level-two: 2;
$z-index-find-equipment-text-box: 2;
$z-index-series-overview-text: 2;
$z-index-series-detail-component-wrapper: 2;
$z-index-series-overview-indicators: 2;
$z-index-inner-split-card-text-content: 2;
$z-index-product-card-image: 2;
$z-index-card-layout-overlay: 2;
$z-index-image-overview-carousel: 2;
$z-index-video-player-play-icon: 2;
$z-index-specs-table-elevate: 2;
$z-index-dealer-landing-map-mode-no-results-dialog: 3;
$z-index-specs-table-col-cover: 3;
$z-index-dropdown: 3;
$z-index-selected-tab-indicator: 3;
$z-index-header: 3;
$z-index-dealer-landing-map-mode-spinner: 3;
$z-index-country-dropdown: 4;
$z-index-dropdownList-form: 4;
$z-index-dropdown-input-form: 5;
$z-index-carousel-links: 5;
$z-index-attachment-dropdown: 7;
$z-index-dealer-filter-droplist: 7;
$z-index-carousel-arrow: 10;
$z-index-icons: 10;
$z-index-tooltip: 10;
$z-index-dealer-locator-heading: 11;
$z-index-download-button: 11;
$z-index-compatible-products-hover: 11;
$z-index-plm-solution-card-hover: 11;
$z-index-custom-dropdown: 15;
$z-index-generic-close-button: 16;
$z-index-card-column-layout-hover: 17;
$z-index-series-overview-nav-section-arrow: 85;
$z-index-series-overview-nav-section: 85;
$z-index-series-navigation: 90;
$z-index-find-equipment-text-box: 91;
$z-index-split-module-brand-image: 98;
$z-index-specs-table-col-index: 98;
$z-index-split-module-asset: 99;
$z-index-navigation: 100;
$z-index-scroll-shadow: 100;
$z-index-overlay: 105;
$z-index-dropdown-modal: 115;
$z-index-promo-banner: 200;
$z-index-sticky-top-wrapper: 210;
$z-index-quick-links: 209;
$z-index-floating-links: 209;
$z-index-geo-location-message: 210;
$z-index-table-dropdown: 211;
$z-index-disable-parent-view360: 211;
$z-index-sticky-nav-dropdown: 311;
$z-index-modal: 1000;
$z-index-modal-edit-images: 10000;
$z-index-news-listing-filter-modal: 1000;
$z-index-dealer-locator-filter-modal: 1000;
$z-index-special-offers-filter-modal: 1000;
$z-index-download-brochure-listing-filter-modal: 1000;
$z-index-latest-news-tag: 1;
$z-index-recaptcha: 999;
$z-index-product-card-price-tag: 1;
$z-index-loader: 9147483641; // override live chat component
$z-index-quick-links-mobile: 99;
$z-floating-menu-links-mobile: 99;
// endregion Z-INDEXES

//Borders
$border-radius-scrollbar: 2px;
$border-radius-alert-messag: 3px;
$border-scrollbar: 1px solid #dcddde;
$border-alert-messag: solid 1px $color-warning;
//end Borders

$vh100: var('--100-vh', 100vh);

// bezier-curves
$transition-slow-start-fast-end: cubic-bezier(0.9, 0.09, 0.5, 0.92);

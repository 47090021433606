@import '../../../styles/_mixins.scss';
@import '../../../styles/_variables.scss';

.externalComponent {
  @include max-width;

  margin: $margin-base auto;

  iframe {
    width: 100%;
    border: 0;
  }
}

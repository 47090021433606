@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.interactive-image {
  background-color: $white;
  overflow: hidden;

  &__container {
    @include max-width;
    @include media($from: tbl-l) {
      display: grid;
      grid-template-columns: 34.286% 65.714%;

      > :nth-child(1) {
        grid-row: 1/2;
        grid-column: 2/3;
      }
      > :nth-child(2) {
        grid-row: 1/2;
        grid-column: 1/2;
      }
    }
  }
}

@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.download-brochure_card {
  $root: &;
  &__image-container {
    margin: 0 $margin-base * 2 0 $margin-base * 2;

    @include media($to: tbl-p) {
      margin: 0;
    }
  }

  &__article__picture {
    width: 100%;

    &--fixed-aspect-ratio {
      @include fixed-picture-aspect-ratio(392, 221);

      @include media($to: tbl-p) {
        height: calc((9 / 16) * 100vw);
        width: 100%;
        padding-top: unset;
      }
    }
  }

  &__article {
    display: flex;
    flex-direction: column;
    height: 100%;

    @include media($to: tbl-p) {
      margin-bottom: $margin-base * 2.5;
    }
  }

  &__content-block {
    flex-grow: 1;
    margin: 0 $margin-base * 2.5 $margin-base * 1.5 $margin-base * 2.5;

    @include media($to: tbl-p) {
      margin: $margin-base $margin-base 0;
    }
  }

  &__title {
    color: $blue-dark;
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
    margin: $margin-base 0 $margin-base * 0.5 0;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-small;

    @include media($to: tbl-p) {
      font-weight: $font-weight-bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: $letter-spacing-small;
      margin: $margin-base * 0.75 $margin-base * 2.75 $margin-base / 2 0;
    }
  }

  &__sub-heading {
    margin: $margin-base / 2 0 0;
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    font-stretch: normal;
    font-style: $line-height-small;
    line-height: 1.43;
    letter-spacing: $letter-spacing-base;

    @include media($to: tbl-p) {
      padding: 0 0 $padding-base * 4;
    }
  }

  &__link-wrapper {
    display: flex;
    column-gap: 24px;
    margin-left: $margin-base * 2.5;
    letter-spacing: $letter-spacing-base;

    @include media($to: tbl-p) {
      margin: $margin-base * 1.5 0 0 $margin-base;
    }
  }

  &__link {
    cursor: pointer;
    &,
    & + a,
    a {
      text-decoration: none;
    }

    &--share {
      display: flex;
      align-items: center;

      pfe-cta {
        width: 100%;
      }

      .at-icon-wrapper {
        display: none;
      }

      &:hover a {
        color: $blue-dark;
      }
    }

    &--label {
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      line-height: 1.38;
      text-align: center;
      color: $color-primary-blue;

      a {
        color: $color-primary-blue;
      }
    }

    &--download,
    &--view {
      display: flex;
      align-items: center;
      font-weight: $font-weight-bold;
      text-align: center;
    }

    &__icon {
      display: inline-block;
      vertical-align: bottom;
    }

    .icon-wrapper {
      margin-left: $margin-base / 2;
      transition: 0.3s margin-left;
    }

    &:hover {
      color: $blue-dark;

      .icon-wrapper {
        background-color: $blue-dark;
      }
    }
  }

  &__add-to-any {
    line-height: inherit !important;
    float: none !important;
    padding: 0 !important;
    display: flex;
  }
}

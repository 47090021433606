body {
  padding: 0;
  margin: 0;
}

.promo-banner__container {
  display: none;
}

ul {
  list-style: none;
  padding: initial;
}

p {
  margin: initial;
}

sup {
  line-height: 0;
}

//prevent style in safari when an element is focused
*:focus {
  outline: none;
}

@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

$link-color: $blue-dark;
$link-color-hover: $color-primary;
$power-bar-height-base: 16px;
$logo-mbl-height: 33px;
$logo-mbl-width: 110px;
$background-image-mbl-width: 211px;

.footer-wrapper {
  background-color: rgba(220, 221, 222, 0.3);
}

.news-detail-footer-gap {
  margin-bottom: $margin-base * 2.5;
  @include media($from: tbl-p) {
    margin-bottom: $margin-base * 5;
  }
}

.footer {
  @include max-width();
  overflow-x: hidden;
  padding: $padding-base * 2 $padding-base $padding-base * 2.6875;
  position: relative;

  @include media($from: tbl-p) {
    padding: $padding-base * 2.5 $padding-base * 2 $padding-base * 2;
  }

  &__columns {
    margin-bottom: $margin-base * 2.5;
    @include media($from: tbl-p) {
      column-gap: $margin-base;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      margin-bottom: 0;
    }
  }

  &__last-column {
    @include media($from: tbl-p) {
      padding-right: $padding-base * 2;
    }
  }

  &__bottom {
    margin: $margin-base * 3.0625 0 0;

    .footer__logo {
      @include media($to: tbl-p) {
        height: $logo-mbl-height;
        width: $logo-mbl-width;
      }
      @include media($from: tbl-p) {
        margin-top: 0;
        margin-right: $margin-base * 1.5;
      }
    }

    .footer__copyright-text {
      margin: 0;
      width: 100%;
      @include media($from: tbl-p) {
        width: auto;
      }
    }

    .footer__text {
      @include media($from: tbl-p) {
        order: 1;
      }
    }

    @include media($from: tbl-p) {
      align-items: center;
      display: flex;
    }
  }

  &__back-to-top-wrapper {
    margin-top: $margin-base * 2.5;
    margin-bottom: $margin-base * 3.75;
  }

  &__copyright-text {
    color: $link-color;
    font-size: $font-size-smaller;
    line-height: $line-height-smaller;
    margin-top: $margin-base;
  }

  &__background img {
    height: auto;
    position: absolute;
    right: 0;
    z-index: $z-index-background;
    float: right;

    @include media($to: tbl-p) {
      bottom: 0;
      width: $background-image-mbl-width;
    }

    @include media($from: tbl-p) {
      bottom: 0;
      width: initial;
    }
  }
}

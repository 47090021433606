@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.series-overview-hero-card {
  &__picture {
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__focus-image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform: translate(0, 0, 1);

    &__text-wrapper {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      color: $white;
      z-index: 2;
      padding: $padding-base;
      background: linear-gradient(to top, rgba(10, 26, 50, 0.7), rgba(10, 26, 50, 0.5) 33%, rgba(10, 26, 50, 0));
    }

    &__text-title {
      font-size: $font-size-medium;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-small;
      font-weight: $font-weight-bold;
      margin-bottom: $margin-base/2;
    }

    &__text-description {
      font-size: $font-size-small;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
    }
  }

  &__slide-video-play-button {
    position: absolute;
    top: 50%;
    z-index: $z-index-carousel-video-play-button;
    padding: $padding-base * 1.5;
    transform: translate(-50%, -50%);
    left: 50%;
    cursor: pointer;
    background-color: $blue-dark;
    --pfe-theme--color--ui-complement: #{$white};

    &:hover {
      background: #{$color-primary};
    }

    @include media($to: tbl-p) {
      padding: $padding-base/2;
    }
  }

  &__fixed-aspect-ratio-box {
    @include fixed-picture-aspect-ratio(1179, 768);

    @include media($to: tbl-p) {
      width: 100%;
    }
  }
}

.series-overview-modal-video {
  max-width: 846px;

  &__internal-video {
    width: 100%;
    height: 100%;
  }

  &__youtube-video {
    width: 100%;
    height: 100%;
    margin: 0;

    @include media($to: tbl-p) {
      height: 100%;
      width: 100%;
    }
  }
}
@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.ExperienceEditorComponentPlaceholder {
  &__text {
    @include max-width;
    text-align: center;
    padding: $padding-base;
    box-sizing: border-box;
    border: 2px dotted $gray-disabled;
    color: $gray-disabled;
  }
  &__image {
    display: flex;
    padding: 0;
    margin: 0;
    width: 100%;
    @include max-width;
  }
}

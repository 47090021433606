@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.series-overview-text-wrapper {
  position: relative;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  &__dot-space {
    height: 0;

    @include media($from: tbl-p) {
      height: 64px;
    }

    width: 100%;
  }
}

.series-overview-text {
  background: $white;
  z-index: $z-index-series-overview-text;
  box-sizing: border-box;
  padding: $padding-base;

  @include media($from: tbl-p) {
    padding: 2 * $padding-base 2 * $padding-base 4 * $padding-base;
    flex-grow: 1;
  }

  @include media($to: tbl-p) {
    min-width: 343px;
    height: auto;
  }

  &__eyebrow {
    color: $grey-medium;
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    margin: 0 0 $margin-base/2 0;

    @include media($from: tbl-p) {
      font-size: $font-size-base;
    }
  }

  &__title {
    font-size: $font-size-large;
    line-height: $line-height-medium;
    letter-spacing: $letter-spacing-medium;
    font-weight: $font-weight-bold;
    color: $blue-dark;
    margin: $margin-base/2 0;
    word-break: break-word;

    @include media($from: tbl-p) {
      font-size: $font-size-larger;
      line-height: $line-height-large;
      letter-spacing: $letter-spacing-large;
      margin: $margin-base/2 0 $margin-base 0;
    }
  }

  &__description {
    font-weight: $font-size-small;
    margin-bottom: 1.5 * $margin-base;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $blue-dark;

    @include media($from: tbl-p) {
      margin-bottom: 0;
      font-size: $font-size-base;
    }
  }

  &__link-wrapper {
    margin-top: $margin-base * 2;
    display: flex;
    flex-wrap: wrap;
    row-gap: $margin-base * 1.5;

    @include media($to: tbl-p) {
      flex-direction: column;
      justify-content: center;
    }

    &:empty {
      display: none;
    }
  }

  & .s-blue,
  & .s-white {
    padding: 0 0 0 $padding-base * 1.5;
  }

  & &__pop-up {
    flex-grow: 1;
    margin: auto 0;
  }

  &__right-margin {
    margin-right: $margin-base * 1.5;
  }
}
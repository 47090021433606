@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

body.body--with-dealer-filter-model {
  // prevent scrolling
  overflow: hidden;
}

.dealer-locator-mbl-filters {
  padding: 0 $padding-base;

  .dealer-locator-mbl-modal-switch {
    padding: $padding-base;
    display: flex;
    align-items: center;
    column-gap: $margin-base * 0.5;
    font-weight: $font-weight-normal;
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $color-dark-blue;
    justify-content: flex-end;
  }

  .dealer-filter-modal {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: $white;
    left: 0;
    top: 0;
    z-index: $z-index-dealer-locator-filter-modal;
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: $padding-base;
      background-color: $color-dark-blue;
      color: $white;

      .icon-wrapper {
        flex-shrink: 0;
      }
    }

    &__title {
      font-size: $font-size-small;
      font-weight: bold;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
    }

    &__body {
      flex-grow: 1;
      overflow: auto;
    }

    &__ctas {
      display: flex;
      column-gap: $margin-base;
      row-gap: $margin-base;
      flex-wrap: wrap;
      padding: $padding-base;
      box-shadow: $shadow-pastel;

      .button {
        flex-grow: 1;
        justify-content: center;
      }
    }

    &__cta {
      &--disabled.button {
        cursor: not-allowed;
        background-color: $color-medium-grey;
        color: $white;
        border: 2px solid $color-medium-grey;

        &:hover {
          color: $white;
          border: 2px solid $color-medium-grey;
          box-shadow: none;
        }
      }
    }
  }
}

@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.model-series-listing-card {
  @include reset-rich-text-styles();
  $root: &;
  padding: $padding-base * 1.25 0 $padding-base * 1.5;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  border-bottom: 2px solid $grey-light;
  margin-bottom: 0;

  @include media($from: tbl-p) {
    padding: $padding-base * 4 $padding-base * 2 $padding-base * 1.5;
    flex-direction: column;
    border-bottom: none;
    &::after {
      content: '';
      background-color: $grey-light;
      width: 100%;
      height: 2px;
      margin-top: $margin-base * 1.5;
    }
  }

  &__image-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 119px;

    @include media($from: tbl-p) {
      margin-bottom: $margin-base / 2;
    }
  }

  &__image {
    object-fit: cover;
    max-width: 100%;
    width: 100%;
    @include fixed-picture-aspect-ratio(119, 67);

    @include media($from: tbl-p) {
      @include fixed-picture-aspect-ratio(328, 185);
    }
  }

  &__body {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 0 0 0 $padding-base;

    @include media($from: tbl-p) {
      padding: 0 $padding-base/2;
    }
  }

  &__title {
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-small;
    color: $color-primary;
    // margin: 0 $margin-base / 2 0;
    white-space: normal;
  }

  &__description-wrapper {
    margin-bottom: 0;

    position: relative;
    text-decoration: none;

    @include media($from: tbl-p) {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }

  &__description {
    white-space: normal;
    padding: $padding-base / 2 0;
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $grey-dark;
    cursor: pointer;

    &--relative {
      @include media($from: tbl-p) {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        padding-bottom: 0;
      }
    }

    &--absoulte {
      position: absolute;
      top: 0;
      z-index: $z-index-card-layout;
      background-color: $white;
      padding-bottom: $padding-base * 1.5;
      border-bottom: 2px solid $grey-light;
    }

    &--lastCard {
      position: relative;
      display: block;
      overflow: visible;
    }
  }

  &__cta {
    margin: ($margin-base * 1.5) ($margin-base * 0.5) 0;
    cursor: pointer;

    @include media($from: tbl-p) {
      margin-left: 0;
    }

    &-wrapper {
      margin-top: auto;
    }
  }
}

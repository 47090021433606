@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  height: 100vh;
  width: 0;
  background: $loader-background;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s ease-in 0s, width 0s ease-in 0.2s;
  z-index: $z-index-loader;

  .spinner {
    display: none;
  }

  &--show {
    opacity: 1;
    transition: opacity 0.2s ease-in 0s, width 0s ease-in 0s;
    width: 100vw;

    .spinner {
      display: initial;
    }
  }
}

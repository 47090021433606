@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.preferred-dealer-search {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $margin-base * 1.5;

  @include media($from: tbl-l) {
    width:  73.5%; // calculated width for 2 text inputs 
    flex-direction: row;
    gap: $margin-base;
  }

  &__col {
    width: 100%;
    box-sizing: border-box;
    display: flex;

    &--dropdown {
      @include media($from: tbl-l) {
        max-width: 392px;
      }
    }

    &--text-search {
      @include media($from: tbl-l) {
        max-width: 392px;
      }
    }
  }

  &__dropdown {
    position: relative;
    width: 100%;
  }

  &__dropdown-input {
    box-sizing: border-box;
    background-color: $color-white;
    border: 1px solid $color-white;
    padding: 1.25 * $padding-base $padding-base $padding-base / 4;
    box-shadow: 0 4px 20px -10px $color-input-shadow;

    &--open {
      border-color: $color-primary;
    }
  }

  &__dropdown-input-field {
    color: $color-dark-blue;
    display: block;
    padding: 0;
    border: 0;
    font-size: $font-size-small;
    line-height: $line-height-small;
  }

  &__dropdown-input-text,
  &__text-search-input {
    color: $color-dark-blue;
    height: 24px;
    display: block;
    line-height: 24px;
    padding: 0;
    border: 0;
    font-size: $font-size-small;
    width: 100%;
  }

  &__dropdown-input-icon,
  &__text-search-input-icon {
    position: absolute;
    top: 13px;
  }

  &__dropdown-input-icon {
    right: 8px;
  }

  &__text-search-input-icon {
    left: 8px;
  }

  &__text-search-google-logo {
    float: right;
    padding: $margin-base * 0.5 $margin-base $margin-base;
  }

  &__dropdown-list,
  &__text-search-suggestions {
    position: absolute;
    left: 0;
    background-color: $color-white;
    z-index: 4;
    box-shadow: 0 0 30px -10px $blue-pastel;
    max-height: 402px;
    width: 100%;
    overflow: auto;
    @include custom-scroll-bar-whiteBg;
  }

  &__dropdown-label,
  &__text-search-label {
    color: $color-dark-grey;
    position: absolute;
    font-size: $font-size-smaller;
    top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 64px);

    &--center {
      top: 16px;
      font-size: $font-size-small;
    }
  }

  &__list-item {
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $color-dark-blue;
    padding: 0.5 * $padding-base $padding-base;
    cursor: pointer;
    position: relative;
    min-height: 40px;

    &:not(:last-child)::after {
      position: absolute;
      display: block;
      content: '';
      bottom: 0;
      left: 16px;
      right: 16px;
      border-bottom: 1px solid $color-light-grey;
    }

    &--highligted {
      background-color: rgba($grey-light, 0.3);
    }
  }

  &__text-search {
    $root: &;
    position: relative;
    width: 100%;

    &--disable {
      pointer-events: none;
      background-color: $light-grey-02;
      #{$root}-input-wrapper {
        background-color: unset;
      }
      #{$root}-input {
        background-color: unset;
      }

      #{$root}-label {
        color: $grey-medium;
      }
    }
  }

  &__text-search-input-wrapper {
    position: relative;
    box-sizing: border-box;
    background-color: $color-white;
    border: 1px solid $color-white;
    padding: 1.25 * $padding-base $padding-base $padding-base * 0.25 $padding-base;
    box-shadow: 0 4px 20px -10px $color-input-shadow;
  }

  &__text-search-suggestion-group {
    margin: 0;
    
  }
}

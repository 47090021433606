@import '../../../../styles/_variables';
@import '../../../../styles/_mixins';

.download-brochure-listing-search {
  .filter-search {
    &__input-wrapper {
      @include media($from: tbl-l) {
        width: 24.22vw;
        min-width: 250px;
        max-width: 407px;
      }
    }

    &__placeholder {
      @include media($from: tbl-p) {
        left: $margin-base * 3;
      }
    }
  }
}

@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.special-offers-listing-filter {
  &__heading {
    display: flex;
    justify-content: space-between;
    margin-top: $margin-base * 0.5;
  }

  &__title {
    font-size: $font-size-large;
    line-height: $line-height-medium;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-small;
    color: $color-dark-blue;
    margin: 0;
  }

  &__reset {
    display: flex;
    column-gap: $margin-base * 0.5;
    margin-left: auto;
    align-items: flex-end;

    &.button {
      padding: 0 !important;
    }
  }

  &--dsk {
    @include max-width-fullscreen;
    box-sizing: border-box;
    background-color: $color-light-grey-opacity03;
    padding: ($padding-base * 1.5) ($padding-base * 5) ($padding-base * 1.5) ($padding-base * 2);
    display: flex;
    flex-direction: column;
    row-gap: $margin-base * 1.5;

    @include media($from: dsk-l) {
      background-color: initial;

      &::before {
        background-color: $color-light-grey-opacity03;
      }
    }

    .offers-filter {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: $margin-base;

      &__dropdown-wrapper {
        display: flex;
      }

      &__dropdown {
        margin: 0;
      }
    }
  }
}

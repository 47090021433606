@import '../../../../styles/_variables';
@import '../../../../styles/_mixins';

.sitecore-jss-form__field-wrapper .telephone-field {
  &__container {
    height: 50px;
    box-shadow: 0 4px 20px -10px rgba($color-black, 0.2);
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    font-family: $font-family;

    &:focus-within {
      outline: 1px solid $color-primary-blue;
    }
  }

  &__input.form-control {
    color: $color-dark-blue;
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    width: 100%;
    height: fit-content;
    border: 0;
    border-radius: unset;
    border-left: 1px solid $color-medium-grey;
    padding: 0 0 0 $margin-base * 0.5;

    &::placeholder {
      opacity: unset;
      color: $color-dark-grey;
    }
  }

  &__button {
    position: unset;
    border-radius: unset;
    border: 0;

    &.open {
      z-index: $z-index-country-dropdown;
    }

    .selected-flag {
      width: 54px;
      min-height: 47px;
      position: unset;
      background-color: $color-white;
      padding-left: $padding-base;
    }
  }

  &__dropdown.country-list {
    @include scrollbars($size: 4px, $background-color: transparent, $border-radius: 2px);
    width: 100%;
    max-height: 245px;
    margin: unset;
    transform: translateY(3px);
    box-shadow: 0 0 30px -10px $color-pastel;

    .divider {
      display: none;
    }

    .country {
      min-height: 41px;
      margin: unset;
      display: flex;
      align-items: center;
      padding: $padding-base * 0.5 $padding-base;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: calc(100% - 32px);
        left: auto;
        right: auto;
        top: 0;
        height: 1px;
        background-color: $color-light-grey;
      }

      &.highlight,
      &:hover {
        background-color: rgba($color-light-grey, 0.3);
      }

      &:nth-child(2),
      &.highlight,
      &:hover,
      &.highlight + .country,
      &:hover + .country,
      &.highlight + .divider + .country,
      &:hover + .divider + .country {
        &::before {
          display: none;
        }
      }

      .flag {
        margin-top: 0;
      }

      .country-name,
      .dial-code {
        font-size: $font-size-small;
        line-height: $line-height-small;
        letter-spacing: $letter-spacing-base;
      }

      .country-name {
        color: $color-dark-blue;
        display: block;
      }

      .dial-code {
        color: $color-dark-grey;
      }
    }

    .no-entries-message {
      display: none;
    }
  }

  &__search {
    &.search {
      min-height: 41px;
      min-width: unset;
      max-width: unset;
      padding: $padding-base * 0.5 $padding-base;
      z-index: $z-index-telphone-search;
      background-color: $color-white;

      &::before {
        content: '';
        position: absolute;
        width: calc(100% - 32px);
        left: auto;
        right: auto;
        top: auto;
        bottom: 0;
        height: 1px;
        background-color: $color-dark-blue;
      }

      &::after {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        left: 16px;
        top: auto;
        bottom: auto;
        background-color: $color-medium-grey;
        mask-image: url('https://cnhi-p-001-delivery.sitecorecontenthub.cloud/api/public/content/a195a84d9258428eb311aa6c1380200d?v=1ff2f2f3');
      }
    }

    &-box.search-box {
      font-size: $font-size-small;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
      color: $color-dark-blue;
      width: 100%;
      padding: 0 0 0 $padding-base * 2;
      margin: 0;
      border: unset;
      border-radius: 0;
      font-family: inherit;

      &::placeholder {
        color: $color-medium-grey;
      }
    }

    &::before {
      width: unset;
    }
  }
}

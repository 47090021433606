@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.component-details {
  overflow-y: auto;

  @include media($from: tbl-p) {
    height: 768px;
  }

  &__media-height {
    @include media($from: tbl-p) {
      @include fixed-picture-aspect-ratio(1240, 489);
    }

    @include media($to: tbl-p) {
      @include fixed-picture-aspect-ratio(375, 210);
    }
  }

  &__content {
    background: $white;
    padding: $padding-base $padding-base $padding-base * 2.5;

    @include media($from: tbl-p) {
      padding: $padding-base * 2 $padding-base $padding-base * 2 - 4 $padding-base * 2;
      overflow-y: auto;

      ::-webkit-scrollbar {
        display: none;
      }

      &-wrapper {
        flex-grow: 1;
        position: relative;
      }
    }
  }

  &-banner {
    overflow: hidden;
    height: 0;
    position: relative;

    &__content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    font-size: $font-size-medium;
    line-height: $line-height-base;
    letter-spacing: $letter-spacing-small;
    margin-top: 0;
    margin-bottom: $margin-base * 0.5;
    color: $color-dark-blue;

    @include media($from: tbl-p) {
      margin-bottom: $margin-base;
      line-height: $line-height-small;
    }
  }

  &__text {
    word-break: break-word;
    margin-bottom: 1.5 * $margin-base;
    line-height: $line-height-small;
    font-size: $font-size-small;
    letter-spacing: $letter-spacing-base;
    color: $color-dark-blue;
    font-weight: $font-weight-normal;

    @include media($from: tbl-p) {
      font-size: $font-size-base;
      min-height: 60px;
    }
  }

  &__button {
    margin: 0;
    cursor: pointer;

    @include media($from: tbl-p) {
      width: fit-content;
    }

    &--secondary {
      padding: 0px !important;

      @include media($to: tbl-p) {
        padding: 0 0 0 $padding-base * 1.5 !important;
      }
    }

    &-wrapper {
      margin-top: auto;
      line-height: $line-height-base;
      display: flex;
      flex-direction: column;
      row-gap: 1.5 * $margin-base;
      column-gap: 1.5 * $margin-base;

      @include media($from: tbl-p) {
        flex-direction: row;
      }
    }
  }

  .fixed-aspect-ratio-box {
    @include fixed-picture-aspect-ratio(1240, 490);
  }
}

@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.special-offers-listing-card {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__border {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-bottom: 2px solid $grey-light;
    margin: 0 $margin-base * 0.5;
  }

  &__button {
    margin: $margin-base * 2 0;

    &-wrapper {
      margin-top: auto;
    }
  }

  &__text {
    font-size: $font-size-small;
    color: $blue-dark;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;

    &--title {
    
      font-weight: $font-weight-bold;
      margin-top: $margin-base * 1.375;

      @include media($from: tbl-p) {
        font-size: $font-size-base;
        margin-top: $margin-base;
      }

      // discount style
      b,
      strong {
        font-size: $font-size-medium;
        font-weight: $font-weight-bold;
        line-height: $line-height-base;
        letter-spacing: $letter-spacing-small;
        color: $color-primary;

        @include media($from: tbl-p) {
          line-height: $line-height-small;
        }
      }
    }

    &--content {
      margin-bottom: $margin-base * 0.5;

      @include media($from: tbl-l) {
        margin-bottom: $margin-base * 2;
      }
    }

    &--disclaimer {
      color: $color-primary;
      font-weight: $font-weight-bold;
      text-decoration: underline;
      cursor: pointer;
    }

    &--expiration {
      font-weight: $font-weight-bold;
      margin-bottom: $margin-base * 1.5;
    }
  }

  &__badge {
    position: absolute;
    top: $margin-base;
    right: $margin-base;
    z-index: 1;
    font-size: $font-size-smaller;
    line-height: $line-height-smaller;
    color: $black;
    padding: $padding-base / 4 $padding-base / 1.5;
    background-color: $grey-light;
    text-transform: uppercase;

    * {
      margin: 0;
    }
  }

  @include media($from: tbl-l) {
    img {
      object-fit: cover;
    }
  }

  &__media-wrapper {
    @include media($to: tbl-p) {
      margin: 0;
    }
  }

  &__image {
    @include fixed-picture-aspect-ratio(300, 168);

    @include media($from: tbl-p) {
      @include fixed-picture-aspect-ratio(736, 226);
    }
  }

  &__cta-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: auto;
    margin-bottom: $margin-base * 1.5;

    @include media($from: tbl-p) {
      &--row {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }
}

.my-modal-dialog.special-offers-listing-card-modal {
  padding: $padding-base * 5 $padding-base * 2 $padding-base * 4;

  @include media($to: tbl-p) {
    padding: $padding-base $padding-base $padding-base * 2.5;
  }

  &>div {
    text-align: left;
    margin: 0 auto;

    @include media($from: tbl-p) {
      max-width: 632px;
    }

    @include media($to: tbl-p) {
      max-width: 343px;
    }
  }
}
@import '../../../styles/_variables.scss';

.carousel-dots-wrapper {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $margin-base * 1.5;
  gap: $margin-base + 3;
  min-height: 10px;

  .carousel-dots {
    width: 8px;
    height: 8px;
    opacity: 0.3;
    border-radius: 50%;
    background-color: $blue-dark;
    transition: all 200ms ease-out;

    &--small {
      width: 4px;
      height: 4px;
    }

    &--hidden {
      display: none;
    }

    &--active {
      opacity: 1;
      width: 10px;
      height: 10px;
    }
  }

  &.carousel-dots--dark-mode {
    .carousel-dots {
      background-color: rgba($white, 0.8);

      &--active {
        background: $white;
      }
    }
  }
}

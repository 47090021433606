@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.splash-component-footer {
  max-height: 56px;
  background-color: $white;
  opacity: 0.85;
  width: 100%;
  overflow: hidden;

  @include media($from: tbl-p) {
    margin-top: auto;
    position: absolute;
    bottom: 0;
  }

  &--active {
    margin-top: auto;
    display: none;

    @include media($from: tbl-p) {
      display: block;
      background-color: transparent;
    }
  }

  .splash-component__container {
    display: flex;
  }

  &__logo {
    max-height: 32px;
    margin-right: $margin-base * 2;
    margin-top: $margin-base * 0.625;
  }

  &__copyright {
    margin: $margin-base * 1.125 0;
    font-family: $font-family;
    font-size: $font-size-smaller;
    line-height: $font-size-medium-large;
    color: $color-dark-grey;
  }
}

@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.spinner {
  height: 100px;
  width: 100px;
  position: relative;
  stroke: $color-primary;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  &__wrapper {
    height: 100%;
    width: 100%;
    display: grid;
    place-content: center;
  }

  &__circle {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    animation: rotate 2s linear infinite;
    margin: auto;
    transform-origin: center center;
  }

  &__path {
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
  }

  @keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }

    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
}

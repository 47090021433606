@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.compatible-product-wrapper {
  position: relative;
  margin: 0 auto;
  max-width: $width-max-page;
  display: flex;
  flex-direction: column;
  padding: 0 0 $padding-base * 4 $padding-base * 2;

  @include media($to: tbl-p) {
    padding: 0 0 $padding-base * 2.5 0;
  }
}

.compatible-product {
  &__header {
    display: flex;
    flex-direction: column;
    padding: $padding-base * 5 0 $padding-base * 2;

    @include media($to: tbl-p) {
      padding: $padding-base * 3 $padding-base 0 $padding-base;
    }
  }

  &__eyebrow {
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $grey-medium;
    text-transform: uppercase;

    @include media($from: tbl-p) {
      font-size: $font-size-base;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
    }
  }

  &__title-wrapper {
    display: flex;
    flex-direction: column;

    @include media($from: tbl-p) {
      flex-direction: row;
      align-items: flex-end;
      margin: 0 0 $margin-base * 1.5;
    }
  }

  &__title {
    margin: 0 0 $margin-base;
    font-weight: $font-weight-bold;
    color: $blue-dark;

    @include media($from: tbl-p) {
      margin-bottom: 0;
      font-size: $font-size-larger;
      line-height: $line-height-large;
      letter-spacing: $letter-spacing-large;
    }
  }

  &__link {
    align-items: center;
    display: flex;
    margin-bottom: $margin-base * 1.5;

    @include media($from: tbl-p) {
      margin-bottom: 0;
      margin-left: $margin-base * 1.5;
    }
  }

  &__link,
  &__link a {
    $transition-link-arrow: margin-left 0.1s ease-in;
    text-underline-position: under;
    color: $color-primary;
    font-weight: $font-weight-bold;
    text-decoration: none;
    text-transform: uppercase;
    font-size: $font-size-base;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;

    &-text {
      margin-right: $margin-base / 2;
    }

    &:hover {
      .icon-wrapper {
        margin-left: $margin-base * 0.5;
      }

      text-decoration: underline;
    }

    .icon-wrapper {
      background-color: $color-primary;
      transition: $transition-link-arrow;
      width: 20px;
      height: 20px;
    }
  }

  &__cards {
    @include media($to: tbl-p) {
      display: flex;
      flex-direction: column;
      padding: 0;
    }

    @include media($from: tbl-p) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-row-gap: $padding-base * 2;
      grid-column-gap: $padding-base * 5;
    }

    @include media($to: dsk) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    position: relative;
    padding: $padding-base * 2 $padding-base * 3 $padding-base * 1.5 $padding-base * 2;
  }

  &__show-more {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__show-more-cta {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    text-align: center;
    color: $color-primary;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    align-items: center;

    .icon-wrapper {
      margin-left: $margin-base / 2;
      background-color: $color-primary;
    }
  }
}

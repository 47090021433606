@import '../../styles/_mixins.scss';
@import '../../styles/_variables.scss';

.sitecore-jss-form {
  $box-shadow-cta: 0px 10px 20px;
  $root: &;
  @include max-width();
  background-color: $color-white;

  form {
    max-width: 1272px;
    padding: $padding-base * 2 $padding-base;
    @include media($from: tbl-p) {
      padding: $padding-base * 2 $padding-base $padding-base * 2 $padding-base * 2;
    }
    @include media($from: tbl-p, $to: dsk-m) {
      margin-right: $margin-base * 5;
    }
  }

  * {
    box-sizing: border-box;
  }

  input::-webkit-input-placeholder {
    opacity: 0;
  }

  input:focus::-webkit-input-placeholder {
    opacity: 1;
  }

  input[type='checkbox'] {
    appearance: none;
    margin: 0;
  }

  input[type='radio'] {
    appearance: none;
    margin: 0;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  #{$root}__button {
    padding: 0.8125 * $padding-base 1.5 * $padding-base;
    background-color: $color-primary-yellow;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    text-align: center;
    color: $color-black;
    border: none;
    outline: none;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    font-family: $font-family;
    text-transform: uppercase;
    cursor: pointer;

    @include media($to: tbl-p) {
      width: 100%;
    }

    &:hover {
      box-shadow: $primary-btn-box-shadow;
      background-color: $color-bright-yellow;
    }

    &--previous {
      border: 2px solid $blue-dark;
      background-color: $white;
      color: $blue-dark;

      &:hover {
        color: $color-primary;
        background-color: $white;
        border-color: $color-primary;
        box-shadow: $box-shadow-cta rgba(134, 188, 237, 0.3);
      }
    }
  }

  &__radio-label{
    display: flex;
    flex-direction: row;
    align-items: center;
  }


  &__control {
    $control: &;
    position: relative;
    max-width: 400px;

    &__date-field {
      display: flex;
      gap: $padding-base * 0.5;
      box-shadow: 0 4px 25px 0 $color-button-shadow;

      #{$root}__date-icon {
        margin: 0.75 * $margin-base 0 $margin-base * 0.75 $margin-base;
      }
    }

    &--has-focus {
      outline: 1px solid $color-primary;
    }

    &--text-box {
      #{$root}__label {
        color: $color-dark-grey;
        position: absolute;
        left: 16px;
        top: 12px;
        font-size: $font-size-small;
        line-height: $line-height-small;
        letter-spacing: $letter-spacing-base;

        &--date {
          left: 0;
          background-color: $white;
        }

        &--has-focus {
          top: 4px;
          font-size: $font-size-smaller;
          line-height: $line-height-smaller;
        }
      }

      &-multiline {
        box-shadow: 0 4px 20px -10px $color-input-shadow;
        background-color: $white;
        line-height: $line-height-smaller - 8;
        &:focus-within {
          outline: 1px solid $color-dark-grey;
        }
        &--has-error {
          outline: 1px solid $color-error;
          .sitecore-jss-form__field {
            border: none !important;
            outline: none !important;
          }
        }
      }

      #{$root}__field-container {
        position: relative;
        flex-grow: 1;
        input[type='date'] {
          border: none;
          -webkit-appearance: none;
          background-color: $white;
          width: auto;
          color: $black;
          opacity: 0;
        }

        input[type='date']::-webkit-calendar-picker-indicator {
          background: none;
        }

        input[type='date']::-webkit-datetime-edit-text,
        input[type='date']::-webkit-datetime-edit-month-field,
        input[type='date']::-webkit-datetime-edit-day-field,
        input[type='date']::-webkit-datetime-edit-year-field {
          color: transparent;
          background-color: transparent;
        }

        input[type='date']#{$root}__field--has-focus::-webkit-datetime-edit-text,
        input[type='date']#{$root}__field--has-focus::-webkit-datetime-edit-month-field,
        input[type='date']#{$root}__field--has-focus::-webkit-datetime-edit-day-field,
        input[type='date']#{$root}__field--has-focus::-webkit-datetime-edit-year-field {
          color: $color-medium-grey;
          background-color: transparent;
        }

        input[type='date']#{$root}__field-value,
        input[type='date']#{$root}__field--has-focus {
          opacity: 1;
        }

        input[type='date']#{$root}__field-value {
          color: $black;
        }

        input[type='date']#{$root}__field-value::-webkit-datetime-edit-text,
        input[type='date']#{$root}__field-value::-webkit-datetime-edit-month-field,
        input[type='date']#{$root}__field-value::-webkit-datetime-edit-day-field,
        input[type='date']#{$root}__field-value::-webkit-datetime-edit-year-field {
          color: $black;
          background-color: transparent;
          &:focus {
            color: $black;
            background-color: transparent;
          }
        }
      }

      #{$root}__field {
        font-size: $font-size-small;
        width: 100%;
        padding: 1.375 * $padding-base $padding-base $padding-base / 4;
        border: none;
        box-shadow: 0 4px 20px -10px $color-input-shadow;
        outline: none;
        line-height: $line-height-small;
        letter-spacing: $letter-spacing-base;
        color: $color-dark-blue;
        font-family: $font-family;

        &::placeholder {
          color: $grey-medium;
        }

        &:focus {
          outline: 1px solid $color-primary;
        }

        &--text-area {
          resize: none;
          padding: 0 $padding-base;
          margin: 1.25 * $margin-base 0 $margin-base / 4;
          box-shadow: unset;

          &:focus {
            border: 0;
            outline: 0;
            padding: 0 $padding-base;
          }

          @include custom-scroll-bar-whiteBg;

          &-icon {
            position: absolute;
            right: 8px;
            bottom: 8px;
          }
        }

        &--date {
          padding: 0.75 * $padding-base $padding-base $padding-base * 0.75 0;
          box-shadow: unset;
          &#{$root}__field-value {
            padding: 1.25 * $padding-base $padding-base $padding-base * 0.25 0;
          }
          &:focus {
            outline: unset;
            padding: 1.25 * $padding-base $padding-base $padding-base * 0.25 0;
          }
        }
      }

      &#{$control}--has-error {
        #{$root}__label {
          color: $color-error;
        }

        #{$root}__field {
          outline: 1px solid $color-error;
          &--date {
            outline: unset;
          }
        }

        #{$control}__date-field {
          outline: 1px solid $color-error;
        }
      }
    }

    &--checkboxfield,
    &--checkbox-list {
      #{$root}__checkbox {
        margin-right: $margin-base / 2;
        position: relative;
        background-color: $blue-dark;
      }

      &#{$control}--has-error {
        #{$root}__checkbox {
          background-color: $color-error;
        }
      }
    }

    &--radio-btn-list {
      #{$root}__radio-btn-title {
        font-size: $font-size-base;
        line-height: $line-height-small;
        letter-spacing: $letter-spacing-base;
        color: $black;
        display: block;
      }

      #{$root}__radio-btn {
        &-wrapper {
          margin-bottom: $margin-base * 1.25;
        }

        margin: $margin-base * 0.125 $margin-base / 2 $margin-base * 0.125 0;
        position: relative;
        display: inline-block;
        background-color: $blue-dark;
      }

      &#{$control}--has-error {
        #{$root}__radio-btn {
          background-color: $color-error;
        }
      }
    }

    &--file-upload {
      #{$root}__input-wrapper {
        position: relative;
      }

      #{$root}__delete-icon {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        cursor: pointer;
      }

      #{$root}__upload-icon {
        margin-left: $margin-base / 2;
        position: relative;
        top: -4px;
        transform: rotate(180deg);
      }

      #{$root}__file-upload-label {
        margin-bottom: $margin-base / 2;
        font-size: $font-size-base;
        line-height: $line-height-small;
        display: inline-block;
      }

      #{$root}__file-upload-btn {
        font-weight: $font-weight-bold;
        color: $color-primary;
        font-size: $font-size-base;
        line-height: $line-height-base;
        margin-top: 1.5 * $margin-base;
        border: 0;
        background-color: transparent;
        padding: 0;
        font-family: $font-family;

        .icon-wrapper {
          display: inline-block;
          position: relative;
          top: 8px;
        }
      }

      #{$root}__file-input {
        background-color: $color-white;
        width: 100%;
        box-shadow: 0 4px 25px 0 $color-button-shadow;
        padding: 0.75 * $padding-base 2.5 * $padding-base 0.75 * $padding-base $padding-base;
        font-size: $font-size-base;
        line-height: $line-height-base;

        &::file-selector-button {
          display: none;
        }

        &--is-empty {
          display: none;

          + #{$root}__delete-icon {
            display: none;
          }
        }
      }
    }

    &--checkboxfield {
      max-width: 706px;
    }

    &__label {
      &--checkbox-label {
        font-size: $font-size-smaller;
        line-height: $line-height-smaller;
        color: $color-dark-grey;
        display: grid;
        grid-template-columns: 24px auto;
        gap: 8px;

        .rich-text {
          padding: $padding-base * 0.125 0;
        }
      }
      &--checkbox-label-NoConsent {
        font-size: $font-size-smaller;
        line-height: $line-height-smaller;
        color: $color-dark-grey;

        .rich-text {
          padding: $padding-base * 0.125 0;
        }
      }

      &--checkbox-list {
        display: flex;
        margin-bottom: $margin-base * 1.125;
        align-items: center;
        font-size: $font-size-small;
        line-height: $line-height-small;
        color: $color-dark-blue;
        letter-spacing: $letter-spacing-base;
      }

      &--title {
        color: $color-black;
        font-size: $font-size-base;
      }
    }
  }

  &__error-list {
    color: $color-error;
    font-size: $font-size-smaller;
    margin-top: 0.5 * $margin-base;
    line-height: $line-height-small;

    &__icon {
      margin: 0 $margin-base * 0.25 0 0;
      display: inline-block;
    }

    &__error-message {
      position: relative;
      top: -5px;
    }
  }

  &__toggle-password-visibility-btn {
    border: none;
    outline: none;
    background-color: transparent;
    position: absolute;
    right: 16px;
    top: 10px;
  }

  & > form,
  &__field-wrapper,
  &__field-wrapper > fieldset {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__field-wrapper {
    margin-bottom: $margin-base;

    &--radio-btn {
      ul {
        margin: $margin-base * 1.125 0 0;
      }

      label {
        display: flex;
        align-items: center;

        &:first-of-type {
          color: $color-black;
          font-size: $font-size-base;
        }

        p {
          font-size: $font-size-small;
          line-height: $line-height-small;
          letter-spacing: $letter-spacing-base;
          color: $blue-dark;
        }
      }

      .invalid ~ label {
        input[type='radio'] {
          border-color: $color-error;
        }
      }
    }

    & > fieldset {
      border: 0;
      margin: 0;
      padding: 0;
    }

    &.width-1,
    &.width-1-2,
    &.width-1-3,
    &.width-1-4,
    &.width-2-3 {
      display: block;
      margin-bottom: $margin-base * 1.5;
      width: 100%;
      @include media($from: tbl-p) {
        margin-right: $margin-base;
        margin-bottom: $margin-base * 2.5;
      }
      & > #{$root}__control {
        max-width: initial;
      }
      #{$root}__control--countryState-dropdown-list {
        padding: 0;
        margin: 0;
      }
      .react-tel-input .form-control {
        width: 100%;
      }
    }

    &.hidden {
      display: none;
    }

    @include media($from: tbl-p) {
      &.width-1 {
        width: 100%;
      }
      &.width-1-2 {
        width: calc(50% - #{$margin-base});
      }
      &.width-1-3 {
        width: calc(33.33% - #{$margin-base});
      }
      &.width-1-4 {
        width: calc(25% - #{$margin-base});
      }
      &.width-2-3 {
        width: calc(66.66% - #{$margin-base});
      }
    }
  }

  .width-1,
  .width-1-2,
  .width-1-3,
  .width-1-4,
  .width-2-3 {
    &:has(> #{$root}__button) {
      @include media($from: tbl-p) {
        width: unset !important;
      }
    }
  }
}

// Global CSS adjustment to address the z-index for reCAPTCHA
.grecaptcha-badge {
  z-index: $z-index-recaptcha;
}

@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

@keyframes slidein {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideout {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes slidedown {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

.promo-banner {
  @include media($from: tbl-p) {
    display: flex;
  }

  &__image {
    @include media($from: tbl-p) {
      position: relative;
      overflow: visible;
      width: 100%;
      height: 100%;
    }

    &-wrapper {
      @include media($from: tbl-p) {
        min-width: 285px;
        min-height: 191px;
        object-fit: cover;
        padding: $padding-base * 0.8125 0 $padding-base * 0.8125 $padding-base;
      }

      @include media($to: tbl-p) {
        display: none;
      }
    }
  }

  &__container {
    display: block;
    position: fixed;
    z-index: $z-index-promo-banner;
    background-color: $blue-dark;
    color: $white;
    transition: top 0.4s ease;
    top: var(--navigation-offset);

    @include media($from: tbl-p) {
      width: 700px;
      top: 50%;
      animation: slidein 0.7s ease-in-out;
    }

    @include media($to: tbl-p) {
      min-width: 375px;
      top: var(--navigation-custom-height, $height-navigation);
      animation: slidedown 1s ease-in-out;
    }

    &--closed {
      display: none;

      @include media($from: tbl-p) {
        display: inherit;
        transform: translateX(-100%);
        animation: slideout 0.7s ease-in-out;
      }
    }
  }

  &__content {
    padding: 0 $padding-base * 1.5 $padding-base * 0.25 $padding-base * 0.9375;

    @include media($to: tbl-p) {
      padding-left: $padding-base;
    }
  }

  &__close-button {
    @include button-unstyled;
    position: absolute;
    height: 24px;
    width: 24px;

    @include media($to: tbl-p) {
      top: $margin-base;
      right: $margin-base;
    }

    @include media($from: tbl-p) {
      top: 13px;
      right: 13px;
    }
  }

  &__heading {
    margin: $margin-base * 3 0 $margin-base/2;
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-medium;

    @include media($from: tbl-p) {
      margin: $margin-base * 1.5 0 $margin-base/2;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-large;
    }
  }

  &__text {
    margin: $margin-base/2 0 0;
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
  }

  &__cta.button {
    padding-left: 0;
  }
}
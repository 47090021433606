@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

$lines-width: 24.52vw;
$selected-series: 26.19vw;
$lines-width-largescreen: 392px;

@mixin text-link-style() {
  color: $color-primary-blue;
  text-decoration: underline;
  text-underline-position: under;
  transition: all 0.3s ease-in-out;
}

.navigation__menu-item--open > .navigation__menu-item__box-shadow {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: $z-index-header-box-shadow;
  pointer-events: none;
  height: 100%;
  width: 100vw;
  max-width: $width-max-page;
  box-shadow: 0 4px 10px -10px rgba($black, 0.2);
}

.equipment-menu {
  width: fit-content;
  max-height: calc(100vh - var(--navigation--Height--actual));
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: row;
  background-color: $grey-lighter;
  max-width: $width-max-page;

  &__open {
    max-height: calc(100vh - var(--navigation--Height--actual));
    visibility: visible;
    transition: all 0.3s ease-out;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__close {
    max-height: 0;
    visibility: hidden;
    transition: all 0.3s ease-in;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .link-style {
    color: $blue-dark;
    font-size: $font-size-small;
    line-height: $line-height-small;
    vertical-align: top;
    width: fit-content;
    cursor: pointer;
  }

  .link-style--selected {
    @include text-link-style();
  }

  .overflow-y--scroll {
    overflow-y: auto;
    overflow-x: hidden;

    @-moz-document url-prefix() {
      scrollbar-width: thin;
      scrollbar-color: $grey-light;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $grey-light;
    }
  }

  * {
    box-sizing: border-box;
  }

  &__lines {
    width: $lines-width;
    padding: ($padding-base * 2) ($padding-base * 1.25) ($padding-base * 2) ($padding-base * 2);
    @extend .overflow-y--scroll;

    @include media($from: dsk-l) {
      width: $lines-width-largescreen;
    }
  }

  &__line-link {
    @extend .link-style;
    margin-bottom: $margin-base;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-base;

    &:last-child {
      margin: 0;
    }

    &:hover {
      color: $color-primary-blue;
      transition: all 0.3s ease-in-out;
    }

    &--selected {
      @extend .link-style--selected;
    }
  }

  &__series__equipment-view__wrapper {
    width: calc(100vw - #{$lines-width});
    display: flex;

    @include media($from: dsk-l) {
      width: calc(#{$width-max-page} - #{$lines-width-largescreen});
    }
  }

  &__series {
    width: calc(100vw - #{$lines-width} - #{$selected-series});
    padding: ($padding-base) $padding-base $padding-base * 2 ($padding-base * 1.6875);
    border-right: $padding-base * 0.6875 solid transparent;
    @extend .overflow-y--scroll;

    &-heading {
      @extend .link-style;
      margin: 0 0 $margin-base;
      &:hover {
        @include text-link-style();
      }
    }

    &-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: $margin-base;
      row-gap: $margin-base;
    }

    &-link {
      @extend .link-style;
      font-weight: $font-weight-normal;

      &--selected {
        @extend .link-style--selected;
      }
    }
  }

  &__selected-series {
    width: $selected-series;
    height: 100%;
    border-left: 1px solid $grey-medium-05;
    padding: 0 2 * $padding-base 0 $padding-base;
    cursor: pointer;
    @extend .overflow-y--scroll;

    @include media($from: dsk-l) {
      width: 100%;
    }

    &__wrapper {
      margin: $margin-base * 2 0 $margin-base * 2.5;

      @include media($from: dsk-l) {
        width: 585px;
      }
    }

    &__image-wrapper {
      @include fixed-picture-aspect-ratio(392, 267);
      margin-bottom: 2 * $margin-base;

      img {
        width: 100%;
      }
    }

    &__title {
      font-size: $font-size-medium;
      line-height: $line-height-smaller;
      font-weight: 300;
      color: $blue-dark;
      margin-bottom: 0 0 $margin-base 0;
    }

    &__spec {
      margin-bottom: $margin-base;

      span {
        display: block;
      }

      &-key,
      &-value {
        font-size: $font-size-small;
        line-height: $line-height-smaller;
      }

      &-key {
        color: $grey-medium;
        font-weight: $font-weight-normal;
      }

      &-value {
        font-weight: 600;
      }
    }
  }

  a,
  a:visited,
  a:active {
    text-decoration: none;
    color: unset;
    text-decoration-color: unset;
  }
}

@import '../../styles/_variables.scss';

.card-layout {
  &--white {
    background-color: $color-white;
  }
  &--lightGrey {
    background-color: $grey-light;
  }
  &--darkGrey {
    background-color: $grey-dark;
  }
  &--black {
    background-color: $black;
  }
  &--pastel {
    background-color: $blue-pastel;
  }
  &--darkBlue {
    background-color: $blue-dark;
  }
  &--primaryYellow {
    background-color: $yellow-primary;
  }
  &__textcolor {
    &--white {
      color: $color-white;
    }
    &--lightGrey {
      color: $grey-light;
    }
    &--darkGrey {
      color: $grey-dark;
    }
    &--black {
      color: $black;
    }
    &--pastel {
      color: $blue-pastel;
    }
    &--darkBlue {
      color: $blue-dark;
    }
    &--primaryYellow {
      color: $yellow-primary;
    }
  }
}

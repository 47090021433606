@import '../../../../styles/_mixins.scss';
@import '../../../../styles/_variables.scss';

$image-height-2column: 414px;
$image-height-3column: 261px;
$image-height-4column: 185px;

$text-max-height: 168px;

.card-column {
  $root: &;
  position: relative;
  margin-bottom: $margin-base * 2.5;

  @include media($from: tbl-p) {
    margin-bottom: $margin-base * 2.5;
    display: flex;
    flex-direction: column;
  }

  &:not(&--touch) {
    &:hover {
      @include media($from: tbl-p) {
        z-index: 1;

        #{$root}__media-bg-image {
          transform: translate(-32px, -32px);
          opacity: 1;
        }
      }

      #{$root}__body {
        @include media($from: tbl-p) {
          position: absolute;
          z-index: 1;
          box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.2);
          max-height: unset;
          min-height: 100%;
          height: auto;
          left: -$margin-base * 2;
          right: -$margin-base * 2;
          padding: 0 $padding-base * 2.5 $padding-base * 2;
        }
      }

      #{$root}__content-text {
        @include media($from: tbl-p) {
          height: unset;
          max-height: fit-content;
          padding: $padding-base 0 0;

          &:before {
            content: unset;
          }
        }
      }

      #{$root}__description,
      #{$root}__title {
        @include media($from: tbl-p) {
          display: initial;
          overflow: unset;
          white-space: initial;
        }
      }
    }
  }

  &--on-load-animation {
    animation: slideUp 500ms ease-in-out forwards 1;
    transform: translateY(150px);

    @keyframes slideUp {
      0% {
        filter: grayscale(100%) blur(5px);
        transform: translateY(150px);
      }

      100% {
        filter: grayscale(0%) blur(0px);
        transform: translateY(0px);
      }
    }
  }

  &--white {
    background-color: $color-white;

    #{$root}__media,
    #{$root}__body,
    #{$root}__buttons {
      background-color: $color-white;
    }
  }

  &--lightGrey {
    background-color: $grey-light;

    #{$root}__media,
    #{$root}__body,
    #{$root}__buttons {
      background-color: $grey-light;
    }
  }

  &--darkGrey {
    background-color: $grey-dark;

    #{$root}__media,
    #{$root}__body,
    #{$root}__buttons {
      background-color: $grey-dark;
    }
  }

  &--black {
    background-color: $black;

    #{$root}__media,
    #{$root}__body,
    #{$root}__buttons {
      background-color: $black;
    }
  }

  &--pastel {
    background-color: $blue-pastel;

    #{$root}__media,
    #{$root}__body,
    #{$root}__buttons {
      background-color: $blue-pastel;
    }
  }

  &--darkBlue {
    background-color: $blue-dark;

    #{$root}__media,
    #{$root}__body,
    #{$root}__buttons {
      background-color: $blue-dark;
    }
  }

  &--primaryYellow {
    background-color: $yellow-primary;

    #{$root}__media,
    #{$root}__body,
    #{$root}__buttons {
      background-color: $yellow-primary;
    }
  }

  // start media section style

  &__media {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__media-bg-image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $z-index-background;
    transition: 0.3s;
    opacity: 0;

    &--4ColumnLayout {
      height: 235px;
    }

    &--2ColumnLayout {
      height: 300px;
    }
  }

  &__media-picture {
    object-fit: cover;
    max-width: 100%;
    width: 100%;

    @include fixed-picture-aspect-ratio(375, 211);

    @include media($to: tbl-p) {
      width: 100vw;
    }

    @include media($from: tbl-p) {
      &--2ColumnLayout {
        @include fixed-picture-aspect-ratio(736, 414);
      }

      &--3ColumnLayout {
        @include fixed-picture-aspect-ratio(463, 261);
      }

      &--4ColumnLayout {
        @include fixed-picture-aspect-ratio(328, 185);
      }
    }
  }

  &__media-video-play-button {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: $z-index-carousel-video-play-button;
    background-color: $blue-dark;
    padding: $padding-base * 0.75;

    @include media($to: tbl-p) {
      padding: $padding-base / 2;
    }

    &--icon {
      background: $white;
    }

    &:hover {
      background: $color-primary;
    }
  }

  &__media-video-play-button-icon {
    background: $white;
  }

  // end media section style

  &__body-wrapper {
    @include media($from: tbl-p) {
      flex-grow: 1;
      position: relative;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 0 $padding-base;

    @include media($from: tbl-p) {
      padding: 0 $padding-base / 2;
      height: 100%;
      justify-content: space-between;
    }

    &--white {
      color: $color-white;
    }

    &--lightGrey {
      color: $grey-light;
    }

    &--darkGrey {
      color: $grey-dark;
    }

    &--black {
      color: $black;
    }

    &--pastel {
      color: $blue-pastel;
    }

    &--darkBlue {
      color: $blue-dark;
    }

    &--primaryYellow {
      color: $yellow-primary;
    }
  }

  &__content-text {
    @include media($from: tbl-p) {
      display: flex;
      flex-direction: column;
      padding: $padding-base 0 0;
      overflow: hidden;
      position: relative;
      box-sizing: border-box;  
      
      #{$root}:not(#{$root}--touch) & {
        max-height: $text-max-height;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: $text-max-height;
          background: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 45%);
        }
      }
    }

    #{$root}__text {
      min-height: 150px;
      height: auto;
      max-height: unset;
      overflow: initial;

      &:before {
        content: unset;
      }
    }
  }

  &__text {
    @include media($from: tbl-p) {
      display: flex;
      flex-direction: column;
      height: 150px;
      max-height: 150px;
      overflow: hidden;
      margin-bottom: $margin-base * 1.5;
      position: relative;
      padding: 0 $padding-base * 2.5;
    }
  }

  &__title {
    font-size: $font-size-medium;
    line-height: $line-height-small;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-small;
    padding: $padding-base 0;
    hyphens: auto;

    @include media($from: tbl-p) {
      padding: 0 0 $padding-base / 2;
    }
  }

  &__description {
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    white-space: normal;
    transition: 0.3s;
    padding: 0;

    @include media($from: tbl-p) {
      #{$root}:not(#{$root}--touch) & {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
    }
  }

  &--darkBlue &__description {
    a,
    a:visited,
    a:focus,
    a:hover {
      color: $color-pastel;
    }
  }

  &__content-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    box-sizing: border-box;

    #{$root}__primary-cta,
    #{$root}__popup-cta {
      line-height: $line-height-small;
    }

    #{$root}__secondary-cta {
      padding: $padding-base * 0.8125 $padding-base;
    }
  }

  &__play-store-cta,
  &__app-store-cta,
  .button {
    margin-top: $margin-base * 1.5;
  }

  &__play-store-cta,
  &__app-store-cta {
    margin-right: $margin-base;
  }
}

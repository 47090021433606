@import '../../../../styles/_mixins.scss';
@import '../../../../styles/_variables.scss';

.form-custom-dropdown {
  $root: &;
  margin: 0;

  &--has-error {
    #{$root}__label {
      color: $color-error;
    }

    #{$root}__input-container,
    #{$root}__input-container--has-focus {
      border-color: $color-error;
    }
  }

  &__label {
    color: $color-dark-grey;
    font-size: $font-size-smaller;
    line-height: $line-height-smaller;
    top: 4px;
    left: 16px;
    margin-bottom: 0;
    position: absolute;
    z-index: $z-index-dropdown;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 56px);

    &--empty-field {
      line-height: $line-height-small;
      font-size: $font-size-small;
      top: 12px;
      letter-spacing: $letter-spacing-base;
    }
  }

  &__text-input {
    background-color: $color-white;
    margin-bottom: 0;
    width: 100%;
    border: 0;
    position: relative;
    z-index: $z-index-text-input;
    padding: 1.25 * $padding-base 2.5 * $padding-base 0.375 * $padding-base $padding-base;
    line-height: $line-height-small;
    font-size: $font-size-small;
    letter-spacing: $letter-spacing-base;
    outline: 0;
    color: $color-dark-blue;
    &:focus {
      outline: 1px solid $color-primary;
    }
  }

  &__icon {
    position: absolute;
    z-index: $z-index-dropdown;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  &__input-container {
    margin-bottom: 0;
    position: relative;
    box-shadow: 0 4px 20px -10px $color-input-shadow;

    &:focus-within {
      z-index: $z-index-dropdown-input-form;
    }
  }

  &__list-container {
    display: none;
    position: absolute;
    width: 100%;

    &--visible {
      display: block;
    }
  }

  &__list {
    position: relative;
    bottom: 0;
    left: 0;
    background-color: $color-white;
    z-index: $z-index-dropdownList-form;
    box-shadow: 0 0 30px -10px $blue-pastel;
    max-height: 220px;
    overflow: auto;
    @include custom-scroll-bar-whiteBg;
  }

  &__list-item {
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $color-dark-blue;
    margin-bottom: 0;
    padding: 0.5 * $padding-base $padding-base;
    cursor: pointer;
    position: relative;
    min-height: 40px;

    &::before {
      content: '';
      width: 100%;
      height: 1px;
      background-color: $color-light-grey;
      display: block;
      position: relative;
      top: -8px;
    }
    &:first-child,
    &:hover,
    &:hover + & {
      &::before {
        display: none;
      }
    }
    &-empty {
      min-height: 40px;
    }
    &:hover {
      background-color: $color-light-grey-opacity03;
    }
  }
}

@import '../../../styles/_variables.scss';

.sticky-top-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: $z-index-sticky-top-wrapper;
  transform: all 0.4s ease;
  margin-right: var(--body-scrollbar-width--scroll-hidden, 0px);

  .sticky-element--hide {
    height: 0;
    overflow: hidden;
  }
}

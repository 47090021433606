@import '../../../../../styles/_variables.scss';
@import '../../../../../styles/_mixins.scss';

$main-breakpoint: map-get($breakpoints, tbl-p);

.media-carousel-card {
  &__picture {
    &--16-9 {
      @include fixed-picture-aspect-ratio(16, 9);
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__slide-wrapper {
    position: relative;
    width: 100%;

    img {
      display: block;
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
    }

    @include media($to: tbl-p) {
      box-sizing: border-box;
      width: 100%;
    }
  }

  &__focus-image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform: translate(0, 0, 1);
    opacity: 1;
  }

  &__slide-video-play-button {
    position: absolute;
    top: 50%;
    z-index: $z-index-media-carousel-play-button;
    padding: $padding-base * 1.5;
    transform: translate(-50%, -50%);
    left: 50%;
    background: $color-black;
    cursor: pointer;

    .icon-wrapper {
      background-color: $color-white;
    }

    &:hover {
      background-color: $color-primary-blue;
      box-shadow: 0 10px 20px 0 $color-arrow-hover-box-shadow;
    }

    @include media($to: tbl-p) {
      padding: $padding-base * 0.5;
    }
  }
}

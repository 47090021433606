@import '../../../../styles/_variables.scss';

$link-color: $blue-dark;
$icon-dimension: 20px;

.market-selector {
  opacity: 0.8;

  &__text {
    margin: 0 $margin-base * 0.5;
  }

  &__link {
    &:hover {
      span {
        color: $color-primary;
      }
      .icon-wrapper {
        background-color: $color-primary;
      }
    }
  }

  a {
    align-items: center;
    display: flex;
    text-decoration: none;
  }

  span {
    color: $link-color;
    font-size: $font-size-smaller;
    font-weight: $font-weight-bold;
    line-height: $line-height-smaller;
  }

  &__left-icon,
  &__right-icon {
    margin: 0;
  }
}

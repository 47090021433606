@import '../../../../../../styles/_variables.scss';
@import '../../../../../../styles/_mixins.scss';

.quick-access-other {
  &__title {
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $grey-medium;
    text-transform: uppercase;
    margin-bottom: $margin-base;

    @include media($to: tbl-p) {
      margin-bottom: $margin-base * 1.25;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    row-gap: $margin-base;

    @include media($to: tbl-p) {
      row-gap: $margin-base * 1.25;
    }
  }

  &__links &__link {
    text-decoration: none;
    display: flex;
    column-gap: $margin-base * 0.5;

    @include media($to: tbl-p) {
      text-decoration: initial;
    }

    &__label {
      flex-grow: 1;
      font-size: $font-size-small;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
      font-weight: $font-weight-bold;
      color: $color-dark-blue;
    }
  }
}
@import '../../../../../styles/_variables';
@import '../../../../../styles/_mixins';

.filter-no-match {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $padding-base $padding-base $padding-base * 8.125;

  @include media($from: tbl-p) {
    padding: $padding-base 0 $padding-base * 3;
    width: 47.62vw;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  &__icon {
    background-color: $blue-dark;
  }

  &__title {
    color: $blue-dark;
    font-weight: bold;
    text-align: center;
    margin: $margin-base 0 0;
    font-size: $font-size-base;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
  }

  &__description {
    color: $blue-dark;
    text-align: center;
    margin: $margin-base 0 0;
    white-space: pre-wrap;
    font-size: $font-size-base;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
  }
}

@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';
.loading-simulator {
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    transform: translateZ(0);
    animation: horizontalScan 1.2s 0s ease-in-out infinite;
    background: linear-gradient(to right, rgba($white, 0) 0%, rgba($white, 0.8) 47%, rgba($white, 0) 100%);
    z-index: $z-index-card-column-loader-background;
  }

  @keyframes horizontalScan {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }
}

.loading-card {
  position: relative;
  overflow: hidden;
  // overriding the default styles
  & .card-column {
    &__image-wrapper {
      background-color: $grey-light;

      @include media($from: tbl-p) {
        max-height: 185px;
        min-height: 185px;
      }
    }
  }

  // loading card content styles
  &__content {
    margin: 0 ($margin-base * 2.5) 0 ($margin-base * 2);

    * {
      background-color: $grey-light;
    }
  }

  &__title {
    height: 24px;
    margin-top: $margin-base;
    width: 50%;
  }

  &__description {
    height: 16px;
    margin-top: $margin-base * 0.5;
  }

  &__cta {
    height: 50px;
    width: 50%;
    margin: $margin-base * 1.5 0 $margin-base * 3;
  }
}

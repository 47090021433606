@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.download-brochure-container {
  @include max-width-fullscreen;
  background-color: $color-white;

  &__min-width-none {
    min-width: unset;
  }

  &__label-container {
    padding: $padding-base * 5 $padding-base * 2 $padding-base * 4 $padding-base * 2;
    width: 47.6vw;
    @include media($from: tbl-p) {
      .heading__title {
        margin-bottom: 0;
      }
    }
    @include media($to: tbl-p) {
      box-sizing: border-box;
      padding: $padding-base * 3 $padding-base $padding-base * 1.5;
      width: 100%;
    }
  }

  &__card-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 16px;
    row-gap: 48px;
    padding: 0 $padding-base * 2 $padding-base * 4 $padding-base * 2;
    @include media($to: dsk) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include media($to: tbl-p) {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      row-gap: 40px;
      padding: 0;
    }
  }
}

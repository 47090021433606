@import './mixins';

.banner-actions-container {
  @include media($to: tbl-p) {
    width: 100%;
  }

  #onetrust-accept-btn-handler {
    @include media($to: tbl-p) {
      width: 100%;
    }
  }
}

@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.pagination {
  @include max-width-fullscreen;
  padding-top: $padding-base * 2;

  @include media($to: tbl-p) {
    padding: 0;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 25%;
    padding-bottom: $padding-base * 4;

    @include media($to: tbl-p) {
      padding-left: 0;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: $white;
    color: $color-dark-blue;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    cursor: pointer;
    letter-spacing: $letter-spacing-base * 1.125;
    padding: $padding-base * 0.625 $padding-base * 0.875 $padding-base * 0.6875 $padding-base;

    @include media($to: tbl-p) {
      padding: $padding-base * 0.625 $padding-base * 0.875 $padding-base * 0.6875 $padding-base * 0.9375;
    }

    &--active {
      color: $white;
      background-color: $color-dark-blue;
    }

    &--disabled {
      pointer-events: none;
    }
  }

  &__arrow {
    background-color: $white;
    cursor: pointer;
    border: 0;
    padding: $padding-base / 4;

    &--first,
    &--last {
      pointer-events: none;

      .pagination__arrow--lefticon,
      .pagination__arrow--righticon {
        background-color: $grey-light;
      }

      color: $grey-light;
    }
  }
}
@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.search-suggestions {
  $root: &;
  display: block;

  &--search-page-input {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(100%);
    background-color: $color-white;
    z-index: 1;
    box-shadow: 0 4px 20px -10px $color-input-shadow;
    max-height: 40vh;
    overflow: auto;

    #{$root}__item {
      &__link {
        &:hover {
          color: $color-white;
          background-color: $color-primary-blue;
        }
      }
    }
  }

  .navigation__menu-item__tray & {
    &__item__link:hover {
      color: $color-black;
      text-decoration: none;
    }
  }

  &__item {
    $item: &;
    display: block;
    margin-bottom: 0;

    &:not(:last-child) {
      #{$item}__link {
        &::after {
          content: '';
          position: absolute;
          display: block;
          bottom: 0;
          right: 16px;
          left: 16px;
          border-bottom: 1px solid $color-light-grey;
        }
        &:hover::after {
          border-bottom-color: $color-primary;
        }
      }
    }

    &__link {
      color: $color-black;
      display: block;
      padding: $padding-base;
      margin-bottom: 0;
      position: relative;
      &:hover {
        color: $color-black;
        background-color: $color-light-grey;
        text-decoration: none;
      }
    }
  }
}

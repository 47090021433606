@import '../../styles/_mixins.scss';
@import '../../styles/_variables.scss';

.text-module {
  $root: &;

  @include max-width-fullscreen;
  background-color: $white;
  display: flex;
  flex-direction: column;

  @include media($to: tbl-p) {
    padding: $padding-base;
  }

  &--white {
    background-color: $white;
  }

  &--lightgrey {
    background-color: $grey-light;
  }

  &--darkgrey {
    background-color: $grey-dark;
  }

  &--black {
    background-color: $black;
  }

  &--pastel {
    background-color: $blue-pastel;
  }

  &--darkblue {
    background-color: $blue-dark;
  }

  &--primaryyellow {
    background-color: $yellow-primary;
  }

  &--left {
    align-items: flex-start;
    text-align: left;

    #{$root}__buttons {
      align-items: flex-start;

      @include media($from: tbl-l) {
        justify-content: flex-start;
        align-items: center;
      }
    }
  }

  &--center {
    align-items: center;
    text-align: center;

    #{$root}__buttons {
      align-items: center;

      @include media($from: tbl-l) {
        justify-content: center;
        align-items: center;
      }
    }

    .text-module__text.rich-text {
      ul,
      ol {
        list-style-position: inside;
        margin-left: 0;

        li {
          padding-left: 0;
        }
      }
    }
  }

  &--right {
    align-items: flex-end;
    text-align: right;

    #{$root}__buttons {
      align-items: flex-end;

      @include media($from: tbl-l) {
        justify-content: flex-end;
        align-items: center;
      }
    }
  }

  &__content {
    @include media($from: tbl-p) {
      width: 47.65%;
    }

    @include media($from: tbl-p) {
      padding: $padding-base $padding-base * 5 $padding-base $padding-base * 2;
    }
  }

  &__eyebrow {
    font-size: $font-size-small;
    line-height: $line-height-small + 3;
    letter-spacing: $letter-spacing-base;
    color: $grey-medium;
    margin-bottom: $margin-base / 2;

    @include media($from: tbl-p) {
      font-size: $font-size-base;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
    }
  }

  &__title {
    margin: 0 0 $margin-base;
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
    line-height: $line-height-medium;
    letter-spacing: $letter-spacing-medium;
    hyphens: auto;

    @include media($from: tbl-p) {
      font-size: $font-size-larger;
      font-weight: $font-weight-bold;
      line-height: $line-height-large;
      letter-spacing: $letter-spacing-large;
      hyphens: none;
    }
  }

  &__subtitle {
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    margin: 0 0 $margin-base * 1.5;

    @include media($from: tbl-p) {
      font-size: $font-size-base;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-base;
    }
  }

  &__text {
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    margin-bottom: $margin-base * 1.5;

    @include media($from: tbl-p) {
      font-size: $font-size-base;
    }
  }

  &--darkblue &__text,
  &--darkblue &__subtitle {
    a,
    a:visited,
    a:focus,
    a:hover {
      color: $color-pastel;
    }
  }

  &__image {
    &-wrapper {
      @include fixed-picture-aspect-ratio(800, 600);
      margin-bottom: $margin-base;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    row-gap: $margin-base * 1.5;

    @include media($from: tbl-l) {
      flex-direction: row;
    }
  }

  &__pop-up {
    & .s-blue,
    & .s-white {
      padding: 0 0 0 $padding-base;

      @include media($to: tbl-p) {
        padding: 0 0 0 $padding-base * 1.5;
      }
    }

    &--EE {
      min-width: 200px;
      min-height: 50px;
    }
  }

  &__margin {
    margin-right: $margin-base;

    @include media($to: tbl-p) {
      margin-right: $margin-base * 1.5;
    }
  }

  // important rules because we must override the color text that user paste in text module
  &--text-color-white {
    #{$root}__eyebrow,
    #{$root}__title,
    #{$root}__subtitle,
    #{$root}__text,
    #{$root}__text * {
      color: $white;
    }
  }

  &--text-color-lightgrey {
    #{$root}__eyebrow,
    #{$root}__title,
    #{$root}__subtitle,
    #{$root}__text,
    #{$root}__text * {
      color: $color-light-grey;
    }
  }

  &--text-color-darkgrey {
    #{$root}__eyebrow,
    #{$root}__title,
    #{$root}__subtitle,
    #{$root}__text,
    #{$root}__text * {
      color: $grey-dark;
    }
  }

  &--text-color-black {
    #{$root}__eyebrow,
    #{$root}__title,
    #{$root}__subtitle,
    #{$root}__text,
    #{$root}__text * {
      color: $color-black;
    }
  }

  &--text-color-pastel {
    #{$root}__eyebrow,
    #{$root}__title,
    #{$root}__subtitle,
    #{$root}__text,
    #{$root}__text * {
      color: $blue-pastel;
    }
  }

  &--text-color-darkblue {
    #{$root}__eyebrow,
    #{$root}__title,
    #{$root}__subtitle,
    #{$root}__text,
    #{$root}__text * {
      color: $blue-dark;
    }
  }

  &--text-color-primaryyellow {
    #{$root}__eyebrow,
    #{$root}__title,
    #{$root}__subtitle,
    #{$root}__text,
    #{$root}__text * {
      color: $color-primary-yellow;
    }
  }

  &--white,
  &--lightgrey {
    #{$root}__eyebrow {
      color: $grey-medium;
    }
  }

  &--pastel,
  &--primaryyellow {
    #{$root}__eyebrow {
      color: $blue-dark;
    }
  }
}

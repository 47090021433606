@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.component-details {
  overflow-y: auto;
  @include custom-scroll-bar;

  @include media($from: tbl-p) {
    height: 768px;
    &__shadow {
      position: absolute;
      left: 0;
      z-index: $z-index-scroll-shadow;
      width: 100%;
      height: 40px;
    }
  
    &__shadow-top {
      top: 0;
      background-image: linear-gradient(to bottom, rgba(10, 26, 50, 0.12), rgba(10, 26, 50, 0));
    }
  
    &__shadow-bottom {
      bottom: 0;
      background-image: linear-gradient(to top, rgba(10, 26, 50, 0.12), rgba(10, 26, 50, 0));
    }
  }

  &__media-height {
    @include media($from: tbl-p) {
      @include fixed-picture-aspect-ratio(1240, 489);
    }

    @include media($to: tbl-p) {
      @include fixed-picture-aspect-ratio(375, 210);
    }
  }

  &__content {
    background: $white;
    padding: $padding-base $padding-base $padding-base * 2.5;

    @include media($from: tbl-p) {
      padding: $padding-base * 2 24% $padding-base * 2 - 4 $padding-base * 2;

      &-wrapper {
        flex-grow: 1;
        position: relative;
        overflow-y: auto;
      }
    }
  }

  &-banner {
    overflow: hidden;
    height: 0;
    position: relative;

    &__content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &__title {
    font-size: $font-size-medium;
    line-height: $line-height-base;
    letter-spacing: $letter-spacing-small;
    margin-top: 0;
    margin-bottom: $margin-base * 0.5;
    color: $color-dark-blue;

    @include media($from: tbl-p) {
      margin-bottom: $margin-base;
      line-height: $line-height-small;
    }
  }

  &__text {
    word-break: break-word;
    margin-bottom: 1.5 * $margin-base;
    line-height: $line-height-small;
    font-size: $font-size-small;
    letter-spacing: $letter-spacing-base;
    color: $color-dark-blue;
    @include reset-rich-text-styles;

    @include media($from: tbl-p) {
      font-size: $font-size-base;
      min-height: 60px;
    }
  }

  &__button {
    margin: 0;
    cursor: pointer;

    @include media($from: tbl-p) {
      width: fit-content;
    }

    .cta-arrow {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: $margin-base * 0.1875;
    }

    &:hover {
      .cta-arrow {
        margin-left: $margin-base / 2;
        transition: all 0.1s ease-in;
      }
    }

    &--secondary-cta.button {
      padding: $padding-base * 0.8125 0px;
      display: inline;

      @include media($to: tbl-p) {
        padding: 0 0 0 $padding-base * 1.5;
      }
    }

    &-wrapper {
      margin-top: auto;
      line-height: $line-height-base;
      display: flex;
      flex-direction: column;
      row-gap: 1.5 * $margin-base;
      column-gap: 1.5 * $margin-base;

      @include media($from: tbl-p) {
        flex-direction: row;
      }
    }
  }

  .fixed-aspect-ratio-box {
    @include fixed-picture-aspect-ratio(664, 374);
  }
}

@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.product-plm-solutions-wrapper {
  position: relative;
  margin: 0 auto;
  max-width: $width-max-page;
  display: flex;
  flex-direction: column;

  @include media($from: tbl-p) {
    padding: $padding-base * 5 $padding-base * 2 0;
  }
}

.product-plm-solutions {
  &__header {
    display: flex;
    flex-direction: column;

    @include media($to: tbl-p) {
      padding: $padding-base * 2.5 $padding-base 0;
    }
  }

  &__eyebrow {
    margin: $margin-base * 5 0 $margin-base / 2;
    font-size: $font-size-base;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $grey-medium;
    text-transform: uppercase;
    font-weight: $font-weight-normal;

    @include media($to: tbl-p) {
      font-size: $font-size-small;
    }
  }

  &__title-wrapper {
    display: flex;
    flex-direction: column;

    @include media($from: tbl-p) {
      flex-direction: row;
    }
  }

  &__title {
    margin: 0;
    font-size: $font-size-larger;
    font-weight: $font-weight-bold;
    line-height: $line-height-large;
    letter-spacing: $letter-spacing-large;
    color: $blue-dark;

    @include media($to: tbl-p) {
      font-size: $font-size-large;
      line-height: $line-height-medium;
      letter-spacing: $letter-spacing-medium;
    }
  }

  &__subtitle {
    font-size: $font-size-base;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $blue-dark;
    margin-bottom: $margin-base * 1.5;

    @include media($from: tbl-p) {
      margin: 0;
      position: relative;
      width: 45vw;
      max-width: 756px;
    }

    @include media($to: tbl-p) {
      font-size: $font-size-small;
    }
  }

  &__cards {
    display: grid;
    position: relative;
    grid-column-gap: unset;
    padding: 0;
    grid-row-gap: $padding-base * 1.25;
    grid-template-columns: 1fr;

    @include media($from: tbl-p) {
      grid-row-gap: $padding-base * 2;
      grid-template-columns: repeat(2, 1fr);
      padding: $padding-base * 4 $padding-base * 2 $padding-base * 2;
      grid-column-gap: $padding-base * 5.125;
    }

    @include media($from: dsk) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }

  &__show-more {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: $margin-base * 2.5;

    @include media($to: tbl-p) {
      margin: $margin-base * 2 0 $margin-base * 2.5;
    }
  }

  &__show-more-cta {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    text-align: center;
    color: $color-primary;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    align-items: center;

    .icon-wrapper {
      margin-left: $margin-base / 2;
      background-color: $color-primary;
    }
  }
}

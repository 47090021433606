@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.thank-you {
    background-color: $white;
    display: flex;
    flex-direction: column;
    @include max-width-fullscreen;
    @include media($from: dsk-l) {
        min-width: 100%;
    }

    @include media($to: tbl-p) {
        padding: $padding-base;
    }

    @include media($from: mbl) {
        padding: $padding-base * 11.625 0 $padding-base * 9.625
    }

    align-items: center;
    text-align: center;

    &__icon-wrapper {
        display: flex;
        justify-content: center;
    }

    &__content {
        @include media($from: tbl-p) {
          width: 47.65%;
          padding: $padding-base $padding-base * 2 $padding-base $padding-base * 2;
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        margin-top: $margin-base * 1.5;
        justify-content: center;
    }

    &__title {
        margin: $margin-base 0 0;
        font-weight: $font-weight-bold;
        line-height: $line-height-small;
        letter-spacing: $letter-spacing-base;
        font-size: $font-size-small;

        @include media($from: tbl-p) {
            font-size: $font-size-base;
        }
    }

    &__message {
        margin-top: $margin-base * 1.5;
        line-height: $line-height-small;
        letter-spacing: $letter-spacing-base;
        font-size: $font-size-small;
        @include media($from: tbl-p) {
            margin-top: $margin-base * 2;
            font-size: $font-size-base;
        }
    }
}
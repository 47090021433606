@import '../../../styles/_mixins.scss';
@import '../../../styles/_variables.scss';

@mixin power-bar {
  background: $yellow-bright;
  content: '';
  display: block;
  height: 6px;
  width: 100%;
}

.social-media {
  width: fit-content;
  margin-left: auto;
  z-index: $z-index-icons;

  &__social {
    padding: $padding-base * 0.5 0 0 $padding-base;
    display: flex;
    background-color: $white;
  }

  &__social-text {
    display: block;
    font-size: $font-size-base;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;
    color: $blue-dark;
    margin-right: $margin-base;
  }

  &__social-icons {
    display: flex;
    padding-bottom: $padding-base;
    position: relative;
    width: 100%;

    &::after {
      @include power-bar();
      bottom: 0;
      position: absolute;
      right: 0;
      left: 0;
    }
  }

  &__social-icon {
    &-link {
      align-items: center;
      display: flex;
      justify-content: center;
      background-color: $white;
      margin-right: $margin-base * 1.25;
      padding: 0 !important; //to override a2a thridparty styles

      &:last-of-type {
        margin-right: $margin-base * 2.25;
      }

      .icon-wrapper {
        &:hover {
          background-color: $color-primary;
        }
      }
    }

    &-EE {
      margin-right: $margin-base * 1.25;
    }
  }
}

@import '../../styles/_variables.scss';

.rich-text {
  p,
  li {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }

  ul {
    list-style: initial;
  }

  ol,
  ul {
    margin: $margin-base 0 0 $margin-base * 1.5;
    padding-left: initial;

    &:not(:last-child) {
      margin-bottom: $margin-base;
    }

    & + ul,
    & + ol {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    li {
      padding-left: $padding-base * 0.75;

      &::marker {
        color: inherit;
        font-weight: bold;
      }
    }
  }

  a,
  a:focus,
  a:visited,
  a:hover {
    text-decoration: underline;
    color: $color-primary;
    font-size: bold;
  }

  &.color-inherit-link * a,
  &.color-inherit-link a {
    &,
    &:focus,
    &:visited,
    &:hover {
      color: inherit;
    }
  }
}

@import '../../styles/_mixins.scss';
@import '../../styles/_variables.scss';

.split-module {
  $root: &;
  @include max-width();
  position: relative;
  padding-top: $padding-base * 5;
  margin-top: $margin-base * 2;

  @include media($from: tbl-p) {
    display: flex;
    flex-direction: row;
    background-color: $white;
    padding-top: $padding-base * 1.5;
    min-height: 301px;
    margin: $margin-base * 5 0 $margin-base * 7.5;

    &--left {
      justify-content: flex-start;

      #{$root}__media-container {
        right: 0;
      }

      #{$root}__text-container {
        @include media($from: dsk) {
          padding-right: 15%;
        }
      }

      #{$root}__dot-image {
        @include media($from: tbl-p, $to: dsk) {
          left: 45%;
        }

        @include media($from: dsk) {
          left: 43%;
        }
      }
    }

    &--right {
      justify-content: flex-end;

      #{$root}__media-container {
        left: 0;
      }

      #{$root}__dot-image {
        right: 45%;
      }

      #{$root}__text-container {
        padding-left: 22%;

        @include media($from: dsk) {
          padding-left: 15%;
        }
      }
    }
  }

  @include media($from: dsk) {
    margin-bottom: $margin-base * 7.5;

    &--right {
      #{$root}__dot-image {
        right: 43%;
      }

      #{$root}__text-container {
        flex-wrap: nowrap;
      }
    }
  }

  @include media($from: dsk-l) {
    margin: $margin-base * 5 auto $margin-base * 7.5 auto;
  }

  &__dot-image {
    position: absolute;
    right: 0;
    top: 0;
    z-index: $z-index-background-media;

    @include media($from: tbl-p) {
      z-index: $z-index-medium;
      width: 301px;
      right: 15%;
    }

    @include media($from: dsk) {
      right: 38%;
    }
  }

  &__media-container {
    > .react-reveal {
      height: 100%;
    }

    @include media($from: tbl-p) {
      width: 49%;
      position: absolute;
      z-index: $z-index-gallery-layout-level-two;
      top: $margin-base * 5;
    }

    @include media($from: dsk) {
      width: 50%;
    }
  }

  &__media-carousel .editorial-image {
    position: unset;
  }

  &__media-height,
  &__image {
    @include fixed-picture-aspect-ratio(16, 9);
  }

  &__video {
    position: relative;
    padding-top: 56.25%;

    img,
    iframe,
    video {
      display: block;
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &__text-container {
    display: flex;
    background-color: $blue-dark;
    padding-top: $padding-base;
    padding-bottom: $padding-base * 2.5;
    flex-direction: column;

    @include media($from: tbl-p) {
      width: 64%;
      margin: 0;
      flex-wrap: nowrap;
      align-items: flex-start;
      padding-top: $padding-base * 5;
    }

    @include media($from: dsk) {
      width: 56%;
      margin: 0;
    }
  }

  &__eyebrow {
    color: $white;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-small;
    font-size: $font-size-small;
    text-transform: uppercase;
    margin: 0 0 $margin-base / 2;

    @include media($from: tbl-p) {
      font-size: $font-size-base;
      width: 57%;
    }

    @include media($from: dsk) {
      width: 100%;
    }
  }

  &__title {
    color: $white;
    font-size: $font-size-large;
    line-height: $line-height-medium;
    letter-spacing: $letter-spacing-medium;
    margin: 0 0 $margin-base / 2;
    font-weight: $font-weight-bold;

    @include media($from: tbl-p) {
      font-size: $font-size-large;
      line-height: $line-height-medium;
      letter-spacing: $letter-spacing-small;
      margin: 0 0 $margin-base;
      width: 57%;
    }

    @include media($from: dsk) {
      width: 100%;
    }
  }

  &__text {
    color: $white;
    margin-bottom: $margin-base / 2 0 0;
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-base;

    @include media($from: tbl-p) {
      margin: 0;
      width: 57%;
    }

    @include media($from: dsk) {
      width: 100%;
    }

    &.rich-text {
      a,
      a:visited,
      a:focus,
      a:hover {
        color: $color-pastel;
      }
    }

    p {
      margin-bottom: $margin-base;
    }
  }

  &__buttons {
    @include media($from: tbl-p) {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 57%;
    }

    @include media($from: dsk) {
      flex-direction: row;
      align-items: center;
      width: 100%;
    }

    @include media($from: dsk) {
      flex-direction: row;
      align-items: center;
    }

    &--media {
      background-color: initial;
      display: flex;

      &:first-child {
        background-color: inherit;
        margin-right: $margin-base;
      }

      #{$root}__image {
        padding: 0;
        border-radius: 10px;
        overflow: hidden;

        &:hover {
          box-shadow: none;
        }

        & img {
          width: auto;
          height: 50px;
        }
      }
    }
  }

  @include media($from: tbl-p) {
    &--right {
      #{$root}__title,
      #{$root}__eyebrow,
      #{$root}__text,
      #{$root}__buttons {
        width: auto;
      }
    }
  }

  &__primary-link,
  &__secondary-link {
    margin-top: $margin-base * 1.5;
    display: inline-block;
  }

  &__primary-link {
    #{$root}__cta-icon {
      text-align: left;
      color: $color-dark-blue;
      background-color: $white;

      &:hover {
        color: $color-primary-blue;
        box-shadow: 0 10px 20px $blue-pastel-04;
      }
    }
  }

  &__secondary-link {
    #{$root}__cta-icon {
      padding: 0 $padding-base * 1.5;
      text-align: left;
      color: $white;
      transform: perspective(1px) translateZ(0);
      transition: all 0.1s ease-in;

      &:hover {
        text-decoration: underline;
        text-underline-offset: 6px;
      }
    }
  }

  &__cta-icon {
    padding: $padding-base $padding-base * 1.5;
    position: relative;
    text-decoration: none;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-small;
    text-transform: uppercase;
    align-items: center;
    display: inline-block;

    &:hover {
      #{$root}__arrow {
        margin-left: $margin-base * 0.5;
        transition: all 0.1s ease-in;
      }
    }
  }

  &__arrow {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: $margin-base * 0.1875;
    background-color: $white;
  }

  &__pop-up {
    margin-top: $margin-base * 1.5;

    &--ee {
      width: 200px;
      height: 50px;
    }
  }
}

.ReactModalPortal {
  @include media($from: dsk) {
    .split-module {
      &__media-container {
        height: 373px;
      }

      &--right {
        .split-module__dot-image {
          right: 44%;
        }
      }
    }
  }
}

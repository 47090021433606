@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.form-page-navigation {
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: $shadow-pastel3;

    &__step-list {
        background-color: $color-white;
        margin: 0 9% $margin-base * 2;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include media($to: tbl-p) {
            margin-bottom: $margin-base;
        }
    }

    &__step {
        $root: &;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        color: $color-blue-dark-opacity05;
        line-height: $line-height-small;
        letter-spacing: $letter-spacing-base;
        
        &__circle {
            display: block;
            background-color: $color-white;
            height: $margin-base * 0.75;
            width: $margin-base * 0.75;
            border-radius: 100%;
            border: 4px solid $color-pastel;
            z-index: $z-index-multi-step-circle;
        }

        &__title {
            font-size: $font-size-small;
            line-height: $line-height-small;
            text-align: center;
        }

        &__name {
            font-size: $font-size-base;
            font-weight: $font-weight-bold;
            line-height: $line-height-small;
            text-align: center;
        }

        &--active {
            color: $color-dark-blue;
            #{$root}__circle {
                border: 4px solid $color-primary;
            }
        }

        &--passed {
            color: $color-dark-blue;
            #{$root}__circle {
                background-color:  $color-primary;
                border: 4px solid  $color-primary;
            }
        }

        &--mobile {
            color: $color-dark-blue;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: $margin-base * 1.5;
        }
    }

    &__progress-bar {
        width: var(--form-progress-bar-step-width, 0px);
        height: 3px;
        position: absolute;
        background-color: $color-pastel;
        top: 9px; // 9px to middle of circle
        // 9% parent padding + start from middle of first step
        left: calc(9% + var(--form-progress-bar-first-step-width, 0px));
    }

    &__progress {
        height: 100%;
        background-color: $color-primary;
        transition: 0.2s ease;
    }

    &__wrapper {
        @include max-width;
        .heading {
            margin: $margin-base * 3 $margin-base * 2 0;
            @include media($from: mbl) {
                margin: $margin-base * 5 $margin-base * 2 0;
            }
        }
    }
}